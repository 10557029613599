import { AuthenticateStatus } from "@/config";
import { VAT } from "@/constants";
import { GeneralObject, LinkType, ProcurementData, QuoteProductTableRow } from "@/models";
import { useEditProject, useGetAllRequisitionData, useIndexBuyerPurchaseOrdersRelatedData } from "@/services";
import { useUserData } from "@/zustand";
import produce from "immer";
import { Timeline } from "src/logic/models/procurement/form-timeline";
import { ResponseHandlers } from "src/logic/models/queries";
import { StateCreator } from "zustand";

interface purchaseOrderRelatedData { projects: GeneralObject[], sellers: GeneralObject[], purchase_orders: GeneralObject[] }
export interface ProcurementSlice {
    previousAuthenticationStatus: AuthenticateStatus | null,
    procurementData: ProcurementData | null,
    purchaseOrderRelatedData: purchaseOrderRelatedData | null,
    sellers: GeneralObject[]
    getProcurementData: () => void,
    loadBuyerPurchaseOrdersRelatedData: (responseHandlers?: ResponseHandlers) => void,
    subscribeToRequisitions: () => void
    updateProject: (formId: string, formType: LinkType, projectId: string, projectName: string, updateProject: (project: GeneralObject) => void) => void
    setPurchaseOrderRelatedData: (purchaseOrderRelatedData: purchaseOrderRelatedData) => void
    timeline: Timeline | null;
    setTimeline: (newTimeline: Timeline | null) => void;
}

export const createProcurementSlice: StateCreator<ProcurementSlice> = (set, get, api) => ({
    previousAuthenticationStatus: null,
    procurementData: null,
    purchaseOrderRelatedData: null,
    sellers: [],
    timeline: null,
    setTimeline: (newTimeline: Timeline | null) => {
        set(produce(draftState => { draftState.timeline = newTimeline }))
    },
    setPurchaseOrderRelatedData: (purchaseOrderRelatedData: purchaseOrderRelatedData) => {
        set(produce(draftState => { draftState.purchaseOrderRelatedData = purchaseOrderRelatedData }))
    },
    getProcurementData: () => {
        const authenticationStatus = useUserData.getState().authenticationStatus
        if(get().previousAuthenticationStatus === authenticationStatus) {
            return;
        }
        const onSuccess = (procurementData: ProcurementData) => {
            set(produce(draftState => {
                draftState.procurementData = procurementData
                draftState.sellers = procurementData.sellers
                draftState.previousAuthenticationStatus = authenticationStatus
            }))
            get().setRequisitions(procurementData.requisitions)
            get().setPersistedRequisitions(procurementData.requisitions)
            get().setProjects(procurementData.projects)
            get().subscribeCurrentRequisition()
        }
        useGetAllRequisitionData(authenticationStatus).then(data => onSuccess(data.data))
        if (authenticationStatus == AuthenticateStatus.AUTHENTICATED)
            useIndexBuyerPurchaseOrdersRelatedData().then(data => {
                // const actions = get().buyerPurchaseOrderTableSliceProperties.
                set(produce(draftState => { draftState.purchaseOrderRelatedData = data.data }))
            })
    },
    loadBuyerPurchaseOrdersRelatedData: (responseHandlers?: ResponseHandlers) => {
        useIndexBuyerPurchaseOrdersRelatedData().then(data => {
            set(produce(draftState => { draftState.purchaseOrderRelatedData = data.data }));
            responseHandlers?.onSuccess?.(data.data)
        }).catch(err => {
            responseHandlers?.onError?.(err);
        })
    },
    subscribeToRequisitions: () => {
        get().subscribeCurrentRequisition()
    },
    updateProject: (formId: string, formType: LinkType, projectId: string, projectName: string, updateProject: (project: GeneralObject) => void) => {
        useEditProject(formId, formType, projectId, projectName).then((data) => {
            updateProject(data.data)
        })
    }
})

export const getVATTotalValues = (products: QuoteProductTableRow[], quantity: string, price: string = "unitPrice") => {
    let subtotal = products.reduce((accumulator, product) => accumulator + (Number(product[price]) * (product[quantity] ?? 0)), 0);
    let vat = VAT * subtotal;

    subtotal = subtotal.toFixed(2);
    vat = vat.toFixed(2);

    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

    return { subtotal: subtotal, vat: vat, total: total };
}

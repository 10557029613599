import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import { Box, Tab, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { StatusTabs } from '../../dumb';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PrivacyButton from '../buttons/privacy-button';
import { PrivacyLevel } from '../buttons/privacy-button/PrivacyButton';

type StyledTabsProps = {
  tabs: any;
  value: number | undefined;
  updateTab: (event: any, newValue: any) => void;
  onChange: (rfqId: number) => void;
  secondText?: string;
  thirdText?: string;
  privacyLevel?: PrivacyLevel
};

const useStyles = makeStyles(theme => {
  return {
    tabs: {
      backgroundColor: theme.palette.background.primary.light,
      '&.MuiButtonBase-root.MuiTab-root': {
        color: theme.palette.text.primary,
        textTransform: 'capitalize',
        fontWeight: 300,
        border: `1px solid ${theme.palette.info.contrastText}`,
        borderRadius: '8px 8px 0px 0px',
        borderBottom: 'none',
        marginRight: 8,
      },
      '& .MuiTab-root.Mui-selected': {
        color: theme.palette.text.primary,
        backgroundColor: 'white',
        borderRadius: '0px 8px 0px 0px',
        textTransform: 'capitalize',
        fontWeight: 700,
        border: 'none',
        borderLeft: `4px solid ${theme.palette.primary.main}`,
      },
      '& .MuiTabs-indicator': {
        display: 'none',
        width: 0,
      },
    },
  };
});

export const StyledTabs: React.FC<StyledTabsProps> = ({ tabs, value, updateTab, onChange, secondText, thirdText, privacyLevel }) => {
  const classes = useStyles();
  return (
    <Tabs
      className={classes.tabs}
      variant='scrollable'
      value={value}
      onChange={(event, newValue) => {
        updateTab(event, newValue), onChange(newValue);
      }}
    >
      {tabs?.map((tab: any) => (
        <Tab
          value={tab.id}
          key={tab.id}
          className={classes.tabs}
          label={
            <Grid item sx={{ maxHeight: '40px' }}>
              <Grid item container alignItems={'center'}>
                <span
                  style={{
                    textAlign: 'start',
                    cursor: 'pointer',
                    lineHeight: 1,
                    display: '-webkit-box',
                    whiteSpace: 'pre-line',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflowY: 'hidden',
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    maxWidth: 200,
                  }}
                >
                  {tab.name}{' '}
                </span>
                {tab.isPrivate && (
                  <PrivacyButton
                    disableText
                    privacyLevel={privacyLevel}
                  />
                )}
                {!secondText && (
                  <Box sx={{ borderRadius: '4px', bgcolor: 'primary.light', p: 0.5, px: 1, ml: 1 }}>{tab.count}</Box>
                )}
              </Grid>
              <Grid item container md={12} gap={1} alignItems={'center'}>
                <Grid item>{tab.status && <StatusTabs status={tab.status} />}</Grid>
                <Grid item pt={0.5}>
                  {tab.isExpired == true && <ErrorOutlineOutlinedIcon sx={{ color: 'error.main', fontSize: '18px' }} />}
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'} justifyContent={'start'}>
                {secondText && (
                  <Typography
                    variant='subtitle2'
                    color='primary.main'
                    fontWeight={400}
                    sx={{ borderRadius: '4px', bgcolor: 'primary.light', px: 1 }}
                  >
                    {' '}
                    {tab.count + secondText}
                  </Typography>
                )}
                {thirdText && (
                  <Typography
                    pl={2}
                    variant='subtitle2'
                    color={tab.detail == 0 ? 'text.third' : 'text.success'}
                    fontWeight={400}
                  >
                    {' '}
                    {tab.detail + thirdText}
                  </Typography>
                )}
              </Grid>
            </Grid>
          }
        />
      ))}
    </Tabs>
  );
};

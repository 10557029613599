import {
  Box,
  Grid,
  Stack,
  SvgIconProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import Image from 'next/image';

import { ResponsiveContainer } from '@/styles';
import { Link, Logo } from '@/components';
import { sm } from 'src/site-map';
import { FB, INS, Linkedin, PH, EM } from '../../../../../public/assets/icons';
import { httpConfig } from '@/config';
import { useTranslation } from 'next-i18next';
import { getFooterTranslationKey } from '@/hooks';

interface Props { }

export const MainFooter: React.FC<Props> = () => {
  const { t } = useTranslation();

  const smallScreens = useMediaQuery(t => t.breakpoints.down('lg'));
  return (
    <Grid
      component={ResponsiveContainer}
      container
      sx={{
        justifyContent: 'space-between',
        py: 4,
        height: '110%',
      }}
    >
      <Grid order={[1, 1, 1, 0]} item lg={5} xs={12} mt={smallScreens ? 4 : 0}>
        <Link href={sm.home.url} underline='none'>
          <Logo />
        </Link>
        <Typography
          mt={2}
          variant='body2'
          lineHeight={1.7}
          sx={{
            color: 'text.third',
            fontWeight: '300',
            width: smallScreens ? '100%' : '70%',
          }}
        >
          {t(getFooterTranslationKey('description'))}
        </Typography>
      </Grid>
      <Grid item lg={5} mt={1} xs={12}>
        <Typography variant='h6' mb={2} fontWeight='500'>
          {t(getFooterTranslationKey('titles.information'))}
        </Typography>
        <Grid container sx={{ width: smallScreens ? '100%' : '80%' }}>
          <Grid item lg={6} xs={6} pb={2}>
            <Stack spacing={2}>
              {[
                [t('pages_names.'+sm.products.index.title), sm.products.index.url],
                [t('pages_names.'+sm.becomeASeller.title), sm.becomeASeller.url],
                [t('pages_names.'+sm.careers.index.title), sm.careers.index.url],
              ].map(el => (
                <Link
                  key={el[0]}
                  href={el[1]}
                  underline='hover'
                  sx={{
                    color: 'text.third',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  {el[0]}
                </Link>
              ))}
            </Stack>
          </Grid>
          <Grid item lg={6} xs={6}>
            <Stack spacing={2}>
              {[
                [t('pages_names.'+sm.contactUs.title), sm.contactUs.url],
                [t('texts.privacy_policy'), httpConfig.domain + '/privacy_policy'],
                [t('texts.terms_and_conditions'), httpConfig.domain + '/terms_conditions'],
              ].map((el, i) => (
                <Link
                  key={el[0]}
                  href={el[1]}
                  underline='hover'
                  sx={{
                    color: '#242833',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                >
                  {el[0]}
                </Link>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={2} mt={smallScreens ? 2 : 1} xs={12}>
        <Typography variant='h6' mb={2} fontWeight='500'>
          {t(getFooterTranslationKey('titles.our_contacts'))}
        </Typography>
        <Grid container>
          <Grid item lg={1}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                height: '24px',
                width: '24px',
              }}
            >
              <Image src={EM} layout='fill' objectFit='fill' alt='Fancy' />
            </Box>
          </Grid>
          <Grid item lg={11}>
            <Typography
              ml={2}
              onClick={() =>
                (window.location.href = 'mailto:info@eprocurement.ae')
              }
              variant='body2'
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                },
                cursor: 'pointer',
                color: 'text.third',
                fontWeight: '400',
                width: '100%',
              }}
            >
              info@eprocurement.ae
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container>
            <Image
              height='24px'
              width='24px'
              src={MAP}
              objectFit='none'
              alt='MAP'
            />
            <Typography
              ml={1}
              mt={2}
              onClick={() => window.open('https://maps.google.com')}
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                },
                cursor: 'pointer',
                color: 'text.third',
                fontSize: '14px',
                fontWeight: '400',
                width: '304px',
                lineHeight: '1.4',
              }}
            >
              1418 Riverwood Drive, Suite 3245 Cottonwood, CA 96052, US
            </Typography> */}
        {/* </Grid> */}
        <Stack mt={smallScreens ? 2 : 5} direction='row'>
          <Stack
            sx={{
              '&:hover': {
                transform: 'scale(1.1)',
              },
              cursor: 'pointer',
            }}
            mr={2}
          >
            <Image
              onClick={() =>
                window.open('https://www.facebook.com/eprocurement.ae/')
              }
              src={FB}
              objectFit='contain'
              alt='Fancy'
            />
          </Stack>
          <Stack
            sx={{
              '&:hover': {
                transform: 'scale(1.1)',
              },
              cursor: 'pointer',
            }}
            mr={2}
          >
            <Image
              onClick={() =>
                window.open(
                  'https://instagram.com/eprocurementofficial?utm_medium=copy_link'
                )
              }
              src={INS}
              objectFit='contain'
              alt='Fancy'
            />
          </Stack>
          <Stack
            sx={{
              '&:hover': {
                transform: 'scale(1.1)',
              },
              cursor: 'pointer',
              justifyContent: 'center',
              alignContent: 'center',
              bgcolor: '#D8ECF5',
              height: 40,
              width: 40,
              borderRadius: 1,
            }}
            mr={2}
          >
            <Image
              onClick={() =>
                window.open('https://www.linkedin.com/company/eprocurement-ae')
              }
              src={Linkedin}
              objectFit='contain'
              alt='Fancy'
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const IDC: React.VFC<{
  icon: React.ComponentType<SvgIconProps>;
  label: string;
}> = ({ label, icon: Icon }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Icon
      color='primary'
      fontSize='medium'
      sx={{ bgcolor: 'background.primary.hover', borderRadius: 1, p: 0.3 }}
    />
    <Typography variant='h6' sx={{ ml: 2, color: 'text.third' }}>
      {label}
    </Typography>
  </Box>
);

import { UserPortalPermission } from "@/config";
import { GeneralObject, Product, Quote, QuoteDetails } from "@/models";
import { buildQuoteDetails, convertStatusToApproverStatus, useChangeProject, useGetAllTerms, useGetQuoteDetails, useUpdateQuoteFromScratch } from "@/services";
import produce from "immer";
import { GeneralTermsPayload, TermPayload } from "src/logic/models/procurement/general-terms";
import { useUserData } from "src/logic/zustand/user";
import { StateCreator } from "zustand";
import { GeneralTermsSlice } from "../../shared/createGeneralTermsSlice";
import { isTermsEqual } from "src/ui/components/smart/general-terms/utils";
import { VAT } from "@/constants";

type ResponseHandlers = {
    onSuccess?: (data: any) => void;
    onError?: () => void;
  }

export interface EditQuoteFromScratchSlice {
    editQuoteFromScratchSliceProperties: {
        terms: any
        quote: QuoteDetails | null
        products: Product[]
        loadingQuoteDetails: boolean
        summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
        isTermsValid: boolean
        payment_term: TermPayload
        warranty_term: TermPayload
        delivery_term: TermPayload
        isChangesApplied: boolean
    }
    editQuoteFromScratchSliceActions: {
        fetchQuoteDetails: (quoteId: number) => void
        setIsLoading: (isLoading: boolean) => void
        setQuoteDetails: (quote: Quote) => void
        changeProject: (project: GeneralObject) => void
        resetQuoteDetails: (data: Quote) => void
        setSummaryDetails: (products: Product[]) => void
        fetchTerms: () => void
        selectGeneralTerms: (terms: GeneralTermsPayload) => void
        handleSelectWarrantyTermForMainProduct: (main_product_id: number, warranty_term: TermPayload) => void;
        changeQuantity: (main_product_id: number, newQuantity: number) => void;
        changeAdditionalRequirements: (main_product_id: number, additional_requirements: string) => void;
        changePrice: (main_product_id: number, newPrice: number) => void;
        removeProduct: (main_product_id: number) => void;
        changeRemark: (main_product_id: number, newRemark: string) => void;
        saveChanges: (responseHandlers: ResponseHandlers) => void;
        updateProductsFromEnvironment: (products: Product[]) => void;
        resetChangesIsApplied: () => void;
    }
}

export const createEditQuoteFromScratchSlice: StateCreator<EditQuoteFromScratchSlice> = (set, get, api) => ({
    editQuoteFromScratchSliceProperties: {
        quote: null,
        products: [],
        loadingQuoteDetails: false,
        summaryDetails: { SummarySubtotal: 0, SummaryVat: 0, SummaryTotal: 0 },
        terms: null,
        isTermsValid: false,
        payment_term: { id: null, name: '' },
        warranty_term: { id: null, name: '' },
        delivery_term: { id: null, name: '' },
        isChangesApplied: false,
    },
    editQuoteFromScratchSliceActions: {
        resetChangesIsApplied: () => {
            set(produce(draftState => {
                draftState.editQuoteFromScratchSliceProperties.isChangesApplied = false;
            }))
        },
        fetchQuoteDetails: (quoteId: number) => {
            const quote = get().editQuoteFromScratchSliceProperties.quote;
            const sameQuote = quote !== null && quote.quoteModel?.id == quoteId;
            if(sameQuote && get().editQuoteFromScratchSliceProperties.isChangesApplied) {
                return;
            }

            get().editQuoteFromScratchSliceActions.setIsLoading(true)
            useGetQuoteDetails(quoteId).then(data => {
                const userId = useUserData.getState().userInfo?.id
                const canApproveQuote = useUserData.getState().canApproveQuote
                let isOwner = data.data.owner_id == userId
                let userPermission = isOwner ? UserPortalPermission.owner : UserPortalPermission.approver
                let approver = data.data.approvers.filter(approver => approver.approver_id == userId)[0]
                set(produce(draftState => {
                    draftState.editQuoteFromScratchSliceProperties.userPermission = userPermission
                    draftState.editQuoteFromScratchSliceProperties.canApproveQuote = canApproveQuote
                    draftState.editQuoteFromScratchSliceProperties.approverId = approver?.approver_id ?? 0
                    draftState.editQuoteFromScratchSliceProperties.isTermsValid = false
                    draftState.editQuoteFromScratchSliceProperties.customLoadingButton = null
                    draftState.editQuoteFromScratchSliceProperties.isChangesApplied = false
                }))
                get().editQuoteFromScratchSliceActions.setQuoteDetails(data.data)
                get().editQuoteFromScratchSliceActions.fetchTerms()
                get().editQuoteFromScratchSliceActions.setIsLoading(false)
            })
        },
        fetchDetails: (formId: string, handlerAfterFetch: (quote: Quote) => void) => {
            useGetQuoteDetails(formId).then(data => {
                handlerAfterFetch({ ...data.data, products: getQuoteProductsArray(data.data.quote_products, get().editQuoteFromScratchSliceProperties.warranty_term) })
            })
        },
        // setBundleRFQDeleted: (isDeleted: boolean) => set(produce(draftState => { draftState.editQuoteFromScratchSliceProperties.isBundleRFQDeleted = isDeleted })),
        setIsLoading: (isLoading: boolean) => set(produce(draftState => { draftState.editQuoteFromScratchSliceProperties.loadingQuoteDetails = isLoading })),
        setQuoteDetails: (quote: Quote) => {
            let userPermission = get().editQuoteFromScratchSliceProperties.userPermission
            let approverStatus = ''
            let approver = quote.approvers?.filter(approver => approver.approver_id == get().editQuoteFromScratchSliceProperties.approverId)[0]
            if (approver) approverStatus = convertStatusToApproverStatus(approver?.status)
            let quoteWithStatus = { ...quote, status: userPermission == UserPortalPermission.owner || !approverStatus ? quote.status : approverStatus }
            set(produce(draftState => {
                draftState.editQuoteFromScratchSliceProperties.quote = buildQuoteDetails(quoteWithStatus, userPermission, get().editQuoteFromScratchSliceProperties.canApproveQuote)
                draftState.editQuoteFromScratchSliceProperties.newComments = quoteWithStatus.new_comments?.length == 0 ? null : quoteWithStatus.new_comments
                draftState.editQuoteFromScratchSliceProperties.customLoadingButton = null
                draftState.editQuoteFromScratchSliceProperties.sendForApprovalPopup = false
                draftState.editQuoteFromScratchSliceProperties.numberOfProductsWithNoPrice = quote?.quote_products.filter(product => product.unit_price === null).length
                draftState.editQuoteFromScratchSliceProperties.products = getQuoteProductsArray(quote?.quote_products, quote.terms_bundle?.warranty_term ?? { id: null, name: '' })
                draftState.editQuoteFromScratchSliceProperties.checkIfTermsValid = false
                draftState.editQuoteFromScratchSliceProperties.payment_term = quote.terms_bundle?.payment_term ?? { id: null, name: '' }
                draftState.editQuoteFromScratchSliceProperties.warranty_term = quote.terms_bundle?.warranty_term ?? { id: null, name: '' }
                draftState.editQuoteFromScratchSliceProperties.delivery_term = quote.terms_bundle?.delivery_term ?? { id: null, name: '' }
            }))
            // get().editQuoteFromScratchSliceActions.setProductsTableRows(get().editQuoteFromScratchSliceProperties.quote)
            get().editQuoteFromScratchSliceActions.setSummaryDetails(getQuoteProductsArray(quote?.quote_products, get().editQuoteFromScratchSliceProperties.warranty_term))
            get().setTimeline(quote.timeline);
        },
        changeProject: (project: GeneralObject) => {
            const modelAfterUpdateProject = useChangeProject(project, get().editQuoteFromScratchSliceProperties.quote?.quoteModel)
            get().editQuoteFromScratchSliceActions.setQuoteDetails(modelAfterUpdateProject)
        },
        resetQuoteDetails: (data) => {
            set(produce(draftState => {
                draftState.editQuoteFromScratchSliceProperties.popupReasonId = 0
                draftState.editQuoteFromScratchSliceProperties.openedPopupStatus = null
                draftState.editQuoteFromScratchSliceProperties.popupComment = null
                // draftState.editQuoteFromScratchSliceProperties.quote = buildQuoteDetails(data, get().editQuoteFromScratchSliceProperties.isOwner)
            }))
            get().editQuoteFromScratchSliceActions.setQuoteDetails(data)
        },
        setSummaryDetails: (products: Product[]) => {
            const summary = getVATTotalValues(products, 'quantity')
            set(produce(draftState => {
                draftState.editQuoteFromScratchSliceProperties.summaryDetails.SummarySubtotal = summary.subtotal
                draftState.editQuoteFromScratchSliceProperties.summaryDetails.SummaryVat = summary.vat
                draftState.editQuoteFromScratchSliceProperties.summaryDetails.SummaryTotal = summary.total
            }))
        },
        fetchTerms: () => {
            const generalTermsSlice = get() as GeneralTermsSlice;

            const terms = get().editQuoteFromScratchSliceProperties.terms;
            const terms_bundle = get().editQuoteFromScratchSliceProperties.quote?.quoteModel?.terms_bundle;

            if (terms) {
              generalTermsSlice.generalTerms.actions.initializeGeneralTerms(terms, terms_bundle);
              return;
            }

            generalTermsSlice.generalTerms.actions.setIsLoading(true);
            useGetAllTerms().then(data => {
                const allTerms = data.data
                set(produce(draftState => {
                    draftState.editQuoteFromScratchSliceProperties.terms = allTerms;
                }))
                generalTermsSlice.generalTerms.actions.initializeGeneralTerms(allTerms, terms_bundle);
                generalTermsSlice.generalTerms.actions.setIsLoading(false);
            })
        },
        selectGeneralTerms: (terms: GeneralTermsPayload) => {
            const { payment_term, warranty_term, delivery_term } = terms;
            const isTermsValid = (payment_term?.id && warranty_term?.id && delivery_term?.id) ? true : false

            set(produce(draftState => {
                draftState.editQuoteFromScratchSliceProperties.payment_term = payment_term ?? { id: null, name: '' }
                draftState.editQuoteFromScratchSliceProperties.warranty_term = warranty_term ?? { id: null, name: '' }
                draftState.editQuoteFromScratchSliceProperties.delivery_term = delivery_term ?? { id: null, name: '' }
                draftState.editQuoteFromScratchSliceProperties.isTermsValid = isTermsValid
                draftState.editQuoteFromScratchSliceProperties.checkIfTermsValid = false
            }))
        },
        handleSelectWarrantyTermForMainProduct: (main_product_id: number, warranty_term: TermPayload) => {
            const products = get().editQuoteFromScratchSliceProperties.products;
            const updatedProducts = products?.map(prod => {
              if(prod.id === main_product_id) {
                return {
                  ...prod,
                  warranty_term
                }
              }
              return prod;
            });
            set(produce(draftState => {
              draftState.editQuoteFromScratchSliceProperties.products = updatedProducts;
            }))
        },
        changeQuantity: (main_product_id: number, newQuantity: number) => {
            const products = get().editQuoteFromScratchSliceProperties.products;
            const updatedProducts = products?.map(prod => {
              if(prod.id === main_product_id) {
                return {
                  ...prod,
                  quantity: newQuantity
                }
              }
              return prod;
            });
            set(produce(draftState => {
              draftState.editQuoteFromScratchSliceProperties.products = updatedProducts;
            }))
            get().editQuoteFromScratchSliceActions.setSummaryDetails(updatedProducts);
        },
        changeAdditionalRequirements: (main_product_id: number, additional_requirements: string) => {
            const products = get().editQuoteFromScratchSliceProperties.products;
            const updatedProducts = products?.map(prod => {
              if(prod.id === main_product_id) {
                return {
                  ...prod,
                  additional_requirements
                }
              }
              return prod;
            });
            set(produce(draftState => {
              draftState.editQuoteFromScratchSliceProperties.products = updatedProducts;
            }))
            get().editQuoteFromScratchSliceActions.setSummaryDetails(updatedProducts);
        },
        changePrice: (main_product_id: number, newPrice: number) => {
            const products = get().editQuoteFromScratchSliceProperties.products;
            const updatedProducts = products?.map(prod => {
              if(prod.id === main_product_id) {
                return {
                  ...prod,
                  price: newPrice
                }
              }
              return prod;
            });
            set(produce(draftState => {
              draftState.editQuoteFromScratchSliceProperties.products = updatedProducts;
            }))
            get().editQuoteFromScratchSliceActions.setSummaryDetails(updatedProducts);
        },
        removeProduct: (main_product_id: number) => {
            const products = get().editQuoteFromScratchSliceProperties.products;
            const updatedProducts = products?.filter(prod => prod.id !== main_product_id);
            set(produce(draftState => {
              draftState.editQuoteFromScratchSliceProperties.products = updatedProducts;
            }))
            get().editQuoteFromScratchSliceActions.setSummaryDetails(updatedProducts);
        },
        changeRemark: (main_product_id: number, newRemark: string) => {
            const products = get().editQuoteFromScratchSliceProperties.products;
            const updatedProducts = products?.map(prod => {
              if(prod.id === main_product_id) {
                return {
                  ...prod,
                  remarks: newRemark
                }
              }
              return prod;
            });
            set(produce(draftState => {
              draftState.editQuoteFromScratchSliceProperties.products = updatedProducts;
            }))
            get().editQuoteFromScratchSliceActions.setSummaryDetails(updatedProducts);
        },
        saveChanges: ({ onSuccess, onError }: ResponseHandlers) => {
            const quoteId = get().editQuoteFromScratchSliceProperties.quote?.quoteModel?.id;
      
            const productsToSave = get().editQuoteFromScratchSliceProperties.products.map(product => ({
                id: product.id,
                quantity: product.quantity,
                price: product.price,
                warranty_term_id: product.warranty_term?.id ?? null,
                warranty_term_custom_value: product.warranty_term?.value ?? null,
                remarks: product.remarks ?? null,
                additional_requirements: product.additional_requirements ?? null,
            }))
      
            const payment_term = get().editQuoteFromScratchSliceProperties.payment_term;
            const warranty_term = get().editQuoteFromScratchSliceProperties.warranty_term;
            const delivery_term = get().editQuoteFromScratchSliceProperties.delivery_term;
      
            useUpdateQuoteFromScratch(quoteId, {
              general_terms: {
                payment_term,
                warranty_term,
                delivery_term
              },
              products: productsToSave,
            }).then(data => {
              onSuccess?.(data.data);
              get().editQuoteFromScratchSliceActions.resetChangesIsApplied()
            }).catch(() => {
              onError?.();
            })
      
        },
        updateProductsFromEnvironment: (products: Product[]) => {
            set(produce(draftState => {
                draftState.editQuoteFromScratchSliceProperties.products = products;
                draftState.editQuoteFromScratchSliceProperties.isChangesApplied = true;
            }))
            get().editQuoteFromScratchSliceActions.setSummaryDetails(products);
        },
        
        
    }
})


export const getQuoteProductsArray = (products: Product[], warrantyTerm: GeneralObject) => {
    // return products.map(product => { return { ...product, ...product.product, quantity: product.available_quantity, price: product.unit_price } })

    return products.map(product => { return { ...product, ...product.product, quantity: product.available_quantity, price: product.unit_price, warranty_term: product.warranty_term ? product.warranty_term : warrantyTerm, isCustomWarranty: !isTermsEqual(warrantyTerm, product.warranty_term) } })
}
export const getVATTotalValues = (products: Product[], quantity: string) => {

    let subtotal = products.reduce((accumulator, product) => accumulator + (Number(product.price) * product.quantity), 0);
    let vat = VAT * subtotal;

    subtotal = subtotal.toFixed(2);
    vat = vat.toFixed(2);

    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

    return { subtotal: subtotal, vat: vat, total: total };
}

import {
  createTheme as createMuiTheme, Direction, Options, responsiveFontSizes, Theme,
  ThemeOptions
} from '@mui/material';
import darkScrollbar from '@mui/material/darkScrollbar';
import merge from 'lodash/merge';
import { shadows } from './shadows';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    default: string;
    paper: string;
    primary: {
      main: string;
      hover: string;
      active: string;
    };
    secondary: {
      main: string;
      hover: string;
      active: string;
    };
  }
  interface TypeText {
    third: string;
    forth: string;
    black: string;
    success: string;
  }

  interface TypeAction {
    lightBackground: string;
  }
}
declare module '@mui/material' {
  function useMediaQuery<T = Theme>(
    query: string | ((theme: T) => string),
    options?: Options
  ): boolean;

  interface PaletteColor {
    darker?: string;
    hover?: string;
    active?: string;
    grayish?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
    hover?: string;
    active?: string;
    grayish?: string;
  }
  interface ButtonPropsVariantOverrides {
    outlined_background: true;
  }
}

interface ThemeConfig {
  direction?: Direction;
}

const baseOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    divider: '#DADCE0',
    primary: {
      main: '#0F5D85',
      hover: '#074B6E',
      active: '#053A55',
      light: '#EAEEF6',
      darker: '#DEDEDE',
      dark: '#F0F2F5',
      grayish: '#D9D9D9',
    },
    secondary: {
      main: '#FB7F0D',
      hover: '#FB7F0D',
      active: '#FFB048',
      contrastText: '#fff',
      dark: '#C7C6C6',
    },
    tertiary: {
      main: '#189BDC',
      hover: '#189BDC',
      active: '#189BDC',
      dark: '#0F5D85',
    },
    background: {
      primary: {
        main: '#F6F8FC',
        hover: '#DCE7EE',
        active: '#B8D0DD',
      },
      secondary: {
        main: '#FFF8ED',
        hover: '#FEE5CF',
        active: '#FED9B6',
      },
    },
    text: {
      primary: '#00385E',
      secondary: '#747783',
      third: '#8B8D98',
      forth: '#c4c4c4',
      disabled: '#3A3E50',
      black: '#000000',
      success: '#0AB87B',
    },
    action: {
      disabledBackground: '#E8E8EA',
      selected: '#00874F',
      lightBackground: '#F3F6F9',
    },
    success: {
      main: '#0AB87B',
      light: '#DAF4EB',
      dark: '#0AB87B',
    },
    info: {
      light: '#C5C6CB',
      main: '#518FBC',
      dark: '#FF4D4D',
      contrastText: '#D0E0E8',
    },
    warning: {
      main: '#F9D835',
      dark: '#FAFAFB',
      light: '#EAEAEA',
      active: '#89B1C6',
      contrastText: '#F6D9DE',
    },
    error: {
      main: '#FF4D4D',
      hover: '#FF4D4D',
      darker: '#F7F7F7',
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  shadows,
  typography: {
    allVariants: {
      lineHeight: 1.8,
    },
    htmlFontSize: 14,
    fontSize: 14,

    h1: {
      fontSize: '2.25rem',
      lineHeight: 1.3,
    },
    h2: {
      fontSize: '1.875rem', //30px
      lineHeight: 1.3,
    },
    h3: {
      fontSize: '1.625rem',
      lineHeight: 1.7,
    },
    h4: {
      fontSize: '1.75rem',
      lineHeight: 1.3,
    },
    h5: {
      fontSize: '1.5rem', //24px
      lineHeight: 1.5,
    },
    h6: {
      fontSize: '1rem', //16px
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '0.938rem',
      lineHeight: 1.7,
    },
    body2: {
      fontSize: '0.875rem', //14px
      lineHeight: '23.8px',
    },
    subtitle1: {
      fontSize: '0.813rem',
      lineHeight: 1.6,
    },
    subtitle2: {
      fontSize: '0.75rem', //12px
      lineHeight: 1.5,
    },
    overline: {
      fontSize: '1.125rem', //18px
      lineHeight: 1.5,
      textTransform: 'none',
      letterSpacing: 'inhirit',
    },
    button: {
      fontSize: '0.875rem',
      // fontWeight: 500
      fontWeight: 400,
    },
    caption: {
      fontSize: '0.625rem',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '.embla': {
          // overflow: 'hidden',
        },
        '.embla__container': {
          // display: 'flex',
        },
        '.embla__slide': {
          position: 'relative',
          flex: '0 0 100%',
        },
        '::selection': {
          backgroundColor: '#0F5D85',
          color: 'white',
        },
        html: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
        body: {
          // maxWidth: 2000,
          margin: '0 auto',
          // overflowX: 'hidden',
          ...(false ? darkScrollbar() : {}),
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          maxHeight: '100%',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: 2,
            height: 2,
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: `white`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#0F5D85',
            outline: `1px solid slategrey`,
            borderRadius: 3,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '& .MuiChip-label': { fontWeight: 500, fontSize: '12px' },
          height: '28px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        withChildren: {
          display: 'grid',
          gridTemplateRows: '1fr',
          gridTemplateColumns: '1fr auto 1fr',
          placeItems: 'center',
          '&::before': {
            top: 'auto',
            left: 'auto',
            height: 'auto',
            width: '100%',
          },
          '&::after': {
            top: 'auto',
            left: 'auto',
            height: 'auto',
            width: '100%',
          },
        },
        withChildrenVertical: {
          gridTemplateRows: '1fr auto 1fr',
          gridTemplateColumns: '1fr',
          '&::before': {
            top: 'auto',
            left: 'auto',
            height: '100%',
            width: 'auto',
          },
          '&::after': {
            top: 'auto',
            left: 'auto',
            height: '100%',
            width: 'auto',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'rgb(19, 98, 140, 0.1)',
        },
        root: {
          '& :active': {
            backgroundColor: 'rgb(19, 98, 140, 0.1)',
            borderRadius: '4px',
          },
          '& :hover': {
            backgroundColor: 'rgb(19, 98, 140, 0.1)',
            borderRadius: '4px',
          },
          borderRadius: '4px',
          height: '2rem',
          width: '2rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '15px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          maxHeight: '35px',
          height: '35px',
          input: {
            maxHeight: '35px',
            height: '35px',
            fontSize: '0.75rem',
            color: '#000',
            '&:-webkit-autofill': {
              height: '5px',
            },
            '&:hover': {
              outline: 'none',
              borderStyle: 'none',
            },
          },
          '& ::placeholder': {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: '#8B8D98',
            alignSelf: 'center',
            opacity: 1,
          },
        },
        multiline: {
          '& ::placeholder': {
            fontSize: '0.75rem',
            color: '#8B8D98',
            alignSelf: 'center',
            opacity: 1,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#8B8D98',
          fontSize: '12px',
          '&.Mui-selected': {
            // <-- mixing the two classes
            backgroundColor: '#D0E0E8',
            borderRadius: '4px',
            color: '#518FBC',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          px: 1,
          '&[aria-selected="true"]': {
            backgroundColor: '#D0E0E8',
            borderRadius: '4px',
            color: '#518FBC',
          },
          color: '#8B8D98',
          fontSize: '12px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '12px !important',
          color: '#8B8D98',
          borderWidth: '0.08px',
          backgroundColor: 'white',
          borderColor: '#0F5D85',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#DEDEDE',
          },
          '& .MuiSelect-icon': {
            color: 'text.third',
          },
          width: '100%',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined_background', color: 'secondary' },
          style: {
            color: '#FB7F0D',
            backgroundColor: '#FEE5CF',
            '&:hover': {
              backgroundColor: '#FEE5CF',
            },
            // borderColor: '#F6D9DE',
          },
        },
        {
          props: { variant: 'outlined_background', color: 'primary' },
          style: {
            color: '#0F5D85',
            backgroundColor: 'rgb(19, 98, 140, 0.1)',
            '&:hover': {
              backgroundColor: 'rgb(19, 98, 140, 0.1)',
            },
            // borderColor: '#F6D9DE',
          },
        },
        {
          props: { variant: 'outlined_background', color: 'success' },
          style: {
            color: '#0AB87B',
            backgroundColor: '#DAF4EB',
            '&:hover': {
              backgroundColor: '#DAF4EB',
            },
            // borderColor: '#F6D9DE',
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '0.875em',
          '@media (max-width:1500px)': {
            fontSize: '0.70em',
          },
          maxHeight: '35px',
          height: '35px',
        },
        sizeLarge: {
          padding: '8px 40px',
        },
        outlinedSizeLarge: {
          borderWidth: 1,
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#4480AC',
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: '#FB7F0D',
          },
        },
        containedWarning: {
          color: '#000',
          backgroundColor: '#E8E8EA',
          '&:hover': {
            backgroundColor: '#E8E8EA',
          },
        },
        outlinedSuccess: {
          color: '#0AB87B',
          borderColor: '#0AB87B',
          '&:hover': {
            borderColor: '#0AB87B',
          },
        },
        LightError: {
          color: '#FF4D4D',
          backgroundColor: 'rgb(246, 217, 222, 1)',
          '&:hover': {
            backgroundColor: 'rgb(246, 217, 222, 1)',
          },
        },
        containedInfo: {
          color: '#13628C',
          fontWeight: 500,
          borderColor: '#D9D9D9',
          backgroundColor: 'rgb(19, 98, 140, 0.1)',
          '&:hover': {
            backgroundColor: 'rgb(19, 98, 140, 0.1)',
          },
        },
        containedSuccess: {
          color: '#FFF',
          backgroundColor: '#0AB87B',
          '&:hover': {
            backgroundColor: '#0AB87B',
          },
        },
      },
    },
  },
};

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let theme = createMuiTheme(
    merge({}, baseOptions, {
      direction: config.direction,
    })
  );
  theme = responsiveFontSizes(theme);

  return theme;
};

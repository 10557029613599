import { HistoryType, PurchaseHistoryItem, SalesHistoryItem, StyledPopper } from '@/components';
import HistoryIcon from '@mui/icons-material/History';
import { Button, ButtonGroup, Grid, SxProps, Typography } from '@mui/material';
import React, { useState } from 'react';

type ProductHistoryProps = {
  quote_items: any[] | null;
  po_items: any[] | null;
  iconText?: React.ReactNode;
  iconColor?: string;
  iconSx?: SxProps;
};

export const FullProductHistory: React.VFC<ProductHistoryProps> = ({
  quote_items,
  po_items,
  iconText,
  iconColor,
  iconSx = {},
}) => {
  const [type, setType] = useState<HistoryType>(HistoryType.sales);
  const items = (type === HistoryType.purchase ? po_items : quote_items) || [];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClosePopper = () => setAnchorEl(null);

  return (
    <>
      <span
        onClick={e => {
          // e.stopPropagation();
          setAnchorEl(anchorEl ? null : e.currentTarget);
        }}
        style={{ cursor: 'pointer' }}
      >
        <HistoryIcon sx={{ fontSize: '20px !important', ml: 1.5, color: iconColor ?? '#518FBC', ...iconSx }} />
        {iconText}
      </span>

      <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClosePopper} zIndex={1300}>
        <Grid container md={12} px={2} py={3} width={450}>
          <ButtonGroup sx={{ marginInline: 'auto' }}>
            {[
              { label: 'Quotes', value: HistoryType.sales },
              { label: 'POs', value: HistoryType.purchase },
            ].map(({ label, value }) => (
              <Button
                key={value}
                type='button'
                variant={type === value ? 'contained' : 'outlined'}
                onClick={() => {
                  if (type !== value) {
                    setType(value);
                  }
                }}
                sx={{
                  borderRadius: '5px',
                  width: '75px',
                  bgcolor: type === value ? '#0F5D85' : 'white',
                  color: type === value ? 'white' : '#0F5D85',
                  border: type === value ? 'none' : '1px solid #0F5D85',
                  '&:hover': {
                    bgcolor: type === value ? '#0F5D85' : 'white',
                  },
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                {label}
              </Button>
            ))}
          </ButtonGroup>

          <Grid
            container
            md={12}
            py={2}
            px={1}
            sx={{ maxHeight: '300px', overflowY: 'auto' }}
            className='styled-scrollbar'
            onMouseDown={e => e.stopPropagation()}
          >
            {items.length === 0 ? (
              <Typography
                color='#8B8D98'
                fontSize={15}
                fontWeight={400}
                py={3}
                textAlign='center'
                sx={{ marginInline: 'auto' }}
              >
                {type === HistoryType.purchase && `0 PO Forms Available`}
                {type === HistoryType.sales && `0 Quote Forms Available`}
              </Typography>
            ) : (
              <>
                {items?.map((item: any) => {
                  const Item = type === HistoryType.purchase ? PurchaseHistoryItem : SalesHistoryItem;
                  return <Item key={item.id} item={item} />;
                })}
              </>
            )}
          </Grid>
        </Grid>
      </StyledPopper>
    </>
  );
};

export default FullProductHistory;

import { Typography } from '@mui/material';
import { StyledComment } from 'src/ui/components/dumb';
import { DescPopup } from 'src/ui/components/shared';
import { ChatErrorIcon } from 'src/ui/icons/error-chat-icon';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  rejection_history?: any[];
};

const RejectionHistoryPopup = ({ isOpen, onClose, rejection_history }: IProps) => {
  return (
    <DescPopup handleClose={onClose} open={isOpen} medium>
      <div
        style={{
          width: '100%',
          padding: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ChatErrorIcon sx={{ color: '#FF4D4D', fontSize: '30px', mx: 'auto' }} />
        <Typography textAlign={'center'} fontSize={19} fontWeight={700} color='#242833' mb={1}>
          Rejected Requests History
        </Typography>
        {rejection_history?.map(comment => (
          <div
            key={comment.id}
            style={{
              backgroundColor: '#F7F7F7',
              borderRadius: '8px',
              width: '100%',
              padding: '1rem',
              marginBlock: '0.5rem',
            }}
          >
            <StyledComment comment={comment} />
          </div>
        ))}
      </div>
    </DescPopup>
  );
};

export default RejectionHistoryPopup;

import React, { memo, useEffect, useMemo, useState } from 'react';

import { BreadcrumbsConfig, ProductManagerEnv } from '@/config';
import { ArrowBack, ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, BreadcrumbsProps, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Link } from '..';
import { sm } from 'src/site-map';
import { useProcurementStore } from '@/zustand';

export type StyledBreadcrumbsProps = {
  alias: string;
  dynammicAlias?: string[];
  dynammicIds?: string[];
  showBackButton?: boolean;
  path?: string;
  maxItems?: number;
} & BreadcrumbsProps;

export const StyledBreadcrumbs: React.VFC<StyledBreadcrumbsProps> = memo(
  ({ alias, dynammicAlias, dynammicIds, showBackButton, path, maxItems = 2, selectedTab }) => {
    const { asPath, query, push, back } = useRouter();
    const { t } = useTranslation();
    const currentBreadCrumbsConfigs = BreadcrumbsConfig[alias](dynammicAlias ? dynammicAlias : '');
    const urlConfigs = BreadcrumbsConfig[alias](dynammicIds ? dynammicIds : '');
    const des = useMemo<string>(() => {
      return asPath
        .split('/')
        .slice(0, asPath.split('/').length - 1)
        .join('/');
    }, [asPath]);

    const [canGoBack, setCanGoBack] = useState(false);

    useEffect(() => {
      if (window.history.length > 1) {
        setCanGoBack(true);
      }
    }, []);

    const handleGoBack = () => {
      if (canGoBack) {
        back();
      } else {
        push(des || '/');
      }
    };

    const { data } = useProcurementStore(state => state.productFormManagerProperties);
    const env = data.environment?.type;
    const isPurchaseOrderEnv = env === ProductManagerEnv.purchase_order;
    const isEditPoFromScratchEnv = env === ProductManagerEnv.edit_po_from_scratch;

    const shouldHideBreadcrumbs = (isPurchaseOrderEnv || isEditPoFromScratchEnv) && selectedTab;

    const breadcrumbsToRender = (isPurchaseOrderEnv || isEditPoFromScratchEnv) && selectedTab
      ? [currentBreadCrumbsConfigs[currentBreadCrumbsConfigs.length - 1]]
      : currentBreadCrumbsConfigs;

    return (
      <Grid container pt={2}>
        {!shouldHideBreadcrumbs && showBackButton && (
          <Button
            onClick={handleGoBack}
            startIcon={<ArrowBack sx={{ fontSize: '19px', mr: '-1px' }} />}
            sx={{ color: 'text.primary', mr: 1, alignItems: 'center', height: '20px' }}
          >
            <Typography variant='subtitle2'>{t('buttons.go_back')}</Typography>
          </Button>
        )}
        {!shouldHideBreadcrumbs && (
          <Breadcrumbs
            maxItems={maxItems}
            separator={<ChevronRight sx={{ mx: -1, fontSize: '20px !important', pt: -1 }} />}
          >
            {breadcrumbsToRender?.map((el, i) => {
              const isLastItem = i === breadcrumbsToRender.length - 1;
              const linkPath = selectedTab ? sm.catalogue.index.url : urlConfigs?.[i]?.url || el.url;
              const linkQuery = selectedTab ? { selectedTab } : query;

              return (
                <React.Fragment key={el.title}>
                  {!isLastItem ? (
                    <Link href={{ pathname: linkPath, query: linkQuery }} underline='hover'>
                      <Typography color='text.third' variant='subtitle2' textTransform='capitalize'>
                        {dynammicAlias?.includes(el.title) ? el.title : t('pages_names.' + el.title)}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography color='primary' variant='subtitle2' textTransform='capitalize'>
                      {dynammicAlias?.includes(el.title) ? el.title : t('pages_names.' + el.title)}
                    </Typography>
                  )}
                </React.Fragment>
              );
            })}
          </Breadcrumbs>
        )}
      </Grid>
    );
  }
);

import { ProcurementButtons } from '@/config';
import { Product, Seller } from '@/models';
import produce from 'immer';
import {
  AdvancedFilterItem,
  AdvancedFilterOption,
  EnvironmentFilters,
  FilterQueryOptions,
} from 'src/logic/models/catalogue/AdvancedFilters';
import { ResponseHandlers } from 'src/logic/models/queries';
import { applyFilters, onFilterChange } from 'src/logic/services/catalogue/advanced-filters';
import { linkSellersInProducts } from 'src/logic/services/catalogue/private-seller';
import QueryHandler, { QueryHandlerModel } from 'src/logic/services/query-handlers/QueryHandler';
import create from 'zustand';

export interface AdvancedFiltersSlice {
  properties: {
    filterQueryOptions?: FilterQueryOptions;
    environmentFilters: EnvironmentFilters;
    initialAdvancedFiltersDate: null | AdvancedFilterItem[];
    advancedFiltersData: null | AdvancedFilterItem[];
    loadingItemsTypes: string[];
    numberOfLinkedItems: number | string;
    customLoadingButton: ProcurementButtons | null;

    fetchQueryStatus: QueryHandlerModel;
    fetchNextPageQueryStatus: QueryHandlerModel;
    results: any[];
    hasNextPage: boolean;
    current_page: number;
    total_results: number;
  };

  actions: {
    setResults: (data: any[]) => void;
    initializeAdvancedFiltersData: (
      advancedFilters: AdvancedFilterItem[] | null,
      filterQueryOptions?: FilterQueryOptions
    ) => void;
    setEnvironmentFilters: (envFilters: EnvironmentFilters, applyFilters?: boolean) => void;
    setAdvancedFiltersData: (advancedFilters: AdvancedFilterItem[] | null) => void;
    replaceAdvancedFiltersDataChunks: (advancedFiltersChunk: AdvancedFilterItem[]) => void;
    getFilterItemByType: (type: string) => AdvancedFilterItem | undefined;
    startLoading: (types: string[]) => void;
    finishLoading: (types: string[]) => void;
    addOptionToSelectedFilters: (filterItemType: string, option: AdvancedFilterOption) => void;
    removeOptionFromSelectedFilters: (
      filterItemType: string,
      option: AdvancedFilterOption,
      disableOnChange?: boolean
    ) => void;
    clearOption: (filterItemType: string, option: AdvancedFilterOption) => void;
    onChange: (filterItemType: string) => void;
    resetChildrenRecursively: (filterItem: AdvancedFilterItem, visited: string[]) => void;
    resetSelectedFilters: () => void;
    setFetchQueryStatus: (queryHandler: QueryHandlerModel) => void;
    apply: () => void;
    setFetchNextQueryStatus: (queryHandler: QueryHandlerModel) => void;
    fetchNextPage: () => void;

    transformResults: (data: any[]) => any[];
    changeProductQuantity: (product_id: number, quantity: number) => void;
    changeProductRequirements: (product_id: number, additional_requirements: string) => void;
    changeProductPrice: (product_id: number, price: string) => void;

    setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void;
    linkSellerToProduct: (product: Product, seller: Seller, responseHandlers?: ResponseHandlers) => void;
    linkMultipleSellerToProduct: (productId: number, newSellers: Seller[], responseHandlers?: ResponseHandlers) => void;
  };
}

export const useAdvancedFiltersSlice = create<AdvancedFiltersSlice>((set, get) => ({
  properties: {
    filterQueryOptions: undefined,
    environmentFilters: {},
    initialAdvancedFiltersDate: null,
    advancedFiltersData: null,
    loadingItemsTypes: [],
    numberOfLinkedItems: 0,
    customLoadingButton: null,

    fetchQueryStatus: QueryHandler.getInitialStatus(),
    fetchNextPageQueryStatus: QueryHandler.getInitialStatus(),
    results: [],
    current_page: 1,
    total_results: 0,
    hasNextPage: false,
  },

  actions: {
    setResults(data) {
      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.results = data;
        })
      );
    },
    initializeAdvancedFiltersData: (
      advancedFilters: AdvancedFilterItem[] | null,
      filterQueryOptions?: FilterQueryOptions
    ) => {
      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.filterQueryOptions = filterQueryOptions;
          draftState.properties.advancedFiltersData = advancedFilters;
          draftState.properties.initialAdvancedFiltersDate = advancedFilters;
          draftState.properties.fetchQueryStatus = QueryHandler.getInitialStatus();
          draftState.properties.fetchNextPageQueryStatus = QueryHandler.getInitialStatus();
          draftState.properties.current_page = 1;
          draftState.properties.total_results = 0;
          draftState.properties.hasNextPage = false;
          draftState.properties.customLoadingButton = null;
        })
      );
    },
    setEnvironmentFilters: (envFilters: EnvironmentFilters, applyFilters: boolean = false) => {
      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.environmentFilters = envFilters;
        })
      );
      if (applyFilters) {
        get().actions.apply();
      }
    },
    setAdvancedFiltersData: (advancedFilters: AdvancedFilterItem[] | null) => {
      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.advancedFiltersData = advancedFilters;
        })
      );
    },
    setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
      set(
        produce(draftState => {
          draftState.properties.customLoadingButton = customButton;
          if (!customButton) {
            draftState.properties.customLoadingButton = null;
          }
        })
      );
    },
    replaceAdvancedFiltersDataChunks: (advancedFiltersChunk: AdvancedFilterItem[]) => {
      const advancedFiltersData = get().properties.advancedFiltersData;
      if (!advancedFiltersData) {
        get().actions.setAdvancedFiltersData(advancedFiltersChunk);
        return;
      }
      const newAdvancedFiltersData = advancedFiltersData.map(filterItem => {
        const chunkItem = advancedFiltersChunk.find(chunk => chunk.type === filterItem.type);
        if (chunkItem !== undefined) return chunkItem;
        return filterItem;
      });
      get().actions.setAdvancedFiltersData(newAdvancedFiltersData);
    },
    getFilterItemByType: (type: string) => {
      const advancedFiltersData = get().properties.advancedFiltersData;
      return advancedFiltersData?.find(item => item.type === type);
    },
    startLoading: (types: string[]) => {
      const loadingItemsTypes = get().properties.loadingItemsTypes;
      const newLoadingItemsTypes = [...loadingItemsTypes, ...types];
      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.loadingItemsTypes = newLoadingItemsTypes;
        })
      );
    },
    finishLoading: (types: string[]) => {
      const loadingItemsTypes = get().properties.loadingItemsTypes;
      const newLoadingItemsTypes = types.reduce<string[]>(
        (result, type) => {
          const index = result.findIndex(item => item === type);
          if (index !== -1) {
            result.splice(index, 1);
          }
          return result;
        },
        [...loadingItemsTypes]
      );
      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.loadingItemsTypes = newLoadingItemsTypes;
        })
      );
    },
    addOptionToSelectedFilters: (filterItemType: string, option: AdvancedFilterOption) => {
      const advancedFiltersData = get().properties.advancedFiltersData;
      const targetItem = get().actions.getFilterItemByType(filterItemType);

      if (!targetItem || !advancedFiltersData) return;

      let newAdvancedFiltersData = advancedFiltersData;
      const kind = targetItem.kind;
      if (kind === 'SINGLE_SELECT') {
        newAdvancedFiltersData = advancedFiltersData.map(filterItem => {
          if (filterItem.type !== filterItemType) return filterItem;
          return {
            ...filterItem,
            filter_items:
              filterItem.filter_items?.map(itemOption => {
                if (itemOption.id === option.id) return { ...itemOption, selected: true };
                else return { ...itemOption, selected: false };
              }) ?? null,
          };
        });
      } else if (kind === 'MULTI_SELECT') {
        newAdvancedFiltersData = advancedFiltersData.map(filterItem => {
          if (filterItem.type !== filterItemType) return filterItem;
          return {
            ...filterItem,
            filter_items:
              filterItem.filter_items?.map(itemOption => {
                if (itemOption.id === option.id) return { ...itemOption, selected: true };
                else return itemOption;
              }) ?? null,
          };
        });
      }
      get().actions.setAdvancedFiltersData(newAdvancedFiltersData);
      get().actions.onChange(filterItemType);
    },
    removeOptionFromSelectedFilters: (
      filterItemType: string,
      option: AdvancedFilterOption,
      disableOnChange: boolean = false
    ) => {
      const advancedFiltersData = get().properties.advancedFiltersData;
      const targetItem = get().actions.getFilterItemByType(filterItemType);

      if (!targetItem || !advancedFiltersData) return;

      let newAdvancedFiltersData = advancedFiltersData;
      newAdvancedFiltersData = advancedFiltersData.map(filterItem => {
        if (filterItem.type !== filterItemType) return filterItem;
        return {
          ...filterItem,
          filter_items:
            filterItem.filter_items?.map(itemOption => {
              if (itemOption.id === option.id) return { ...itemOption, selected: false };
              else return itemOption;
            }) ?? null,
        };
      });

      get().actions.setAdvancedFiltersData(newAdvancedFiltersData);
      if (!disableOnChange) {
        get().actions.onChange(filterItemType);
      }
    },
    clearOption: (filterItemType: string, option: AdvancedFilterOption) => {
      const targetItem = get().actions.getFilterItemByType(filterItemType);
      if (!targetItem) return;

      if (targetItem.kind === 'SINGLE_SELECT') {
        get().actions.removeOptionFromSelectedFilters(filterItemType, option, true);
        get().actions.resetChildrenRecursively(targetItem, []);
      } else if (targetItem.kind === 'MULTI_SELECT') {
        get().actions.removeOptionFromSelectedFilters(filterItemType, option);
      }
    },
    onChange: (filterItemType: string) => {
      const targetItem = get().actions.getFilterItemByType(filterItemType);
      if (!targetItem || targetItem.dependant_ids.length === 0) return;

      get().actions.startLoading(targetItem.dependant_ids);

      // Fetch the dependant_ids & replace the new items
      onFilterChange(targetItem, get().properties.advancedFiltersData)
        .then(data => {
          // Reset all dependant ids selections
          get().actions.resetChildrenRecursively(targetItem, []);
          const advancedFiltersChunk = data.data;
          get().actions.replaceAdvancedFiltersDataChunks(advancedFiltersChunk);
        })
        .finally(() => {
          get().actions.finishLoading(targetItem.dependant_ids);
        });
    },
    resetChildrenRecursively: (filterItem: AdvancedFilterItem, visited: string[]) => {
      const advancedFiltersData = get().properties.advancedFiltersData;
      if (!advancedFiltersData) return;

      let newAdvancedFiltersData = advancedFiltersData;
      filterItem.dependant_ids
        .filter(dependant_id => !visited.includes(dependant_id))
        .forEach(dependantId => {
          const dependantItem = get().actions.getFilterItemByType(dependantId);
          if (dependantItem !== undefined) {
            newAdvancedFiltersData = newAdvancedFiltersData.map(item => {
              if (item.type === dependantItem.type)
                return {
                  ...dependantItem,
                  // filter_items: dependantItem.filter_items?.map(option => ({ ...option, selected: false })) ?? null,
                  filter_items: [],
                };
              return item;
            });
          }
        });
      get().actions.setAdvancedFiltersData(newAdvancedFiltersData);

      filterItem.dependant_ids.forEach(dependantId => {
        const dependantItem = get().actions.getFilterItemByType(dependantId);
        if (dependantItem !== undefined && !visited.includes(dependantId)) {
          visited.push(filterItem.type);
          get().actions.resetChildrenRecursively(dependantItem, visited);
        }
      });
    },
    resetSelectedFilters: () => {
      get().actions.setAdvancedFiltersData(get().properties.initialAdvancedFiltersDate);
    },
    setFetchQueryStatus: (queryHandler: QueryHandlerModel) => {
      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.fetchQueryStatus = queryHandler;
        })
      );
    },
    apply: () => {
      const advancedFiltersData = get().properties.advancedFiltersData;
      const filterQueryOptions = get().properties.filterQueryOptions;
      const environmentFilters = get().properties.environmentFilters;
      get().actions.setFetchQueryStatus(QueryHandler.getStartStatus());
      applyFilters({
        advancedFiltersData,
        page_number: 1,
        filterQueryOptions,
        environmentFilters,
      })
        .then(data => {
          const results = get().actions.transformResults(data.data.content.data);

          set(
            produce<AdvancedFiltersSlice>(draftState => {
              draftState.properties.results = results;
              draftState.properties.current_page = 1;
              draftState.properties.total_results = data.data.content.total;
              draftState.properties.hasNextPage = data.data.content.last_page > 1;
            })
          );

          get().actions.setFetchQueryStatus(QueryHandler.getSuccessStatus());
        })
        .catch(err => {
          get().actions.setFetchQueryStatus(QueryHandler.getErrorStatus(err));
        });
    },
    setFetchNextQueryStatus: (queryHandler: QueryHandlerModel) => {
      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.fetchNextPageQueryStatus = queryHandler;
        })
      );
    },
    fetchNextPage: () => {
      const advancedFiltersData = get().properties.advancedFiltersData;
      const filterQueryOptions = get().properties.filterQueryOptions;
      const environmentFilters = get().properties.environmentFilters;
      const { results, current_page } = get().properties;
      const next_page = current_page + 1;

      get().actions.setFetchNextQueryStatus(QueryHandler.getStartStatus());
      applyFilters({
        advancedFiltersData,
        page_number: next_page,
        filterQueryOptions,
        environmentFilters,
      })
        .then(data => {
          const newResults = get().actions.transformResults(data.data.content.data);

          set(
            produce<AdvancedFiltersSlice>(draftState => {
              draftState.properties.results = [...results, ...newResults];
              draftState.properties.current_page = next_page;
              draftState.properties.total_results = data.data.content.total;
              draftState.properties.hasNextPage = data.data.content.last_page > next_page;
            })
          );

          get().actions.setFetchNextQueryStatus(QueryHandler.getSuccessStatus());
        })
        .catch(err => {
          get().actions.setFetchNextQueryStatus(QueryHandler.getErrorStatus(err));
        });
    },
    transformResults: (data: any[]) => {
      // return data;
      return data.map(product => {
        return {
          ...product,
          // inCart: false,
          quantity: product.quantity ?? 1,
          price: product.price ?? product.catalog_price ?? '',
        };
      });
      // return data.map(item => ({ ...item, quantity: 1 }));
    },
    changeProductQuantity: (product_id: number, quantity: number) => {
      const results = get().properties.results;
      if (!results) return;

      const newResults = results.map(product => {
        if (product.id === product_id) {
          return { ...product, quantity };
        }
        return product;
      });

      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.results = newResults;
        })
      );
    },
    changeProductRequirements: (product_id: number, additional_requirements: string) => {
      const results = get().properties.results;
      if (!results) return;

      const newResults = results.map(product => {
        if (product.id === product_id) {
          return { ...product, additional_requirements };
        }
        return product;
      });

      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.results = newResults;
        })
      );
    },
    changeProductPrice: (product_id: number, price: string) => {
      const results = get().properties.results;
      if (!results) return;

      const newResults = results.map(product => {
        if (product.id === product_id) {
          return { ...product, price };
        }
        return product;
      });

      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.results = newResults;
        })
      );
    },
    linkSellerToProduct: (product: Product, seller: Seller, responseHandlers?: ResponseHandlers) => {
      linkSellersInProducts({
        product_id: product.id,
        new_sellers: [],
        seller_ids: [seller.seller.id],
      })
        .then(data => {
          const newSellers = data.data.sellers;
          const results = get().properties.results.map(productItem => {
            if (productItem.id !== product.id) return productItem;
            return {
              ...productItem,
              sellers: [...productItem.sellers, ...newSellers],
            };
          });
          set(
            produce<AdvancedFiltersSlice>(draftState => {
              draftState.properties.results = results;
            })
          );
          responseHandlers?.onSuccess?.(data.data);
        })
        .catch(err => {
          responseHandlers?.onError?.(err);
        });
    },
    linkMultipleSellerToProduct: (productId: number, newSellers: Seller[], responseHandlers?: ResponseHandlers) => {
      const processedSellers = newSellers.map(seller => {
        const { data, ...otherProperties } = seller;
        return {
          ...otherProperties,
          ...data,
          seller: {
            ...data,
          },
        };
      });

      const results = get().properties.results.map(productItem => {
        if (productItem.id !== productId) return productItem;
        return {
          ...productItem,
          sellers: [...productItem.sellers, ...processedSellers],
        };
      });

      set(
        produce<AdvancedFiltersSlice>(draftState => {
          draftState.properties.results = results;
        })
      );

      responseHandlers?.onSuccess?.(processedSellers);
    },
  },
}));

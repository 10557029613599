import { SvgIcon, SvgIconProps } from "@mui/material";

export const BrandIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 29 29" {...props}>
        <mask
            id="mask0"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="29"
            height="29"
        >
            <rect x="0.5" y="0.972656" width="28" height="28" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0)">
            <path
                d="M13.5344 26.638C13.2427 26.638 12.951 26.5797 12.6594 26.463C12.3677 26.3464 12.1052 26.1714 11.8719 25.938L3.53021 17.5964C3.29687 17.363 3.12674 17.1054 3.01979 16.8234C2.91285 16.5415 2.85938 16.2547 2.85938 15.963C2.85938 15.6714 2.91285 15.3797 3.01979 15.088C3.12674 14.7964 3.29687 14.5339 3.53021 14.3005L13.7969 4.00469C14.0108 3.7908 14.2635 3.62066 14.5552 3.49427C14.8469 3.36788 15.1483 3.30469 15.4594 3.30469H23.8302C24.4719 3.30469 25.0212 3.53316 25.4781 3.9901C25.9351 4.44705 26.1635 4.99635 26.1635 5.63802V14.0089C26.1635 14.32 26.1052 14.6165 25.9885 14.8984C25.8719 15.1804 25.7066 15.4283 25.4927 15.6422L15.1969 25.938C14.9635 26.1714 14.701 26.3464 14.4094 26.463C14.1177 26.5797 13.826 26.638 13.5344 26.638ZM20.9135 10.3047C21.3997 10.3047 21.8128 10.1345 22.1531 9.79427C22.4934 9.45399 22.6635 9.0408 22.6635 8.55469C22.6635 8.06858 22.4934 7.65538 22.1531 7.3151C21.8128 6.97483 21.3997 6.80469 20.9135 6.80469C20.4274 6.80469 20.0142 6.97483 19.674 7.3151C19.3337 7.65538 19.1635 8.06858 19.1635 8.55469C19.1635 9.0408 19.3337 9.45399 19.674 9.79427C20.0142 10.1345 20.4274 10.3047 20.9135 10.3047Z"
                fill="#0F5D85"
            />
        </g>
    </SvgIcon>
);

export default BrandIcon;

import { productManagerEnv } from '@/config';
import { useProcurementStore } from '@/zustand';
import Description from '@mui/icons-material/Description';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import useScrollObserver from 'src/logic/hooks/use-scroll-observer';
import { getQuoteProductsArray } from 'src/logic/zustand/procurement/seller/seller-slices/createQuoteDetailsSlice';
import { Loading, RadioButtonGroup } from 'src/ui/components/shared';
import DebouncedSearchInput from 'src/ui/components/shared/debounced-search-input';
import { useGetQuotesRelatedToProducts } from './quote-queries';
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';

export type QuoteOptionData = {
  active: boolean;
  id: number;
  name: string;
  sender_project: {
    id: number;
    name: string;
  };
  buyer_details?: {
    id: number;
    name: string;
  };
};

type IProps = {
  onPopupSwitch: () => void;
  onPopupClose: () => void;
};

const AddToExistingQuote = ({ onPopupSwitch, onPopupClose }: IProps) => {
  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const product_id = data.candidateProductToAdd?.id;

  const [searchText, setsearchText] = React.useState<string>('');
  const {
    data: quotesData,
    isLoading,
    isError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetQuotesRelatedToProducts({ product_id, search: searchText });
  const optionsToRender = React.useMemo(() => {
    return quotesData?.pages?.flatMap(page => page.data).map((item: any) => ({ ...item, ...item.quote })) ?? [];
  }, [quotesData]);

  const { observerRef, containerRef } = useScrollObserver({
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage?.();
      }
    },
  });

  const [selectedQuoteId, setSelectedQuoteId] = React.useState<number | ''>('');
  const { push } = useRouter()

  const selectLoading = useLoadingState();
  const { fetchQuoteDetails } = useProcurementStore(state => state.quoteDetailsSliceActions);
  const handleSelectQuote = () => {
    const product = data.candidateProductToAdd;
    if (!product || !product_id || !selectedQuoteId) return;
    push(sm.myCatalogue.index.url)
    selectLoading.startLoading();
    fetchQuoteDetails(selectedQuoteId, {
      onSuccess: quote => {
        actions.setDetails(
          { ...quote, products: getQuoteProductsArray(quote.quote_products) },
          productManagerEnv.quote
        );
        onPopupClose();
        actions.addProduct(product);
      },
      onError: selectLoading.finishLoading,
    });
  };

  return (
    <Box width={'100%'}>
      <Stack sx={{ alignItems: 'center', textAlign: 'center', p: 2, width: '100%' }}>
        <Description sx={{ color: 'primary.main' }} />
        <Typography color='primary' variant='overline' fontWeight='700' mt={1} textAlign={'center'}>
          Add To Quote
        </Typography>
        <Typography color='#3A3E50' fontWeight={400} fontSize={14} mt={1} textAlign={'center'}>
          This item can be added to draft quotes of applicable buyers only. Please select from the list below.
        </Typography>
      </Stack>
      <Box width={'100%'} mb={2}>
        <DebouncedSearchInput onChange={setsearchText} translateKey={'search_quotes'} />
      </Box>
      {isLoading ? (
        <Box minHeight={200} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loading fullHeight={false} />
        </Box>
      ) : isError ? (
        <Box minHeight={200} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Typography fontSize={18} color='red' fontWeight={700}>
            An error occured, please try again
          </Typography>
        </Box>
      ) : (
        <Stack my={1} maxHeight='200px' pl={1} ref={containerRef}>
          <RadioButtonGroup
            data={optionsToRender}
            handleChange={(id: number) => setSelectedQuoteId(id)}
            value={selectedQuoteId}
            getSubTitle={(item: QuoteOptionData) =>
              `${item.buyer_details ? `${item.buyer_details.name}, ` : ''}${item.sender_project.name}`
            }
            isItemDisabled={(item: QuoteOptionData) => !item.active}
          />
          {hasNextPage && (
            <div
              style={{ marginBlock: '.5rem', opacity: isFetchingNextPage ? 1 : 0, transition: 'opacity 200ms' }}
              ref={observerRef}
            >
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '.5rem' }}>
                <CircularProgress size={'1rem'} />
                <Typography color='#8B8D98' fontWeight={400} fontSize={13}>
                  Loading Quotes...
                </Typography>
              </div>
            </div>
          )}
        </Stack>
      )}

      <LoadingButton
        loading={selectLoading.isLoading}
        sx={{ mt: 2 }}
        type='button'
        fullWidth
        variant='contained'
        disabled={!selectedQuoteId}
        onClick={handleSelectQuote}
      >
        Select Quote
      </LoadingButton>
      <Button
        type='button'
        color='primary'
        variant='text'
        fullWidth
        sx={{ mt: 1, fontWeight: 500 }}
        onClick={onPopupSwitch}
        disabled={selectLoading.isLoading}
      >
        Or Create A New Quote
      </Button>
    </Box>
  );
};

export default AddToExistingQuote;

import { ErrorType } from '@/helpers';
import {
  FilterKind,
  PaginationResult,
  Table,
  TableFilterItem,
  TableFilters,
  TableMapperType,
  TableSelectedFilters,
} from '@/models';
import { getTableIndex, getTableMapper, useApplyTableFilters } from '@/services';
import produce from 'immer';
import { getLocationsForCreateBuyer, getLocationsForCreateSeller } from 'src/logic/services/catalogue/private-seller';
import QueryHandler, { QueryHandlerModel } from 'src/logic/services/query-handlers/QueryHandler';
import { buildTableModel } from 'src/logic/services/tables';
import { StateCreator } from 'zustand';

export interface TableSlice {
  tableSliceProperties: {
    data: {
      isLoading: boolean;
      isError: boolean;
      errorMessage: string | null | undefined;
      tableType: TableMapperType | null;
      tableFilters: TableFilters[];
      tableData: Table | null;
      requiresMyAction: boolean;
      searchText: string | null;
      tablePaginationResult: PaginationResult | null;
      selectedPageNumber: number;
      selectedFiltersNumber: 0;
      selectedFilters: TableSelectedFilters[] | null;
      isFilteredDataLoading: boolean;
      isFilteredDataError: boolean;
      filteredDataErrorMessage: string | null | undefined;
      params: any;
      requestID: number;
      createSellerData: null;
      createBuyerData: null;
      fetchQueryStatus: QueryHandlerModel;
    };
    actions: {
      loadData: (tableType: TableMapperType, params?: undefined | any, initialFilters?: undefined | any | null) => void;
      setRequiresMyAction: (checked: boolean) => void;
      setSearchText: (text: string | null) => void;
      changePageNumber: (pageNumber: number) => void;
      onFilterChanged: (type: string, kind: string, value: any, onUpdate?: (newFilters: any) => void) => void;
      updateFilters: (type?: string, kind?: string, value?: any, onUpdate?: (newFilters: any) => void) => void;
      applyFilters: () => void;
      clearFilters: (tableType: TableMapperType) => void;
      clearFilterByKind: (kind: FilterKind) => void;
      getLocationsForCreateSeller: () => void;
      getLocationsForCreateBuyer: () => void;
      setFetchQueryStatus: (queryHandler: QueryHandlerModel) => void;
    };
  };
}

export const createTableSlice: StateCreator<TableSlice> = (set, get, api) => ({
  tableSliceProperties: {
    data: {
      isLoading: false,
      tableType: null,
      tableFilters: [],
      tableData: null,
      requiresMyAction: false,
      searchText: '',
      tablePaginationResult: null,
      selectedFiltersNumber: 0,
      selectedPageNumber: 1,
      selectedFilters: [],
      isFilteredDataLoading: false,
      isFilteredDataError: false,
      filteredDataErrorMessage: null,
      params: undefined,
      requestID: 0,
      createSellerData: null,
      createBuyerData: null,
      fetchQueryStatus: QueryHandler.getInitialStatus(),
    },
    actions: {
      loadData: (tableType: TableMapperType, params: undefined | any, initialFilters: undefined | any | null) => {
        const currentRequestID = Date.now(); // Generate a unique request ID
        set(
          produce(draftState => {
            draftState.tableSliceProperties.data.requestID = currentRequestID;
            draftState.tableSliceProperties.data.params = params;
            draftState.tableSliceProperties.data.isLoading = true;
            draftState.tableSliceProperties.data.isError = false;
            draftState.tableSliceProperties.data.errorMessage = null;
            draftState.tableSliceProperties.data.tableType = tableType;
            draftState.tableSliceProperties.data.tableData = null;
          })
        );

        getTableIndex(tableType, params)
          .then(data => {
            // Check if the stored request ID matches the current request ID
            if (currentRequestID !== get().tableSliceProperties.data.requestID) {
              return;
            }
            
            let isGrouping = null;
            let showSingleChildAsMainRow = true;
            if (
              [
                TableMapperType.request_for_quotes_buyer,
                TableMapperType.project_tables_request_for_quotes_buyer,
                TableMapperType.seller_company_request_for_quotes,
              ].includes(tableType)
            ) {
              isGrouping = 'request_for_quotes';
            }
            if (
              [
                TableMapperType.buyer_purchase_order,
                TableMapperType.project_tables_buyer_purchase_order,
                TableMapperType.seller_company_purchase_order,
              ].includes(tableType)
            ) {
              isGrouping = 'purchaseOrders';
            }
            if (
              [
                TableMapperType.seller_buyers,
                TableMapperType.buyer_sellers
              ].includes(tableType)
            ) {
              isGrouping = 'users';
              showSingleChildAsMainRow = false;
            }

            let paginationResult = data.data.data;
            const tableModel = buildTableModel({
              jsonData: data.data.data.data,
              columnMapper: getTableMapper(get().tableSliceProperties.data.tableType),
              rowChildrenAccessorKey: isGrouping,
              showSingleChildAsMainRow,
              isTheTableRequisition: tableType === TableMapperType.requisitions,
            });

            set(
              produce(draftState => {
                draftState.tableSliceProperties.data.isFilteredDataLoading = false;
                draftState.tableSliceProperties.data.isFilteredDataError = false;
                draftState.tableSliceProperties.data.requiresMyAction = false;
                draftState.tableSliceProperties.data.tableData = tableModel;
                draftState.tableSliceProperties.data.tableFilters = buildTableFilters(
                  data.data.filters,
                  initialFilters?.filters
                );
                draftState.tableSliceProperties.data.selectedFilters = [];
                draftState.tableSliceProperties.data.searchText = '';
                draftState.tableSliceProperties.data.tablePaginationResult = {
                  total: paginationResult.total,
                  last_page: paginationResult.last_page,
                  current_page: paginationResult.current_page,
                };
                draftState.tableSliceProperties.data.selectedPageNumber = 1;
              })
            );

            if (initialFilters != undefined) {
              set(
                produce(draftState => {
                  draftState.tableSliceProperties.data.selectedFilters = initialFilters?.filters ?? [];
                  // draftState.tableSliceProperties.data.selectedFilters = buildSelectedFilters(initialFilters?.filters) ?? [];
                  draftState.tableSliceProperties.data.selectedPageNumber = initialFilters?.page_number ?? 1;
                  draftState.tableSliceProperties.data.requiresMyAction = initialFilters?.requires_user_action ?? false;
                  draftState.tableSliceProperties.data.searchText = initialFilters?.search_text ?? '';
                })
              );
              get().tableSliceProperties.actions.applyFilters();
            } else {
              set(
                produce(draftState => {
                  draftState.tableSliceProperties.data.isLoading = false;
                })
              );
            }

            set(
              produce(draftState => {
                draftState.tableSliceProperties.data.selectedFiltersNumber = getTheNumberOfSelectedFilters(
                  initialFilters?.filters ?? [],
                  initialFilters?.requires_user_action ?? get().tableSliceProperties.data.requiresMyAction
                );
              })
            );
          })
          .catch((err: ErrorType) => {
            set(
              produce(draftState => {
                draftState.tableSliceProperties.data.isLoading = false;
                draftState.tableSliceProperties.data.isError = true;
                draftState.tableSliceProperties.data.errorMessage = err.response?.data?.message;
              })
            );
          });
      },
      setRequiresMyAction: (checked: boolean) => {
        set(
          produce(draftState => {
            draftState.tableSliceProperties.data.requiresMyAction = checked;
          })
        );
        get().tableSliceProperties.actions.updateFilters();
      },
      setSearchText: (text: string | null) => {
        set(
          produce(draftState => {
            draftState.tableSliceProperties.data.searchText = text;
          })
        );
        get().tableSliceProperties.actions.updateFilters();
      },
      changePageNumber: (pageNumber: number) => {
        set(
          produce(draftState => {
            draftState.tableSliceProperties.data.selectedPageNumber = pageNumber;
          })
        );
        get().tableSliceProperties.actions.updateFilters();
      },
      onFilterChanged: (type: string, kind: string, value: any, onUpdate?: (newFilters: any) => void) => {
        get().tableSliceProperties.actions.updateFilters(type, kind, value, onUpdate);
      },
      updateFilters: (type?: string, kind?: string, value?: any, onUpdate?: (newFilters: any) => void) => {
        set(
          produce(draftState => {
            draftState.tableSliceProperties.data.tableFilters = updateFilterItem(
              type,
              kind,
              value,
              draftState.tableSliceProperties.data.tableFilters
            );
          })
        );
        set(
          produce(draftState => {
            draftState.tableSliceProperties.data.selectedFilters = buildSelectedFilters(
              get().tableSliceProperties.data.tableFilters
            );
            draftState.tableSliceProperties.data.selectedFiltersNumber = getTheNumberOfSelectedFilters(
              draftState.tableSliceProperties.data.selectedFilters,
              get().tableSliceProperties.data.requiresMyAction
            );
          })
        );
        onUpdate?.(get().tableSliceProperties.data.selectedFilters);
        get().tableSliceProperties.actions.applyFilters();
      },
      applyFilters: async () => {
        const currentRequestID = Date.now(); // Generate a unique request ID
        set(
          produce(draftState => {
            (draftState.tableSliceProperties.data.requestID = currentRequestID),
              (draftState.tableSliceProperties.data.isFilteredDataLoading = true),
              (draftState.tableSliceProperties.data.isFilteredDataError = false);
          })
        );
        useApplyTableFilters(
          get().tableSliceProperties.data.tableType,
          get().tableSliceProperties.data.selectedPageNumber,
          get().tableSliceProperties.data.searchText,
          get().tableSliceProperties.data.requiresMyAction,
          get().tableSliceProperties.data.selectedFilters,
          get().tableSliceProperties.data.params
        )
          .then(data => {
            // Check if the stored request ID matches the current request ID
            if (currentRequestID !== get().tableSliceProperties.data.requestID) {
              return;
            }

            let paginationResult = data.data.data;
            const tableType = get().tableSliceProperties.data.tableType;
            let isGrouping = null;
            let showSingleChildAsMainRow = true;
            if (
              [
                TableMapperType.request_for_quotes_buyer,
                TableMapperType.project_tables_request_for_quotes_buyer,
                TableMapperType.seller_company_request_for_quotes,
              ].includes(tableType)
            ) {
              isGrouping = 'request_for_quotes';
            }
            if (
              [
                TableMapperType.buyer_purchase_order,
                TableMapperType.project_tables_buyer_purchase_order,
                TableMapperType.seller_company_purchase_order,
              ].includes(tableType)
            ) {
              isGrouping = 'purchaseOrders';
            }
            if (
              [
                TableMapperType.seller_buyers,
                TableMapperType.buyer_sellers
              ].includes(tableType)
            ) {
              isGrouping = 'users';
              showSingleChildAsMainRow = false;
            }

            const tableModel = buildTableModel({
              jsonData: data.data.data.data,
              columnMapper: getTableMapper(get().tableSliceProperties.data.tableType),
              rowChildrenAccessorKey: isGrouping,
              showSingleChildAsMainRow,
              isTheTableRequisition: tableType === TableMapperType.requisitions,
            });

            set(
              produce(draftState => {
                draftState.tableSliceProperties.data.isLoading = false;
                draftState.tableSliceProperties.data.isFilteredDataLoading = false;
                draftState.tableSliceProperties.data.tableData = tableModel;
                draftState.tableSliceProperties.data.tablePaginationResult = {
                  total: paginationResult.total,
                  last_page: paginationResult.last_page,
                  current_page: paginationResult.current_page,
                };
                draftState.tableSliceProperties.data.selectedPageNumber = 1;
              })
            );
          })
          .catch((err: ErrorType) => {
            set(
              produce(draftState => {
                draftState.tableSliceProperties.data.isFilteredDataLoading = false;
                draftState.tableSliceProperties.data.isFilteredDataError = true;
                draftState.tableSliceProperties.data.filteredDataErrorMessage = err.response?.data?.message;
              })
            );
          });
      },
      clearFilters: (tableType: TableMapperType) => {
        get().tableSliceProperties.actions.loadData(tableType);
      },
      clearFilterByKind: (kind: FilterKind) => {
        const selectedFilters = get().tableSliceProperties?.data?.selectedFilters;

        set(
          produce(draftState => {
            draftState.tableSliceProperties.data.selectedFilters = selectedFilters?.filter(
              (item: any) => item.kind != kind
            );
          })
        );
        get().tableSliceProperties.actions.applyFilters();
      },
      getLocationsForCreateSeller: () => {
        get().tableSliceProperties.actions.setFetchQueryStatus(QueryHandler.getStartStatus());

        getLocationsForCreateSeller()
          .then(data => {
            set(
              produce<TableSlice>(draftState => {
                draftState.tableSliceProperties.data.createSellerData = data.data.locations;
              })
            );
            get().tableSliceProperties.actions.setFetchQueryStatus(QueryHandler.getSuccessStatus());
          })
          .catch(err => {
            get().tableSliceProperties.actions.setFetchQueryStatus(QueryHandler.getErrorStatus(err));
          });
      },
      getLocationsForCreateBuyer: () => {
        get().tableSliceProperties.actions.setFetchQueryStatus(QueryHandler.getStartStatus());

        getLocationsForCreateBuyer()
          .then(data => {
            set(
              produce<TableSlice>(draftState => {
                draftState.tableSliceProperties.data.createBuyerData = data.data.locations;
              })
            );
            get().tableSliceProperties.actions.setFetchQueryStatus(QueryHandler.getSuccessStatus());
          })
          .catch(err => {
            get().tableSliceProperties.actions.setFetchQueryStatus(QueryHandler.getErrorStatus(err));
          });
      },
      setFetchQueryStatus: (queryHandler: QueryHandlerModel) => {
        set(
          produce<TableSlice>(draftState => {
            draftState.tableSliceProperties.data.fetchQueryStatus = queryHandler;
          })
        );
      },
    },
  },
});

export const buildTableFilters = (filtersData: TableSelectedFilters[], initialFilters?: TableSelectedFilters[]) => {
  return filtersData.map(element => {
    let targetInitialFilter: TableSelectedFilters | undefined;
    if (initialFilters !== undefined) {
      targetInitialFilter = initialFilters.find(
        initialItem => initialItem.kind === element.kind && initialItem.type === element.type
      );
    }

    if (element.kind === FilterKind.RANGE) {
      return {
        ...element,
        initialValue: element.value,
      };
    }

    if (
      [FilterKind.MULTI_SELECT, FilterKind.MULTI_SELECT_STATUS].includes(element.kind) &&
      targetInitialFilter !== undefined
    ) {
      return {
        ...element,
        value: element.value?.map(elementValue => {
          if (targetInitialFilter?.array_value?.some(num => num === elementValue.id)) {
            // is found in initial filters
            return { ...elementValue, checked: true };
          }
          return elementValue;
        }),
      };
    }

    // if(element.kind === FilterKind.DATE && targetInitialFilter !== undefined) {
    //     return {
    //         ...element,
    //         date_value: targetInitialFilter?.date_value
    //     }
    // }

    return element;
  });
};

export const updateFilterItem = (type?: string, kind?: string, value?: any, filtersData?: TableSelectedFilters[]) => {
  const copyFiltersData = filtersData?.slice();

  const updatedFilters = copyFiltersData?.map((filterElement: TableSelectedFilters) => {
    if ((kind == FilterKind.MULTI_SELECT || kind == FilterKind.MULTI_SELECT_STATUS) && filterElement.type == type) {
      const filterValue = filterElement.value;
      if (Array.isArray(filterValue)) {
        const filterItem = filterValue?.find(element => element.id == value);
        if (filterItem) {
          const updatedFilterItems = filterElement.value?.map((filterItemElement: TableFilterItem) => {
            if (filterItemElement.id === filterItem.id) {
              return { ...filterItemElement, checked: !filterItemElement.checked };
            }
            return filterItemElement;
          });

          const updatedFilterElement = { ...filterElement, value: updatedFilterItems };
          return updatedFilterElement;
        } else return filterElement;
      }
    } else if (kind === FilterKind.DATE && filterElement.type == type) {
      const updatedItem = { ...filterElement, value: value };
      return updatedItem;
    } else if (kind === FilterKind.RANGE && filterElement.type == type) {
      const updatedItem = { ...filterElement, value: value };
      return updatedItem;
    }

    return filterElement;
  });
  return updatedFilters;
};

export const buildSelectedFilters = (filtersData: TableSelectedFilters[]) => {
  const checkedItems: any[] = [];

  filtersData.forEach((filterElement: TableSelectedFilters) => {
    const filterValue = filterElement.value;
    if (filterElement.kind == FilterKind.MULTI_SELECT || filterElement.kind == FilterKind.MULTI_SELECT_STATUS) {
      const selectedItems = filterValue.filter((filterItemElement: TableFilterItem) => {
        return filterItemElement.checked;
      });

      if (selectedItems.length > 0) {
        const ids = selectedItems?.map(item => {
          return item.id;
        });
        checkedItems.push({ kind: filterElement.kind, type: filterElement.type, array_value: ids });
      }
    } else if (filterElement.kind == FilterKind.DATE) {
      if (filterValue && filterValue != null) {
        checkedItems.push({ kind: filterElement.kind, type: filterElement.type, date_value: filterValue });
      }
    } else if (filterElement.kind == FilterKind.RANGE) {
      if (filterValue && filterValue != null) {
        const currentMin = filterValue?.min,
          initialMin = filterElement?.initialValue?.min,
          currentMax = filterValue?.max,
          initialMax = filterElement?.initialValue?.max;
        if (!(currentMin == initialMin && currentMax == initialMax)) {
          checkedItems.push({ kind: filterElement.kind, type: filterElement.type, value: filterValue });
        }
      }
      // if (filterValue && filterValue != null) { checkedItems.push({ kind: filterElement.kind, type: filterElement.type, value: filterValue }) }
    }
  });
  return checkedItems;
};

export const getTheNumberOfSelectedFilters = (
  filtersData: TableSelectedFilters[] | null,
  requiresMyAction: boolean
): number => {
  let num: number = 0;

  filtersData?.forEach((filterElement: TableSelectedFilters) => {
    if (filterElement.kind == FilterKind.MULTI_SELECT || filterElement.kind == FilterKind.MULTI_SELECT_STATUS) {
      num += filterElement.array_value.length;
    } else if (filterElement.kind == FilterKind.DATE) {
      num++;
    }
  });
  if (requiresMyAction) {
    num++;
  }
  return num;
};

import { productManagerEnv } from '@/config';
import { useProcurementStore, useSellerProcurementStore } from '@/zustand';
import Description from '@mui/icons-material/Description';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import useScrollObserver from 'src/logic/hooks/use-scroll-observer';
import { Loading, RadioButtonGroup } from 'src/ui/components/shared';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import DebouncedSearchInput from 'src/ui/components/shared/debounced-search-input';
import { useGetCompaniesRelatedToProducts } from './quote-queries';
import { sm } from 'src/site-map';
import { useRouter } from 'next/router';

export type BuyerCompany = {
  id: number;
  name: string;
  phone_number: string;
  email: string;
  contact_full_name: string;
  is_private: boolean;
};

type IProps = {
  onPopupSwitch: () => void;
  onPopupClose: () => void;
};

const CreateQuoteWithProduct = ({ onPopupSwitch, onPopupClose }: IProps) => {
  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const product_id = data.candidateProductToAdd?.id;

  const [searchText, setsearchText] = React.useState<string>('');
  const {
    data: buyersData,
    isLoading,
    isError,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetCompaniesRelatedToProducts({ product_id, search: searchText });
  const companiesToRender = React.useMemo(() => {
    return buyersData?.pages?.flatMap(page => page.data) ?? [];
  }, [buyersData]);

  const { observerRef, containerRef } = useScrollObserver({
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage?.();
      }
    },
  });

  const [selectedBuyerId, setSelectedBuyerId] = React.useState<number | ''>('');

  const createLoading = useLoadingState();
  const { create: createQuote } = useSellerProcurementStore(state => state.sellerQuotesTableSliceProperties.actions);
  const { push } = useRouter()

  const handleCreateQuote = () => {
    const product = data.candidateProductToAdd;
    if (!product || !product_id || !selectedBuyerId) return;
    push(sm.myCatalogue.index.url)

    createLoading.startLoading();
    createQuote({ buyerId: selectedBuyerId, projectId: '' }, (id, quote) => {
      actions.setDetails({ ...quote, products: [] }, productManagerEnv.quote);
      onPopupClose();
      actions.addProduct(product);
    });
  };

  return (
    <Box width={'100%'}>
      <Stack sx={{ alignItems: 'center', textAlign: 'center', p: 2, width: '100%' }}>
        <Description sx={{ color: 'primary.main' }} />
        <Typography color='primary' variant='overline' fontWeight='700' mt={1} textAlign={'center'}>
          Add To New Quote
        </Typography>
        <Typography color='#3A3E50' fontWeight={400} fontSize={14} mt={1} textAlign={'center'}>
          Please Select a Buyer
        </Typography>
      </Stack>
      <Box width={'100%'} mb={2}>
        <DebouncedSearchInput onChange={setsearchText} translateKey={'search_buyers'} />
      </Box>
      {isLoading ? (
        <Box minHeight={200} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loading fullHeight={false} />
        </Box>
      ) : isError ? (
        <Box minHeight={200} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Typography fontSize={18} color='red' fontWeight={700}>
            An error occured, please try again
          </Typography>
        </Box>
      ) : (
        <Stack my={1} pl={1} maxHeight='200px' ref={containerRef}>
          <RadioButtonGroup
            data={companiesToRender}
            handleChange={(id: number) => setSelectedBuyerId(id)}
            value={selectedBuyerId}
            getSubTitle={() => ''}
            getLabelSuffix={(buyer: any) => {
              return buyer.is_private ? <PrivacyButton disableText zIndex={1300} privacyLevel='COMPANY' /> : null;
            }}
          />
          {hasNextPage && (
            <div
              style={{ marginBlock: '.5rem', opacity: isFetchingNextPage ? 1 : 0, transition: 'opacity 200ms' }}
              ref={observerRef}
            >
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '.5rem' }}>
                <CircularProgress size={'1rem'} />
                <Typography color='#8B8D98' fontWeight={400} fontSize={13}>
                  Loading Companies...
                </Typography>
              </div>
            </div>
          )}
        </Stack>
      )}
      <LoadingButton
        loading={createLoading.isLoading}
        sx={{ mt: 2 }}
        type='button'
        fullWidth
        variant='contained'
        disabled={!selectedBuyerId}
        onClick={handleCreateQuote}
      >
        Create New Quote
      </LoadingButton>
      <Button
        type='button'
        color='primary'
        variant='text'
        fullWidth
        sx={{ mt: 1, fontWeight: 500 }}
        onClick={onPopupSwitch}
        disabled={createLoading.isLoading}
      >
        Or Add To Existing Quote
      </Button>
    </Box>
  );
};

export default CreateQuoteWithProduct;

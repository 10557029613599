import { SvgIcon, SvgIconProps } from '@mui/material';

export const BigChatErrorIcon: React.FC<SvgIconProps> = props => (
    <SvgIcon {...props} viewBox="0 0 28 28">
        <path
            d="M10.7807 16.7324L14.2474 13.2658L17.7141 16.7324L19.5807 14.8658L16.1141 11.3991L19.5807 7.93242L17.7141 6.06575L14.2474 9.53242L10.7807 6.06575L8.91406 7.93242L12.3807 11.3991L8.91406 14.8658L10.7807 16.7324ZM0.914062 27.3991V3.39909C0.914062 2.66576 1.17517 2.03798 1.6974 1.51576C2.21962 0.993533 2.8474 0.732422 3.58073 0.732422H24.9141C25.6474 0.732422 26.2752 0.993533 26.7974 1.51576C27.3196 2.03798 27.5807 2.66576 27.5807 3.39909V19.3991C27.5807 20.1324 27.3196 20.7602 26.7974 21.2824C26.2752 21.8046 25.6474 22.0658 24.9141 22.0658H6.2474L0.914062 27.3991ZM5.11406 19.3991H24.9141V3.39909H3.58073V20.8991L5.11406 19.3991Z"
            fill="#FF4D4D"
        />
    </SvgIcon>
);

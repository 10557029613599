import { AuthenticateStatus, ProductInCartType } from "@/config";
import { Product, Requisition, Seller } from "@/models";
import { NotifyError, useProductDetails } from "@/services";
import produce from "immer";
import { StateCreator } from "zustand";
import { useUserData } from "../../user";
import { EnvironmentFilters } from "src/logic/models/catalogue/AdvancedFilters";
import { getUnlinkSeller, unlinkCatalogueItemService } from "src/logic/services/catalogue/private-seller";
import { ResponseHandlers } from "src/logic/models/queries";

export interface ProductDetailsSlice {
    productDetailsMainProduct: Product | null,
    productDetailsAlternatives: Product[],
    productSellers: Seller[],
    canPerformActionProductDetails: boolean
    canPerformActionProductDetailsPO: boolean
    canPerformActionProductDetailsQuote: boolean
    copyOfFormDetails: Requisition | null
    loadProductDetails: (productId: number, params?: EnvironmentFilters) => void;
    changeProductDetailsMainProductQuantity: (quantity: number, sellerId: string) => void
    changeProductDetailsMainProductRequirements: (additional_requirements: string) => void
    changeProductDetailsMainProductPrice: (quantity: number, sellerId?: string) => void
    changeSimilarProductQuantity: (quantity: number, product: Product) => void
    linkOrCreateSeller: (sellers: Seller[]) => void
    unlinkSeller: (sellerId: string, productId: number) => void
    loadingDetails: boolean
    updateFieldsAfterLinkToMyCatalogue: (updatedProduct: Product) => void;
    unlinkProduct: (catalogueItemId: number, responseHandlers?: ResponseHandlers) => void
}

export const createProductDetailsSlice: StateCreator<ProductDetailsSlice> = (set, get, api) => ({
    loadingDetails: false,
    copyOfFormDetails: null,
    productDetailsMainProduct: null,
    productDetailsAlternatives: [],
    productSellers: [],
    canPerformActionProductDetails: false,
    canPerformActionProductDetailsPO: false,
    canPerformActionProductDetailsQuote: false,
    changeProductDetailsMainProductQuantity: (quantity: number, sellerId: string) => {
        if (quantity > 0) {
            if (!sellerId) set(produce(draftState => { draftState.productDetailsMainProduct.quantity = quantity }))
            if (sellerId) set(produce(draftState => { draftState.productSellers = get().productSellers.map(seller => { return seller.seller.id == sellerId ? { ...seller, quantity: quantity } : { ...seller } }) }))
        }
        else if (get().productDetailsMainProduct?.inCart)
            get().productFormManagerProperties.actions.changeQuantity(get().productDetailsMainProduct, quantity)
    },
    changeProductDetailsMainProductRequirements: (additional_requirements: string) => {
        set(produce(draftState => { draftState.productDetailsMainProduct.additional_requirements = additional_requirements }))
        if (get().productDetailsMainProduct?.inCart) {
            get().productFormManagerProperties.actions.changeRequirements(get().productDetailsMainProduct, additional_requirements)
        }
    },
    changeSimilarProductQuantity: (quantity: number, product: Product) => {
        product = produce(product, (draftProduct) => { draftProduct.quantity = quantity })
        set(produce(draftState => {
            draftState.productDetailsAlternatives = draftState.productDetailsAlternatives.map((alternativeProduct: Product) => {
                if (alternativeProduct.id == product.id) return product
                else return alternativeProduct
            })
        }))
        if (product.inCart)
            if (product.inCartType == ProductInCartType.PO) get().productFormManagerProperties.actions.changeQuantity(product, quantity)
        // else get().updateRequisitionProductQuantity(product, quantity)
    },
    changeProductDetailsMainProductPrice: (price: number | string, sellerId?: string) => {
        if (price === "" || Number(price) >= 0) {
            set(produce(draftState => {
                draftState.productDetailsMainProduct.price = price === "" ? "" : Number(price);
            }));

            if (sellerId) {
                set(produce(draftState => {
                    draftState.productSellers = get().productSellers.map(seller =>
                        seller.seller.id == sellerId ? { ...seller, price: price === "" ? "" : Number(price) } : { ...seller }
                    );
                }));
            }
        }

        if (get().productDetailsMainProduct?.inCart && get().productDetailsMainProduct?.inCartType === ProductInCartType.PO) {
            get().productFormManagerProperties.actions.changePrice(
                get().productDetailsMainProduct,
                price === "" ? 0 : Number(price)
            );
        }
    },
    linkOrCreateSeller: (sellers: Seller[]) => {
        set(
            produce(draftState => {
                sellers.forEach(item => {
                    const newItem = {
                        ...item,
                        unit_price: item.price ?? '',
                        quantity: 1
                    };
                    draftState.productSellers.push(newItem);
                });
            })
        );
    },
    unlinkSeller: (sellerId: string, productId: number) => {
        getUnlinkSeller(sellerId, productId).then((data) => {
            if (!data.data.success) {
                throw new Error('Unlinking seller failed');
            }
            set(
                produce(draftState => {
                    const index = draftState.productSellers.findIndex((seller: Seller) => seller.seller.id === sellerId);
                    if (index !== -1) {
                        draftState.productSellers.splice(index, 1);
                    }
                })
            );
        }).catch((error) => {
            NotifyError('Something went wrong!')
        });
    },
    unlinkProduct: (catalogueItemId: number, responseHandlers?: ResponseHandlers) => {
        unlinkCatalogueItemService(catalogueItemId)
            .then((data) => {
                set(produce(draftState => {
                    draftState.productDetailsMainProduct = { ...data.data };
                }));
                responseHandlers?.onSuccess?.(data.data);
            })
            .catch((err) => {
                responseHandlers?.onError?.(err);
            });
    },
    updateFieldsAfterLinkToMyCatalogue: (product: Product) => {
        const { catalog_item, catalog_price, edit_type, part_of_seller_catalog, seller_product_status, status } = product;
        const currentProduct = get().productDetailsMainProduct;
        const updatedProduct = { ...currentProduct, catalog_item, catalog_price, edit_type, part_of_seller_catalog, seller_product_status, status };
        set(produce<ProductDetailsSlice>(draftState => {
            draftState.productDetailsMainProduct = updatedProduct;
        }))
    },
    loadProductDetails: (productId: number, params: EnvironmentFilters = {}) => {
        set(produce(draftState => { draftState.loadingDetails = true }))
        useProductDetails(productId, params).then(data => {
            const productSliceData = get().productFormManagerProperties.data
            const { isSeller, isAuthenticated } = useUserData.getState()
            const productSliceActions = get().productFormManagerProperties.actions
            set(produce(draftState => {
                draftState.loadingDetails = false
                draftState.canPerformActionProductDetails = !isSeller() || isAuthenticated === AuthenticateStatus.NOT_AUTHENTICATED
                draftState.canPerformActionProductDetailsPO = !isSeller() && isAuthenticated === AuthenticateStatus.AUTHENTICATED
                draftState.canPerformActionProductDetailsQuote = isSeller() && isAuthenticated === AuthenticateStatus.AUTHENTICATED
                draftState.productSellers = data.data.product.sellers.map(seller => { return { ...seller, unit_price: seller.price, quantity: 1 } })
                draftState.productDetailsMainProduct = productSliceData.environment ? productSliceActions.transformProduct(data.data.product, productSliceData.formDetails)
                    : get().buildInitialProductState(data.data.product)
                draftState.productDetailsAlternatives = data.data.alternatives.map((alternativeProduct: Product) => {
                    return get().buildInitialProductAlternativeState(alternativeProduct, data.data.product.id)
                })
                draftState.copyOfFormDetails = null //!important - we need to reset it to null so that it rebuilds if there is any current form
            }))
        })
        api.subscribe(
            state => {
                const productSliceData = get().productFormManagerProperties.data
                const formDetails = state.productFormManagerProperties.data.formDetails
                const productSliceActions = get().productFormManagerProperties.actions
                if (formDetails)
                    if (JSON.stringify(formDetails) != JSON.stringify(get().copyOfFormDetails))
                        set(produce(draftState => {
                            draftState.productDetailsMainProduct =
                                productSliceData.environment ? productSliceActions.transformProduct(get().productDetailsMainProduct, formDetails)
                                    : productSliceActions.buildInitialProductState(get().productDetailsMainProduct)
                            draftState.productDetailsAlternatives = get().productDetailsAlternatives.map((element: Product) =>
                                productSliceData.environment ? productSliceActions.transformProduct(element, formDetails, true)
                                    : get().buildInitialProductAlternativeState(element, get().productDetailsMainProduct?.id)
                            )
                            draftState.copyOfFormDetails = formDetails
                        }))

            },
        )
    },
})

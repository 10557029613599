import { Seller } from '@/models';
import AddIcon from '@mui/icons-material/Add';
import BadgeIcon from '@mui/icons-material/Badge';
import { Button, Grid, Typography } from '@mui/material';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { LinkOrCreateSellerProps } from 'src/logic/models/catalogue/LinkOrCreateSellerProps';
import { linkSellersInRequisition, linkSellersInProducts } from 'src/logic/services/catalogue/private-seller';
import { QueryHandlerModel } from 'src/logic/services/query-handlers/QueryHandler';
import QueryStatusComponent from 'src/logic/services/query-handlers/QueryStatusComponent';
import { DescPopup, StyledLoadingButton } from 'src/ui/components/shared';
import AddSellerDropDown from '../../../catalogue/external-item-form/tabs/sellers/AddSellerDropDown';
import { SellerItem } from './seller-item';
import {
  initialValuesForLinkOrCreateSeller,
  validationSchemaForLinkOrCreateSeller,
} from './link-or-create-seller-form-utils';
import { NotifyError, NotifySuccess } from '@/services';
import { ERROR_CODE } from 'src/logic/services/error-codes';

type ServiceType = 'publicProduct' | 'requisition';
type LinkOrCreateSellersPopupProps = {
  service: ServiceType;
  open: boolean;
  LoadingFetchStatus: QueryHandlerModel;
  productId: number;
  onSuccess: (sellers: Seller[]) => void;
  existingSellers: Seller[] | null;
  closePopup: () => void;
};

type ServiceFunction = (data: any, options?: any) => Promise<any>;

const services: Record<ServiceType, ServiceFunction> = {
  publicProduct: linkSellersInProducts,
  requisition: linkSellersInRequisition,
};

export const LinkOrCreateSellersPopup: React.FC<LinkOrCreateSellersPopupProps> = ({
  service,
  open,
  LoadingFetchStatus,
  productId,
  onSuccess,
  existingSellers,
  closePopup,
}) => {
  const [selectedSellers, setSelectedSellers] = React.useState<number[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [linkLoading, setLinkLoading] = React.useState(false);

  const startLoading = React.useCallback(() => setLinkLoading(true), []);
  const finishLoading = React.useCallback(() => setLinkLoading(false), []);
  const handleClosePopup = () => {
    closePopup();
    setSelectedSellers([]);
  };

  const filteredSellersOptions = React.useMemo(() => {
    const options = existingSellers?.map(item => ({ ...item, ...item.seller })) ?? [];
    if (!searchValue) return options;
    const searchTerm = searchValue.toLocaleLowerCase();
    return options?.filter(option => option.name.toLocaleLowerCase().includes(searchTerm));
  }, [existingSellers, searchValue]);

  const handleCheckSeller = React.useCallback((sellerId: number) => {
    setSelectedSellers(prev => {
      if (prev.includes(sellerId)) return prev;
      return [...prev, sellerId];
    });
  }, []);

  const handleUncheckSeller = React.useCallback((sellerId: number) => {
    setSelectedSellers(prev => prev.filter(id => id !== sellerId));
  }, []);

  const dropdownTitle = React.useMemo(() => {
    if (selectedSellers.length === 0) return 'Select Sellers';
    if (selectedSellers.length === 1)
      return existingSellers?.find(seller => seller.seller.id === selectedSellers[0])?.seller.name ?? '';
    return `${selectedSellers.length} Sellers Selected`;
  }, [selectedSellers, existingSellers]);

  const handleSubmit = async (
    values: LinkOrCreateSellerProps,
    { setFieldError, setFieldValue, setFieldTouched }: FormikHelpers<LinkOrCreateSellerProps>
  ) => {
    const finalValues = {
      product_id: productId,
      new_sellers: values.new_sellers,
      seller_ids: selectedSellers,
    };

    startLoading();
    services[service](finalValues)
      .then(data => {
        if (data.data.errorMessages.length == 0) {
          NotifySuccess('Linked Successfully');
          handleClosePopup();
        } else {
          setSelectedSellers([]);
          NotifyError(`${data.data.errorMessages.length} New Private Sellers added already exist.`);
          const updatedSellers = values.new_sellers.filter(seller => {
            return data.data.errorMessages.some(
              (item: { key: number; code: string; index: number; identifier: number }) =>
                item.identifier === seller.identifier
            );
          });

          setFieldValue('new_sellers', updatedSellers);
          const errorCodeToField = {
            [ERROR_CODE.PRIVATE_SELLER_EXISTS_WITH_SAME_NAME]: 'company_name',
            [ERROR_CODE.PRIVATE_SELLER_EXISTS_WITH_USER_EMAIL]: 'email',
            [ERROR_CODE.PRIVATE_SELLER_EXISTS_WITH_USER_PHONE]: 'phone_number',
          };
          data.data.errorMessages.forEach(
            (errorItem: { key: string; code: string; index: number; identifier: number }) => {
              const index = updatedSellers.findIndex(seller => seller.identifier === errorItem.identifier);
              if (index !== -1) {
                const fieldName = errorCodeToField[errorItem.code];
                if (fieldName) {
                  let fieldDisplayName;
                  if (fieldName === 'company_name') {
                    fieldDisplayName = 'company name';
                  } else if (fieldName === 'phone_number') {
                    fieldDisplayName = 'phone number';
                  } else {
                    fieldDisplayName = fieldName;
                  }
                  setFieldError(`new_sellers.${index}.${fieldName}`, `This ${fieldDisplayName} already exists.`);
                  setFieldTouched(`new_sellers.${index}.${fieldName}`, true);
                  setFieldValue(`new_sellers.${index}.existingSellerId`, errorItem.key);
                }
              }
            }
          );
        }
        if (service === 'publicProduct') {
          onSuccess(data.data.sellers);
        } else if (service === 'requisition') {
          onSuccess(data.data.catalogItems);
        }
      })
      .catch(err => {
        console.log(err.data);
      })
      .finally(() => {
        finishLoading();
      });
  };

  return (
    <DescPopup big open={open} handleClose={handleClosePopup}>
      <Formik
        initialValues={initialValuesForLinkOrCreateSeller}
        validationSchema={validationSchemaForLinkOrCreateSeller}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Form style={{ width: '100%', overflow: 'auto', maxHeight: '600px' }} className='styled-scrollbar'>
            <Grid container item md={12} p={2}>
              <Grid item md={12} textAlign='center'>
                <BadgeIcon sx={{ color: 'primary.main', fontSize: 40 }} />
              </Grid>
              <Grid item md={12} textAlign='center' pb={2}>
                <Typography variant='overline' color='text.disabled' fontWeight={600} my={1}>
                  Link Seller
                </Typography>
              </Grid>
              <QueryStatusComponent queryHandler={LoadingFetchStatus} LoadingFullHeight={false}>
                <Grid container item md={12}>
                  <Grid item container md={12} alignItems='center' spacing={2} py={1}>
                    <Grid item md={4}>
                      <Typography fontSize={'13px'} fontWeight={600} color={'text.disabled'}>
                        Link Seller(s)
                      </Typography>
                    </Grid>
                    <Grid item md={8} pr={2}>
                      <AddSellerDropDown
                        dropdownTitle={dropdownTitle}
                        options={filteredSellersOptions}
                        onSearchChange={setSearchValue}
                        handleCheckSeller={handleCheckSeller}
                        handleUncheckSeller={handleUncheckSeller}
                        selectedSellers={selectedSellers}
                        onCreateOption={(value) => {
                          formik.setFieldValue('new_sellers', [
                            ...formik.values.new_sellers,
                            {
                              seller_id: '',
                              company_name: value,
                              country_id: '',
                              city_id: '',
                              full_name: '',
                              email: '',
                              phone_number: '',
                              identifier: Date.now(),
                              existingSellerId: undefined,
                            },
                          ]);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container md={12} pb={0.5}>
                    <FieldArray
                      name='new_sellers'
                      render={arrayHelpers => (
                        <Grid container md={12} mt={2} alignItems={'center'}>
                          {formik.values.new_sellers.map((new_seller, index) => (
                            <SellerItem
                              key={index}
                              index={index}
                              onRemove={() => arrayHelpers.remove(index)}
                              productId={productId}
                              existingSellerId={new_seller.existingSellerId}
                            />
                          ))}
                          <Button
                            type='button'
                            onClick={() =>
                              arrayHelpers.push({
                                seller_id: '',
                                company_name: '',
                                country_id: '',
                                city_id: '',
                                full_name: '',
                                email: '',
                                phone_number: '',
                                identifier: Date.now(),
                                existingSellerId: undefined,
                              })
                            }
                            startIcon={<AddIcon sx={{ fontSize: 'small' }} />}
                            sx={{ fontWeight: 500, display: 'flex-end' }}
                          >
                            Create a new Private Seller
                          </Button>
                        </Grid>
                      )}
                    />
                  </Grid>
                  <Grid item container md={12} alignItems={'center'} justifyContent={'center'} spacing={1} pt={3}>
                    <Grid item md={4} pr={2}>
                      <Button fullWidth variant='contained' color='info' type='button' onClick={handleClosePopup}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={4} pr={2}>
                      <StyledLoadingButton
                        type='submit'
                        variant='contained'
                        color='success'
                        loading={linkLoading}
                        disabled={selectedSellers.length == 0 && formik.values.new_sellers.length == 0}
                        translateKey={
                          <span>
                            Link Seller <span style={{ textTransform: 'lowercase' }}>(s)</span>
                          </span>
                        }
                        disableTranslate
                      />
                    </Grid>
                    <Grid item md={4}></Grid>
                  </Grid>
                </Grid>
              </QueryStatusComponent>
            </Grid>
          </Form>
        )}
      </Formik>
    </DescPopup>
  );
};

import { Product, ProductCategoryKind } from '@/models';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import RejectionHistoryPopup from 'src/ui/components/shared/popups/rejection-history-popup';
import { ChatErrorIcon } from 'src/ui/icons/error-chat-icon';

interface ProductStatusProps {
    productDetails: Product;
}

const ProductStatus: React.FC<ProductStatusProps> = ({ productDetails }) => {
    const rejectionHistoryPopup = usePopup();

    if (!productDetails?.seller_product_status) {
        return null;
    }

    return (
        <>
            {(productDetails?.seller_product_status === 'CREATE_PENDING_APPROVAL' || productDetails?.seller_product_status === 'EDIT_PENDING_APPROVAL') && (
                <Grid item sx={{ display: 'flex', alignItems: 'center', gap: 0.5, ml: 1 }}>
                    <RotateRightIcon sx={{ color: '#FB7F0D', fontSize: '20px', mr: 0.5 }} />
                    <Typography variant='subtitle2' color='#8B8D98' fontWeight={500}>
                        {productDetails?.seller_product_status === 'CREATE_PENDING_APPROVAL' ? `New ${productDetails?.category_kind === ProductCategoryKind.SERVICE ? 'Service' : 'Product'}` : 'Edits'}: Pending
                    </Typography>
                </Grid>
            )}

            {(productDetails?.seller_product_status === 'CREATE_REJECTED' || productDetails?.seller_product_status === 'EDIT_REJECTED') && (
                <>
                <Grid item sx={{ display: 'flex', alignItems: 'center', gap: 0.5, ml: 1, cursor: 'pointer' }} onClick={rejectionHistoryPopup.handleOpen}>
                    <ChatErrorIcon sx={{ color: '#FF4D4D', fontSize: '19px', mr: 0.5, transform: 'translate(0, 1px)' }} />
                    <Typography variant='subtitle2' color='#8B8D98' fontWeight={500}>
                        {productDetails?.seller_product_status === 'CREATE_REJECTED' ? `New ${productDetails?.category_kind === ProductCategoryKind.SERVICE ? 'Service' : 'Product'}` : 'Edits'}: Rejected
                    </Typography>
                </Grid>
                <RejectionHistoryPopup isOpen={rejectionHistoryPopup.isOpen} onClose={rejectionHistoryPopup.handleClose} rejection_history={productDetails?.rejection_history} />
                </>
            )}

        </>
    );
};

export default ProductStatus;

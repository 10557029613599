import { SellerDetails } from '@/models';
import { useProcurementStore } from '@/zustand';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRef, useState } from 'react';
import PrivacyButton from '../../buttons/privacy-button';
import { TextTooltip } from '../../text-tooltip';

export type StyledCTClickableCardProps = {
  textProperties: SellerDetails;
  handleClickCard: () => void;
};

const statusStyles = {
  QUOTED: {
    status: 'QUOTED',
    colorStatus: 'primary.main',
    bgColorStatus: 'primary.light',
  },
  DECLINED: {
    status: 'DECLINED',
    colorStatus: 'error.main',
    bgColorStatus: 'warning.contrastText',
  },
  QUOTED_DECLINED_BY_BUYER: {
    status: 'QUOTED_DECLINED_BY_BUYER',
    colorStatus: 'error.main',
    bgColorStatus: 'warning.contrastText',
  },
  PENDING: {
    status: 'PENDING',
    colorStatus: 'secondary.main',
    bgColorStatus: 'rgba(254, 229, 207, 0.9)',
  },
  NOT_QUOTED: {
    status: 'NOT_QUOTED',
    colorStatus: 'text.third',
    bgColorStatus: 'primary.light',
  },
  EXPIRED: {
    status: 'EXPIRED',
    colorStatus: 'primary.main',
    bgColorStatus: 'primary.light',
  },
  EXPIRED_EXTENSION_REQUESTED: {
    status: 'EXPIRED_EXTENSION_REQUESTED',
    colorStatus: 'success.main',
    bgColorStatus: 'primary.light',
  },
};

export const StyledCTClickableCard: React.VFC<StyledCTClickableCardProps> = ({ textProperties, handleClickCard }) => {
  const textRef = useRef<HTMLParagraphElement>(null);

  const { requestExtensionForCTQuote } = useProcurementStore();
  const { t } = useTranslation();

  const { status, colorStatus } = statusStyles[textProperties.status];
  const handleClick = () => {
    if (status === 'QUOTED') {
      handleClickCard();
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const stopLoading = () => setIsLoading(false);
  const handleHoverIn = (event: React.MouseEvent<HTMLElement>) => handleMouseEnter();
  const handleHoverOut = () => handleMouseLeave();

  const handleRequestExtension = (quoteId: string) => {
    setIsLoading(true);
    requestExtensionForCTQuote(quoteId, stopLoading);
  };

  return (
    <Box sx={{ position: 'relative', py: 2 }}>
      {textProperties?.previously_purchased && (
        <Typography
          variant='subtitle2'
          sx={{
            color: 'text.success',
            textTransform: 'capitalize',
            textAlign: 'start',
            fontWeight: 500,
            position: 'absolute',
            top: 0,
            left: '3px',
            transform: 'translateY(-1px)',
          }}
        >
          previously purchased{' '}
        </Typography>
      )}

      <div style={{ minHeight: '66px' }}>
        <Box sx={{ position: 'relative' }}>
          <Box
            onClick={() =>
              status == 'EXPIRED' && textProperties?.can_request_extension_for_quote == true && isLoading == false
                ? handleRequestExtension(textProperties.quote_id)
                : status == 'QUOTED'
                ? handleClick()
                : null
            }
            onMouseEnter={event =>
              (status === 'EXPIRED' || status === 'EXPIRED_EXTENSION_REQUESTED') && handleHoverIn(event)
            }
            onMouseLeave={() => (status === 'EXPIRED' || status === 'EXPIRED_EXTENSION_REQUESTED') && handleHoverOut()}
            sx={{
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: 'rgba(19, 98, 140, .5)',
              borderRadius: '8px',
              cursor:
                status == 'QUOTED' || (status == 'EXPIRED' && textProperties?.can_request_extension_for_quote == true)
                  ? 'pointer'
                  : 'auto',
              width: 230,
              backgroundColor: textProperties.is_selected ? 'primary.main' : 'transparent',
            }}
          >
            <Grid sx={{ mx: 1, my: 1 }}>
              <TextTooltip title={textProperties.seller_name} textRef={textRef}>
                <Typography
                  ref={textRef}
                  variant='subtitle2'
                  fontWeight={500}
                  color={textProperties.is_selected ? '#fff' : 'text.disabled'}
                  sx={{
                    width: 'auto',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    transition: 'color 300ms',
                  }}
                >
                  <Grid container alignItems={'center'} gap={1}>
                    {isHovered && (status === 'EXPIRED' || status === 'EXPIRED_EXTENSION_REQUESTED') ? (
                      <Grid container alignItems={'center'} gap={1}>
                        <ErrorOutlineOutlinedIcon sx={{ color: 'error.main', fontSize: '18px' }} />
                        <Typography variant='subtitle2' color={'info.dark'}>
                          Expired
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid container alignItems={'center'} md={12} gap={0.5}>
                        {(status === 'EXPIRED' || status === 'EXPIRED_EXTENSION_REQUESTED') && (
                          <Grid item md={1}>
                            <ErrorOutlineOutlinedIcon sx={{ color: 'error.main', fontSize: '18px' }} />
                          </Grid>
                        )}
                        <Grid item container md={10} pb={0.5} alignItems={'center'} sx={{ lineHeight: 1.1 }}>
                          <Typography variant='subtitle2'> {textProperties.seller_name}</Typography>
                          {textProperties.isPrivate && (
                            <PrivacyButton
                              iconHeight='1.2rem'
                              disableText
                              privacyLevel='COMPANY'
                            />
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Typography>
              </TextTooltip>

              <Typography
                variant='subtitle2'
                fontWeight={900}
                color={textProperties.is_selected ? '#fff' : 'text.disabled'}
                style={{ transition: 'color 300ms' }}
              >
                <Grid container gap={1}>
                  {(status === 'QUOTED' || status === 'EXPIRED' || status === 'EXPIRED_EXTENSION_REQUESTED') &&
                    !isHovered && (
                      <>
                        {'AED '}
                        {textProperties.price} x {textProperties.quantity}
                        {textProperties.isLess ? (
                          <ArrowDownwardIcon sx={{ color: 'info.dark', fontSize: '18px' }} />
                        ) : null}
                      </>
                    )}
                  {status === 'PENDING' && (
                    <Typography variant='subtitle2' color={colorStatus} fontWeight={400}>
                      {' '}
                      {'Pending'}{' '}
                    </Typography>
                  )}
                  {status === 'DECLINED' && (
                    <Typography variant='subtitle2' color={colorStatus} fontWeight={400}>
                      {' '}
                      {'RFQ Declined'}{' '}
                    </Typography>
                  )}
                  {status === 'QUOTED_DECLINED_BY_BUYER' && (
                    <Typography variant='subtitle2' color={colorStatus} fontWeight={400}>
                      {' '}
                      {'Quote Declined'}{' '}
                    </Typography>
                  )}

                  {status === 'EXPIRED' && isHovered && (
                    <Typography
                      variant='subtitle2'
                      py={0.3}
                      color={textProperties?.can_request_extension_for_quote == true ? colorStatus : 'text.third'}
                      fontWeight={500}
                      sx={{ cursor: textProperties?.can_request_extension_for_quote == true ? 'pointer' : 'auto' }}
                    >
                      {textProperties?.can_request_extension_for_quote == true ? (
                        isLoading ? (
                          <span>Loading...</span>
                        ) : (
                          t('buttons.request_extension')
                        )
                      ) : (
                        <Typography variant='subtitle2' py={0.3} color={'text.third'} fontWeight={500}>
                          Request Extension
                        </Typography>
                      )}
                    </Typography>
                  )}

                  {status === 'EXPIRED_EXTENSION_REQUESTED' && isHovered && (
                    <Typography variant='subtitle2' color={colorStatus} fontWeight={500} py={0.3}>
                      {' '}
                      {'Extension Requested'}{' '}
                    </Typography>
                  )}
                  {status === 'NOT_QUOTED' && (
                    <Typography variant='subtitle2' color={colorStatus}>
                      {' '}
                      {'Not priced'}{' '}
                    </Typography>
                  )}
                </Grid>
              </Typography>
            </Grid>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

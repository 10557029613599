import { VAT } from "@/constants";
import { Product, Table, TableMapperType } from "@/models";
import { NotifyError, NotifySuccess } from "@/services";
import { t } from "i18next";
import produce from "immer";
import { getProductsSlicePropertiesNames, getProductsTableMapper } from "src/logic/services/procurement/table-products";
import QuoteSavingServices from "src/logic/services/products-saving/quotes";
import { buildTableModel } from "src/logic/services/tables";
import { StateCreator } from "zustand";

export interface ProductsTableSlice {
    productsTableSliceProperties: {
        data: {
            tableType: TableMapperType | null
            tableData: Table | null
            products: Product[]
            summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
            typingTimer: NodeJS.Timeout | null
            quantityTimer: NodeJS.Timeout | null
            requirementsTimer: NodeJS.Timeout | null
        },
        actions: {
            loadData: (tableType: TableMapperType, products: Product[]) => void
            setSummaryDetails: (products: Product[]) => void
            setTimer: (timer: NodeJS.Timeout | null) => void
            setQuantityTimer: (timer: NodeJS.Timeout | null) => void
            setRequirementsTimer: (timer: NodeJS.Timeout | null) => void
            productsUpdate: (products: Product[]) => void
            changePrice: (quoteId: number, productId: string, newPrice: string) => void
            changeQuantity: (quoteId: number, productId: string, newQuantity: number) => void
            changeAdditionalRequirements: (quoteId: number, productId: string, additional_requirements: string) => void
            changeWarrantyTerm: (quoteId: number, productId: string, warrantyTerm: any, isCustomWarranty: boolean) => void
            deleteProduct: (quoteId: number, productId: string) => void
            changeRemakrs: (quoteId: number, productId: string, remarks: string) => void
        }
    },
}

export const createProductsTableSlice: StateCreator<ProductsTableSlice> = (set, get, api) => ({
    productsTableSliceProperties: {
        data: {
            tableType: null,
            tableData: null,
            typingTimer: null,
            products: [],
            summaryDetails: { SummarySubtotal: 0, SummaryVat: 0, SummaryTotal: 0 },
        },
        actions: {
            loadData: (tableType: TableMapperType, products: Product[]) => {
                set(produce(draftState => {
                    draftState.productsTableSliceProperties.data.tableType = tableType
                    draftState.productsTableSliceProperties.data.products = products
                }))
                const tableModel = buildTableModel({
                    jsonData: products, 
                    columnMapper: getProductsTableMapper(get().productsTableSliceProperties.data.tableType)
                })

                set(produce(draftState => { draftState.productsTableSliceProperties.data.tableData = tableModel }))
                get().productsTableSliceProperties.actions.setSummaryDetails(products)
            },
            setTimer: (timer: NodeJS.Timeout | null) => set(produce(draftState => { draftState.productsTableSliceProperties.data.typingTimer = timer })),
            setQuantityTimer: (timer: NodeJS.Timeout | null) => set(produce(draftState => { draftState.productsTableSliceProperties.data.quantityTimer = timer })),
            setRequirementsTimer: (timer: NodeJS.Timeout | null) => set(produce(draftState => { draftState.productsTableSliceProperties.data.requirementsTimer = timer })),
            setSummaryDetails: (products: Product[]) => {
                const summary = getVATTotalValues(products)
                set(produce(draftState => {
                    draftState.productsTableSliceProperties.data.summaryDetails.SummarySubtotal = summary.subtotal
                    draftState.productsTableSliceProperties.data.summaryDetails.SummaryVat = summary.vat
                    draftState.productsTableSliceProperties.data.summaryDetails.SummaryTotal = summary.total
                }))
            },
            productsUpdate: (products: Product[]) => {
                const data = get().productsTableSliceProperties.data
                const actions = get().productsTableSliceProperties.actions
                actions.loadData(data.tableType, products)
                clearTimeout(data.typingTimer);
                const newTimer = setTimeout(() => {
                    actions.setTimer(null)
                    const handleSavingSucess = (form: any) => {
                        get().productsTableSliceProperties.actions.setSummaryDetails(products)
                        get()[slicePropertiesName?.actions].setQuoteDetails(form)
                        NotifySuccess(t('notifications.changes_saved'))
                    }
                    let slicePropertiesName = getProductsSlicePropertiesNames(data.tableType)
                    get()[slicePropertiesName?.actions].saveFormProducts(get()[slicePropertiesName?.properties].quote?.quoteModel, products, handleSavingSucess)
                }, 300);
                actions.setTimer(newTimer)
            },
            changePrice: (quoteId: number, productId: string, newPrice: string) => {
                const actions = get().productsTableSliceProperties.actions
                const data = get().productsTableSliceProperties.data

                const oldProducts = data.products;
                const updatedProducts = oldProducts.map(product => product.id === productId ? { ...product, price: newPrice, unit_price: newPrice } : product)
                actions.loadData(data.tableType, updatedProducts)

                clearTimeout(data.typingTimer);
                const newTimer = setTimeout(() => {
                    actions.setTimer(null)
                    QuoteSavingServices.changePrice({
                        quote_id: quoteId,
                        product_id: productId,
                        price: newPrice
                    }).then((data) => {
                        NotifySuccess(t('notifications.changes_saved'))
                        get()["quoteDetailsSliceActions"]?.setQuoteDetails(data.data)
                    }).catch(() => NotifyError("An error occurred while changing the price"))                    
                }, 300);
                actions.setTimer(newTimer)
            },
            changeQuantity: (quoteId: number, productId: string, newQuantity: number) => {
                const actions = get().productsTableSliceProperties.actions
                const data = get().productsTableSliceProperties.data

                let newQuantityToUpdate = newQuantity;
                let oldQuantity;
                const oldProducts = data.products;
                const updatedProducts = oldProducts.map(product => {
                    if(product.id !== productId) return product;

                    oldQuantity = product.quantity;
                    if(product.requested_quantity != null && product.requested_quantity != undefined) {
                        const minQuantity = Math.min(newQuantity, product.requested_quantity);
                        newQuantityToUpdate = minQuantity;
                        return { ...product, quantity: minQuantity, available_quantity: minQuantity };
                    }
                    return { ...product, quantity: newQuantity, available_quantity: newQuantity };
                })
                if(oldQuantity === newQuantityToUpdate) {
                    return;
                }

                actions.loadData(data.tableType, updatedProducts)

                clearTimeout(data.quantityTimer);
                const newTimer = setTimeout(() => {
                    actions.setQuantityTimer(null)
                    QuoteSavingServices.changeQuantity({
                        quote_id: quoteId,
                        product_id: productId,
                        quantity: newQuantityToUpdate
                    }).then((data) => {
                        NotifySuccess(t('notifications.changes_saved'))
                        get()["quoteDetailsSliceActions"]?.setQuoteDetails(data.data)
                    }).catch(() => NotifyError("An error occurred while changing the quantity"))                       
                }, 300);
                actions.setQuantityTimer(newTimer)
            },
            changeAdditionalRequirements: (quoteId: number, productId: string, additional_requirements: string) => {
                const actions = get().productsTableSliceProperties.actions
                const data = get().productsTableSliceProperties.data

                let newRequirementsToUpdate = additional_requirements;
                let oldRequirements;
                const oldProducts = data.products;
                const updatedProducts = oldProducts.map(product => {
                    if(product.id !== productId) return product;
                    return { ...product, additional_requirements: newRequirementsToUpdate };
                })
                if(oldRequirements === newRequirementsToUpdate) {
                    return;
                }

                actions.loadData(data.tableType, updatedProducts)

                clearTimeout(data.requirementsTimer);
                const newTimer = setTimeout(() => {
                    actions.setRequirementsTimer(null)
                    QuoteSavingServices.changeAdditionalRequirements({
                        quote_id: quoteId,
                        product_id: productId,
                        additional_requirements: newRequirementsToUpdate
                    }).then((data) => {
                        NotifySuccess(t('notifications.changes_saved'))
                        get()["quoteDetailsSliceActions"]?.setQuoteDetails(data.data)
                    }).catch(() => NotifyError("An error occurred while changing the requirements"))                       
                }, 300);
                actions.setRequirementsTimer(newTimer)
            },
            changeWarrantyTerm: (quoteId: number, productId: string, warrantyTerm: any, isCustomWarranty: boolean) => {
                const actions = get().productsTableSliceProperties.actions
                const data = get().productsTableSliceProperties.data

                const oldProducts = data.products;
                const updatedProducts = oldProducts.map(
                    product => {
                      return product.id == productId
                        ? {
                            ...product,
                            warranty_term: warrantyTerm,
                            isCustomWarranty
                          }
                        : product;
                    }
                );
                actions.loadData(data.tableType, updatedProducts)

                QuoteSavingServices.changeWarrantyTerm({
                    quote_id: quoteId,
                    product_id: productId,
                    warranty_term_id: warrantyTerm?.id,
                    warranty_term_custom_value: warrantyTerm?.value
                }).then((data) => {
                    NotifySuccess(t('notifications.changes_saved'))
                    get()["quoteDetailsSliceActions"]?.setQuoteDetails(data.data)
                }).catch(() => NotifyError("An error occurred while changing the warranty terms"))   
            },
            deleteProduct: (quoteId: number, productId: string) => {
                const actions = get().productsTableSliceProperties.actions
                const data = get().productsTableSliceProperties.data

                const oldProducts = data.products;
                const updatedProducts = oldProducts.filter(product => product.id !== productId);
                actions.loadData(data.tableType, updatedProducts)

                QuoteSavingServices.removeProductFromQuote({
                    quote_id: quoteId,
                    product_id: productId,
                }).then((data) => {
                    NotifySuccess(t('notifications.changes_saved'))
                    get()["quoteDetailsSliceActions"]?.setQuoteDetails(data.data)
                }).catch(() => NotifyError("An error occurred while deleting the product"))   

            },
            changeRemakrs: (quoteId: number, productId: string, remarks: string) => {
                const actions = get().productsTableSliceProperties.actions
                const data = get().productsTableSliceProperties.data

                const oldProducts = data.products;
                const updatedProducts = oldProducts.map(product => product.id === productId ? {...product, remarks} : product);
                actions.loadData(data.tableType, updatedProducts)

                QuoteSavingServices.changeRemarks({
                    quote_id: quoteId,
                    product_id: productId,
                    remarks
                }).then((data) => {
                    NotifySuccess(t('notifications.changes_saved'))
                    get()["quoteDetailsSliceActions"]?.setQuoteDetails(data.data)
                }).catch(() => NotifyError("An error occurred while changing remark"))   
            },

        }
    }
})

const getVATTotalValues = (products: Product[]) => {

    let subtotal = products.reduce((accumulator, product) => accumulator + (Number(product.price) * product.quantity), 0);
    let vat = VAT * subtotal;

    subtotal = subtotal.toFixed(2);
    vat = vat.toFixed(2);

    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

    return { subtotal: subtotal, vat: vat, total: total };
}

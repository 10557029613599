import { procurementStatus } from "@/config"
import { useTranslation } from 'next-i18next';
import { Box, Divider, Grid, Typography } from "@mui/material"
import React from "react"
import { StyledPopper } from "src/ui/components/shared";

interface ApproversListProps {
    isHovered: boolean
    Anchor: HTMLElement | null
    listOfApprovers: { user_name: string, status: string }[]
    handleClose: () => void
}

export const ApproversList: React.VFC<ApproversListProps> = ({ isHovered, Anchor, listOfApprovers, handleClose }) => {
    const { t } = useTranslation();
    const mainStatus = [procurementStatus.MIXED, procurementStatus.PARTIALLY_QUOTES_RECEIVED, procurementStatus.QUOTES_RECEIVED, procurementStatus.EDITING, procurementStatus.QUOTE_RECEIVED, procurementStatus.PO_RECEIVED, procurementStatus.RFQ_RECEIVED, procurementStatus.DRAFT, procurementStatus.PARTIALLY_APPROVED, procurementStatus.PARTIALLY_APPROVED_BY_ME, procurementStatus.PENDING, procurementStatus.QUOTE_GENERATED, procurementStatus.PARTIALLY_SENT_TO_SELLERS, procurementStatus.READY]
    const dangerStatus = [procurementStatus.REVOKED, procurementStatus.DECLINED, procurementStatus.REJECTED, procurementStatus.REJECTED_BY_ME, procurementStatus.CANCELED, procurementStatus.RECALLED, procurementStatus.PARTIALLY_DECLINED]
    const warningStatus = [procurementStatus.PENDING_APPROVAL, procurementStatus.PENDING_MY_APPROVAL, procurementStatus.SENT_FOR_APPROVAL, procurementStatus.REQUESTED, "PENDING_QUOTES"]
    const successStatus = [procurementStatus.PO_GENERATED, procurementStatus.PUBLISHED, procurementStatus.APPROVED, procurementStatus.CONFIRMED, procurementStatus.APPROVED_BY_ME, procurementStatus.SENT_TO_SELLERS, procurementStatus.SENT_TO_SELLER, procurementStatus.SENT_TO_BUYER, procurementStatus.CONVERTED_TO_RFQ, procurementStatus.PARTIALLY_APPROVED, procurementStatus.PARTIALLY_SENT_TO_SELLERS, procurementStatus.PARTIALLY_CONFIRMED]
    const statuses = {}
    mainStatus.forEach(key => statuses[key] = 'main');
    dangerStatus.forEach(key => statuses[key] = 'danger');
    warningStatus.forEach(key => statuses[key] = 'warning');
    successStatus.forEach(key => statuses[key] = 'success');

    return (
        <>
            <StyledPopper open={isHovered} anchorEl={Anchor} handleClose={handleClose}>
                <div style={{ padding: 15 }}>
                    <Typography variant="body2" fontWeight={600} color={'primary.main'}>{t('texts.approvers')}</Typography>
                    <Box width={"100%"} mt={1} mb={2}> <Divider sx={{ borderColor: 'info.contrastText', opacity: 0.7 }} /></Box>
                    {listOfApprovers?.map(approver => (
                        <Grid container justifyContent={'space-between'} gap={5}>
                            <Typography color="text.disabled" variant="subtitle2">{approver.user_name}</Typography>
                            <Typography variant="subtitle2"
                                color={statuses[approver.status] == 'danger' ? 'error' : statuses[approver.status] == 'warning' ? 'secondary' : statuses[approver.status] == 'success' ? 'success.dark' : 'primary'}>
                                {approver.status && t('chip.' + approver.status)}
                            </Typography>
                        </Grid>
                    ))}
                </div>
            </StyledPopper>
        </>
    )
}
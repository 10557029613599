import { Grid, Typography, Button } from '@mui/material';
import { ResponsiveContainer } from '@/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import { sm } from 'src/site-map';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { apiURLs } from '@/config';
import axios from 'axios';
import { useRouter } from 'next/router';
import { getRegisterTranslationKey } from '@/hooks'

interface verificationExpiredProps { email: string; }

export const VerificationExpired: React.FC<verificationExpiredProps> = email => {
  const isMobile = useMediaQuery(t => t.breakpoints.down('md'));
  const { t } = useTranslation();
  const router = useRouter();

  const [resendCode, setresendCode] = useState(false);

  const resendVerificationCode = () => {
    axios
      .post(apiURLs.resendVerification, email)
      .then(response => {
        setresendCode(true);
      })
      .catch(exception => {
        router.push(sm.register.somethingWentWrong.url);
      });
  };

  return (
    <Grid container component={ResponsiveContainer}>
      <Grid item xs={12} sx={{ pt: [4, 16] }}>
        <Typography variant='h1' sx={{ fontWeight: 'bolder' }}> {t(getRegisterTranslationKey('headers.verification_expired'))} </Typography>
      </Grid>
      <Grid item xs={12} sx={{ pt: 3 }}>
        <Typography variant='body2' color='text.third'> {t(getRegisterTranslationKey('paragraphs.verification_expired'))} </Typography>
      </Grid>
      <Grid container item xs={12} my={[4, 3]}>
        {!resendCode ? (
          <Button
            variant='contained'
            onClick={resendVerificationCode}
            sx={{ float: 'left', width: isMobile ? '100%' : '247px' }}
          >
            {t('buttons.resend_verification_code_expired')}
          </Button>
        ) : (
          <>
            <Button
              variant='outlined'
              startIcon={<CheckIcon />}
              sx={{ mr: 2, float: 'left', width: isMobile ? '100%' : '325px', bgcolor: 'primary.light' }}
            >
              {t('buttons.verification_code_sent')}
            </Button>
            {!isMobile && (
              <Button
                variant='outlined'
                component="a"
                href={sm.contactUs.url}
                sx={{ float: 'left', width: '156px' }}
              >
                {t('buttons.go_to_contact_us')}
              </Button>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

import { VisibilityLockIcon } from '@/icons';
import { Product } from '@/models';
import { useUserData } from '@/zustand';
import BadgeIcon from '@mui/icons-material/Badge';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Button, ButtonProps, Divider, Grid, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import usePopup from 'src/logic/hooks/use-popup';
import { formatInteger } from 'src/logic/utils/numbers';
import { sm } from 'src/site-map';
import { MoreOptions } from 'src/ui/components/dumb';
import RejectionHistoryPopup from 'src/ui/components/shared/popups/rejection-history-popup';
import { ChatErrorIcon } from 'src/ui/icons/error-chat-icon';
import { LoadingButton } from "@mui/lab";

type StatusIndicatorProps = {
  icon: React.ReactNode;
  text?: string;
  element?: React.ReactNode;
  isSeller?: boolean;
};

type ActionButtonProps = {
  text: string;
  onClick: () => void;
  icon: React.ReactNode;
  color: ButtonProps['color'];
};

const StatusIndicator = ({ icon, text, element, isSeller }: StatusIndicatorProps) => (
  <Grid item sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
    {icon}
    {text !== undefined && (
      <Typography
        variant='subtitle2'
        color='#8B8D98'
        fontWeight={500}
        sx={{ transform: !isSeller ? 'translateY(2px)' : 'none' }}
      >
        {text}
      </Typography>
    )}
    {element !== undefined && <>{element}</>}
  </Grid>
);

const DividerGrid = () => (
  <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
    <Divider orientation='vertical' sx={{ bgcolor: '#EAEEF6', height: '20px', mx: 2 }} />
  </Grid>
);

const StatusBar = ({ statuses, actions, loadingState }: { statuses: StatusIndicatorProps[]; actions: ActionButtonProps[], loadingState?: boolean }) => {
  const { isSeller: _isSeller } = useUserData();
  const isSeller = _isSeller();

  return (
    <Grid
      container
      alignItems='center'
      justifyContent={'space-between'}
      sx={{ bgcolor: '#F6F8FC', p: 1, my: 0.5, mb: 2, borderRadius: '8px' }}
    >
      <Grid item>
        <Grid container spacing={2} alignItems='center'>
          {statuses.map((statusItem, index) => {
            return (
              <React.Fragment key={statusItem.text}>
                {index > 0 && <DividerGrid />}
                <StatusIndicator {...statusItem} isSeller={isSeller} />
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>

      <Grid item>
        {actions.length > 0 && (
          <>
            {
              loadingState ? <LoadingButton loading={true} loadingPosition="end" /> : <MoreOptions HideBackground>
                {actions.map(actionItem => {
                  return (
                    <Button
                      key={actionItem.text}
                      fullWidth
                      style={{ justifyContent: 'start' }}
                      startIcon={actionItem.icon}
                      color={actionItem.color}
                      onClick={actionItem.onClick}
                    >
                      {actionItem.text}
                    </Button>
                  );
                })}
              </MoreOptions>
            }
          </>
        )}
      </Grid>
    </Grid>
  );
};

const BuyerStatus = ({ productDetails }: { productDetails: Product }) => {
  const router = useRouter();

  const statuses = React.useMemo(() => {
    const result: StatusIndicatorProps[] = [];
    if (productDetails?.is_private_product) {
      result.push({
        text: 'Private',
        icon: <VisibilityLockIcon color='#189BDC' sx={{ fontSize: '18px', mr: 0.5 }} />,
      });
    } else {
      result.push({ text: 'Public', icon: <PublicIcon sx={{ color: '#189BDC', fontSize: '17px', mr: 0.5 }} /> });
    }

    const numberOfSellers = productDetails?.sellers?.length ?? 0;
    result.push({
      text: `${formatInteger(numberOfSellers)} Seller${numberOfSellers !== 1 ? 's' : ''}`,
      icon: <BadgeIcon sx={{ color: '#189BDC', fontSize: '19px', mr: 0.5 }} />,
    });

    return result;
  }, [productDetails]);

  const actions = React.useMemo(() => {
    const result: ActionButtonProps[] = [];

    if (productDetails?.can_edit_product_details) {
      result.push({
        text: 'Edit',
        onClick: () => router.push(sm.catalogue.products.editProduct(`${productDetails?.id}`).url),
        icon: <BorderColorIcon />,
        color: 'primary',
      });
    }

    return result;
  }, [productDetails]);

  return <StatusBar actions={actions} statuses={statuses} />;
};

const SellerStatus = ({ productDetails, handleUnlinkProduct, loadingState }: { productDetails: Product, handleUnlinkProduct: () => void, loadingState: boolean }) => {
  const router = useRouter();
  const { canUpdateCatalog } = useUserData();

  const rejectionHistoryPopup = usePopup();
  const statuses = React.useMemo(() => {
    const result: StatusIndicatorProps[] = [];
    if (productDetails?.catalog_item) {
      result.push({ text: 'Linked', icon: <LinkIcon color='success' sx={{ fontSize: '18px', mr: 0.5 }} /> });
    } else {
      result.push({ text: 'Not Linked', icon: <LinkOffIcon sx={{ color: '#8B8D98', fontSize: '18px', mr: 0.5 }} /> });
    }

    if (productDetails?.catalog_item?.status === 'PUBLISHED') {
      result.push({ text: 'Published', icon: <PublicIcon sx={{ color: '#189BDC', fontSize: '14px', mr: 0.5 }} /> });
    } else {
      result.push({
        text: 'Not Published',
        icon: <PublicOffIcon sx={{ color: 'rgba(112, 176, 255, 1)', fontSize: '14px', mr: 0.5 }} />,
      });
    }

    if (productDetails?.seller_product_status === 'CREATE_PENDING_APPROVAL') {
      result.push({
        text: 'New Product: Pending',
        icon: <RotateRightIcon sx={{ color: '#FB7F0D', fontSize: '20px', mr: 0.5 }} />,
      });
    } else if (productDetails?.seller_product_status === 'EDIT_PENDING_APPROVAL') {
      result.push({
        text: 'Edits: Pending',
        icon: <RotateRightIcon sx={{ color: '#FB7F0D', fontSize: '20px', mr: 0.5 }} />,
      });
    } else if (productDetails?.seller_product_status === 'CREATE_REJECTED') {
      result.push({
        text: 'New Product: Rejected',
        icon: <ChatErrorIcon sx={{ color: '#FF4D4D', fontSize: '20px', mr: 0.5 }} />,
        element: (
          <Button
            type='button'
            onClick={rejectionHistoryPopup.handleOpen}
            sx={{ color: '#242833', fontSize: '11px', fontWeight: 500 }}
          >
            View History
          </Button>
        ),
      });
    } else if (productDetails?.seller_product_status === 'EDIT_REJECTED') {
      result.push({
        text: 'Edits: Rejected',
        icon: <ChatErrorIcon sx={{ color: '#FF4D4D', fontSize: '20px', mr: 0.5 }} />,
        element: (
          <Button
            type='button'
            onClick={rejectionHistoryPopup.handleOpen}
            sx={{ color: '#242833', fontSize: '11px', fontWeight: 500 }}
          >
            View History
          </Button>
        ),
      });
    }

    return result;
  }, [productDetails]);

  const actions = React.useMemo(() => {
    const result: ActionButtonProps[] = [];

    if (
      (productDetails.edit_type === 'suggest_edit' || productDetails.edit_type === 'direct_edit') &&
      canUpdateCatalog
    ) {
      result.push({
        text: productDetails.edit_type === 'suggest_edit' ? 'Suggest Edits' : 'Edit',
        onClick: () => router.push(sm.catalogue.products.editProduct(`${productDetails?.id}`).url),
        icon: <BorderColorIcon />,
        color: 'primary',
      });
    }
    if (productDetails?.catalog_item?.can_unlink && canUpdateCatalog) {
      result.push({ text: 'Unlink', onClick: handleUnlinkProduct, icon: <LinkOffIcon />, color: 'error' });
    }

    return result;
  }, [productDetails, canUpdateCatalog]);

  return (
    <>
      <StatusBar actions={actions} statuses={statuses} loadingState={loadingState} />
      <RejectionHistoryPopup
        isOpen={rejectionHistoryPopup.isOpen}
        onClose={rejectionHistoryPopup.handleClose}
        rejection_history={productDetails?.rejection_history}
      />
    </>
  );
};

const ProductStatusBar = ({ productDetails, handleUnlinkProduct, loadingState }: { productDetails: Product, handleUnlinkProduct: () => void, loadingState: boolean }) => {
  const isSeller = useUserData().isSeller();
  const isBuyer = useUserData().isBuyer();

  if (isBuyer) return <BuyerStatus productDetails={productDetails} />;
  if (isSeller) return <SellerStatus productDetails={productDetails} handleUnlinkProduct={handleUnlinkProduct} loadingState={loadingState} />;
  return null;
};

export default ProductStatusBar;

import { StyledPopper } from '@/components';
import { ProductCategoryKind } from '@/models';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, Typography } from '@mui/material';
import React from 'react';

type ListPricePopupProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClosePopper: () => void;
  listPrice?: number;
  categoryKind?: number;
};

export const ListPricePopup: React.VFC<ListPricePopupProps> = ({
  open,
  anchorEl,
  handleClosePopper,
  listPrice,
  categoryKind,
}) => {
  return (
    <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClosePopper} place='bottom-start' zIndex={1000}>
      <div style={{ maxWidth: '300px' }}>
        <Grid container p={2}>
          <Grid item xs={12} display="flex" alignItems="center">
            <InfoOutlinedIcon sx={{ fontSize: 22, color: 'primary.main' }} />
            <Typography variant="h6" color="primary.main" fontWeight={700} pl={1}>
              Offered Price
            </Typography>
          </Grid>

          <Grid item xs={12} display="flex" alignItems="center">
            <Typography variant="body2" color="text.disabled">
              <Typography component="span" fontWeight="bold" sx={{ marginRight: 0.5 }}>
                Price:
              </Typography>
              {`This is your PO price for this ${categoryKind === ProductCategoryKind.SERVICE ? 'service' : 'product'
                } and is subject to approval from the seller.`}
            </Typography>
          </Grid>

          <Grid item xs={12} display="flex" alignItems="center" pt={2}>
            <Typography variant="body2" color="text.disabled">
              <Typography component="span" fontWeight="bold" sx={{ marginRight: 0.5 }}>
                List Price:
              </Typography>
              {listPrice ? `AED ${listPrice}` : 'No Price Added'}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </StyledPopper>
  );
};

import { TermPayload } from 'src/logic/models/procurement/general-terms';

export const isTermsEqual = (term_1: TermPayload | undefined | null, term_2: TermPayload | undefined | null) => {
  if (!term_1 || !term_2) {
    return false;
  }

  if (term_1.id === term_2.id) {
    if (term_1.value == term_2.value) {
      return true;
    }
  }
  return false;
};

import { ConfirmPopup, DescPopup, StyledLoadingButton } from '@/components';
import { procurementStatus } from '@/config';
import { Product, ProductCategoryKind, ProductCategoryKindLabel, Seller } from '@/models';
import { NotifyError, NotifySuccess } from '@/services';
import { useProcurementStore, useUserData } from '@/zustand';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import { Box, Button, CardMedia, Divider, Grid, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import useCanModifyCatalogue from 'src/logic/hooks/smart/catalogue/use-can-modify-catalogue';
import usePopup from 'src/logic/hooks/use-popup';
import { useAdvancedFiltersSlice } from 'src/logic/zustand/catalouge/advanced-filters';
import { useManageCatalogueSlice } from 'src/logic/zustand/catalouge/manage-catalogue';
import { sm } from 'src/site-map';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import useShouldShowSellersList from 'src/ui/components/smart/catalogue/helpers/use-should-show-sellers-list';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';
import LoadingLayer from '../loading-layer';
import FullProductHistory from '../product-history/FullProductHistory';
import ProductStatus from './product-status';
import { SellersList } from './sellers-popup';

type ProductCardProps = {
  productDetails: Product;
  canPerformActionPO: boolean;
  canPerformActionRequisition: boolean;
  mainProduct?: Product;
  ControlButtons?: any;
  onClickAlternativeProduct?: Function;
  isPageAlternative?: boolean;
  seller?: Seller;
};

const SpecItem = ({ label, value }: { label: string | React.ReactNode; value: string | React.ReactNode }) => {
  return (
    <Box>
      <Typography fontSize={11} fontWeight={400} color='#C5C6CB' lineHeight={1}>
        {label}
      </Typography>
      <Typography fontSize={15} fontWeight={400} color='#3A3E50'>
        {value}
      </Typography>
    </Box>
  );
};

export const ProductCard: React.VFC<ProductCardProps> = ({
  productDetails,
  canPerformActionRequisition,
  canPerformActionPO,
  mainProduct,
  isPageAlternative,
  onClickAlternativeProduct,
  ControlButtons,
  seller,
}) => {
  const { push } = useRouter();
  const detailsPopup = usePopup();
  function onCLickCard() {
    productDetails.inCartType == 'alternative'
      ? // ? onClickAlternativeProduct?.()
      detailsPopup.handleOpen()
      : push(sm.catalogue.products.productId(`${productDetails.id}`).url);
  }
  const loadingProductsIds = useProcurementStore(store => store.productFormManagerProperties.data.loadingProductsIds);
  const productIsLoading = React.useMemo(
    () => loadingProductsIds.find(id => id === productDetails.id),
    [loadingProductsIds]
  );

  return (
    <Grid
      container
      my={2}
      sx={{
        position: 'relative',
        isolation: 'isolate',
        backgroundColor: 'white',
        justifyContent: 'center',
        borderRadius: '8px',
        borderLeft: productDetails.inCart
          ? productDetails.inCartType == 'alternative'
            ? '8px solid #FB7F0D'
            : '8px solid #13628C'
          : null,
        // '&:hover': {
        //   border: productDetails.inCartType == 'alternative' ? '0.5px solid #FB7F0D' : '0.5px solid #13628C',
        // },
        // transition: 'border 200ms',
      }}
    >
      {productIsLoading && <LoadingLayer />}
      <DescPopup big open={detailsPopup.isOpen} handleClose={detailsPopup.handleClose} paperSx={{ borderRadius: 0 }}>
        <ProductQuickView product={productDetails} disableNavigation />
      </DescPopup>
      <Grid container item justifyContent='center' md={1.5}>
        <Typography
          sx={{
            backgroundColor: '#fff',
            color: 'text.disabled',
            borderRadius: '16px',
            border: '0.5px solid #3A3E50',
            position: 'absolute',
            top: '.5rem',
            left: '.5rem',
            zIndex: 1,
            padding: '4px 12px',
          }}
          fontSize={11}
          fontWeight={500}
        >
          {ProductCategoryKindLabel[productDetails.category_kind]}
        </Typography>
        <Box width='100%' m={1}>
          <CardMedia
            component='img'
            sx={{ objectFit: 'contain', height: '123px', pt: 3 }}
            image={productDetails.image_url}
            alt={''}
          />
        </Box>
      </Grid>

      {productDetails.category_kind == 1 ? (
        <ProductCardContent productDetails={productDetails} onCLickCard={onCLickCard} />
      ) : (
        <ServiceCardContent
          productDetails={productDetails}
          onCLickCard={onCLickCard}
          isPageAlternative={isPageAlternative}
        />
      )}

      <Grid item md={0.5} my={2} sx={{ display: 'flex', justifyItems: 'center', justifyContent: 'center' }}>
        <Divider orientation='vertical' color='#D0E0E8' />
      </Grid>
      <Grid item container md={2.5} pr={2} alignItems='center'>
        <Stack width='100%'>
          <ControlButtons
            key={productDetails.id}
            product={productDetails}
            isPageAlternative={isPageAlternative}
            canPerformActionRequisition={canPerformActionRequisition}
            canPerformActionPO={canPerformActionPO}
            mainProductIsInCart={mainProduct?.inCart}
            seller={seller}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

const PriceAttribute = (productDetails: any) => {
  const purchaseHistoryItems = productDetails?.purchaseHistoryItems;
  const quoteHistoryItems = productDetails?.quote_history;

  const isSeller = useUserData().isSeller();
  const isBuyer = useUserData().isBuyer();

  if ((isSeller || isBuyer) && (purchaseHistoryItems || quoteHistoryItems)) {
    return (
      <FullProductHistory
        po_items={purchaseHistoryItems}
        quote_items={quoteHistoryItems}
        iconSx={{ ml: 0, transform: 'translateY(3px)', mr: 0.5 }}
      />
    );
  }
  return null;
};

const ProductCardContent = ({ productDetails, onCLickCard }) => {
  const shouldShowSellersList = useShouldShowSellersList();
  const { isSeller } = useUserData();
  const { t } = useTranslation();
  const canModifyCatalogue = useCanModifyCatalogue();
  return (
    <Grid item md={7.5} p={1}>
      <Grid item container md={12} alignItems={'center'} justifyContent={'space-between'}>
        <Grid item md={9} sx={{ display: 'flex', flexWrap: 'nowrap' }} alignItems={'center'}>
          <Grid item maxWidth={'90%'}>
            <Typography
              fontSize={18}
              color='text.primary'
              fontWeight={700}
              noWrap
              className='underline-on-hover cursor-pointer'
              my={1}
              onClick={onCLickCard}
              maxWidth='100%'
            >
              {productDetails.name}
            </Typography>
          </Grid>

          {productDetails?.is_private_product && (
            <Grid item sx={{ pl: 1, transition: 'translateY(-1px)' }}>
              <PrivacyButton
                disableText={true}
              />{' '}
            </Grid>
          )}

          <Grid item sx={{ pl: 0.5, transition: 'translateY(-1px)' }}>
            <PriceAttribute {...productDetails} />
          </Grid>

          {isSeller() && (<Grid item sx={{ flexShrink: 0 }}>
            <ProductStatus productDetails={productDetails} />
          </Grid>
          )}

        </Grid>
        {isSeller() && productDetails?.catalog_item?.is_published == true && (
          <PublicIcon sx={{ color: 'primary.main', fontSize: '18px', mx: 0.5, marginLeft: 'auto' }} />
        )}
        {isSeller() && productDetails?.catalog_item?.is_published == false && (
          <PublicOffIcon sx={{ color: 'rgba(112, 176, 255, 1)', fontSize: '18px', mx: 0.5, marginLeft: 'auto' }} />
        )}

        {shouldShowSellersList && (
          <Grid item sx={{ flexShrink: 0 }}>
            <SellersList length={productDetails?.sellers?.length} items={productDetails?.sellers} />
          </Grid>
        )}

        {productDetails?.catalog_item && canModifyCatalogue && isSeller() && <UnlinkCatalogueItemButton productDetails={productDetails} />}
      </Grid>

      <Grid container md={12} alignItems={'start'}>
        <Grid item md={3}>
          <Box height='50px' width='50px'>
            <img
              height='100%'
              width='100%'
              style={{ objectFit: 'contain' }}
              src={productDetails.brand.image_url}
              alt={''}
            />
          </Box>
          <SpecItem label={t('texts.part_number')} value={productDetails.part_number || 'N/A'} />
        </Grid>
        <Grid item md={9} container alignItems={'start'}>
          {productDetails?.productAttributes?.slice(0, 6)?.map(item => (
            <Grid key={item.name} item md={3} pt={0.5}>
              <SpecItem label={item.name} value={item.value || 'N/A'} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid >
  );
};

const ServiceCardContent = ({ productDetails, onCLickCard, isPageAlternative }) => {
  const shouldShowSellersList = useShouldShowSellersList();
  const [visibilityStatus, setVisibilityStatus] = React.useState(true);
  const canModifyCatalogue = useCanModifyCatalogue();

  const envActions = useProcurementStore(state => state.productFormManagerProperties.actions);
  const changeProductAlternativesRequirements = useProcurementStore(
    state => state.changeProductAlternativesRequirements
  );
  const resultsActions = useAdvancedFiltersSlice(state => state.actions);
  // const { changeProductRequirements } = useProcurementStore()
  const { isSeller } = useUserData();

  const handleChangeRequirements = (newRequirements: string) => {
    if (isPageAlternative) {
      changeProductAlternativesRequirements(productDetails, newRequirements);
    }
    if (productDetails.inCart) {
      envActions.changeRequirements(productDetails, newRequirements);
      // changeProductRequirements(newRequirements, productDetails);
    }
    resultsActions.changeProductRequirements(productDetails.id, newRequirements);
  };

  const toggleVisibilityText = () => {
    setVisibilityStatus(!visibilityStatus);
  };

  const { data } = useProcurementStore(state => state.productFormManagerProperties);
  const { environment } = data;
  const requirementsFieldError =
    productDetails.category_kind === ProductCategoryKind.SERVICE &&
    !productDetails.additional_requirements &&
    ((environment && productDetails.inCart) || data.productIdRequestedToBeAdded == productDetails.id);

  return (
    <Grid item md={7.5} p={1}>
      <Grid item container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item sx={{ display: 'flex' }} alignItems={'center'}>
          <Grid item maxWidth={'90%'}>
            <Typography
              fontSize={18}
              color='text.primary'
              fontWeight={700}
              noWrap
              className='underline-on-hover cursor-pointer'
              my={1}
              onClick={onCLickCard}
              maxWidth='100%'
            >
              {productDetails.name}
            </Typography>
          </Grid>

          {productDetails?.is_private_product && (
            <Grid item sx={{ ml: 1, transition: 'translateY(-1px)' }}>
              <PrivacyButton
                disableText={true}
              />{' '}
            </Grid>
          )}

          <Grid item sx={{ pl: 0.5, transition: 'translateY(-1px)' }}>
            <PriceAttribute {...productDetails} />
          </Grid>

          {isSeller() && (<Grid item sx={{ flexShrink: 0 }}>
            <ProductStatus productDetails={productDetails} />
          </Grid>
          )}

        </Grid>

        {isSeller() && productDetails?.catalog_item?.is_published == true && (
          <PublicIcon sx={{ color: 'primary.main', fontSize: '18px', mx: 0.5, marginLeft: 'auto' }} />
        )}
        {isSeller() && productDetails?.catalog_item?.is_published == false && (
          <PublicOffIcon sx={{ color: 'rgba(112, 176, 255, 1)', fontSize: '18px', mx: 0.5, marginLeft: 'auto' }} />
        )}

        {shouldShowSellersList && (
          <Grid item>
            <SellersList length={productDetails?.sellers?.length} items={productDetails?.sellers} />
          </Grid>
        )}

        {productDetails?.catalog_item && canModifyCatalogue && isSeller() && <UnlinkCatalogueItemButton productDetails={productDetails} />}

        <Grid container md={12} justifyContent={'space-between'} py={0.5}>
          <Grid item md={11}>
            <div>
              <Typography
                variant='subtitle2'
                fontWeight={400}
                color={'text.disabled'}
                sx={{ overflowX: 'hidden' }}
                noWrap={visibilityStatus == false ? true : false}
              >
                {productDetails?.description ?? 'Description: N/A'}
              </Typography>
            </div>
          </Grid>
          <Grid item md={1} sx={{ textAlign: 'end' }}>
            <Typography
              variant='subtitle2'
              fontWeight={500}
              sx={{ cursor: 'pointer', color: '#13628C', pr: 1 }}
              onClick={toggleVisibilityText}
            >
              {visibilityStatus == false ? 'Show' : 'Hide'}
            </Typography>
          </Grid>
        </Grid>

        {productDetails.inCartType !== 'alternative' && (
          <Grid item container md={12} alignItems={'center'} pt={1} pb={1}>
            <TextField
              fullWidth
              multiline
              rows={visibilityStatus ? 1 : 3}
              variant='outlined'
              placeholder='Insert Buyer Requirements'
              InputProps={{
                sx: {
                  minHeight: visibilityStatus ? undefined : 60,
                  padding: '10px',
                  border: requirementsFieldError ? '1px solid red' : 'none',
                },
                endAdornment: (
                  <Typography
                    variant='subtitle2'
                    fontWeight={500}
                    sx={{ cursor: 'pointer', color: '#13628C' }}
                    onClick={toggleVisibilityText}
                  >
                    {visibilityStatus == false ? 'Hide' : 'Show'}
                  </Typography>
                ),
              }}
              value={productDetails.additional_requirements}
              onChange={e => {
                handleChangeRequirements(e.target.value);
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const UnlinkCatalogueItemButton = ({ productDetails }: { productDetails: Product }) => {
  const { unlinkCatalogueItem } = useManageCatalogueSlice();
  const { t } = useTranslation();

  const [loadingItemId, setLoadingItemId] = useState<number | null>(null);
  const unlinkProductPopper = usePopup();

  const handleUnlinkCatalogueItem = (catalogueItemId: number) => {
    unlinkProductPopper.handleClose();
    setLoadingItemId(catalogueItemId);
    unlinkCatalogueItem(catalogueItemId, {
      onSuccess: () => {
        NotifySuccess('Unlinked Successfully');
        setLoadingItemId(null);
      },
      onError: () => {
        NotifyError(t('notifications.server_error'));
        setLoadingItemId(null);
      },
    });
  };

  if (
    productDetails?.catalog_item?.status !== procurementStatus.PUBLISHED &&
    productDetails?.catalog_item?.status !== procurementStatus.UNPUBLISHED
  ) {
    return (
      <StyledLoadingButton
        style={{
          backgroundColor: 'rgba(246, 217, 222, 0.4)',
          color: '#FF4D4D',
          width: '48px',
          '&:hover': { backgroundColor: 'rgba(246, 217, 222, 0.4)' },
        }}
        variant='contained'
        translateKey={'delete'}
      />
    );
  }

  return (
    <React.Fragment>
      {productDetails?.catalog_item?.can_unlink ? (
        <Tooltip
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, px: 1 }}>
              <LinkOffIcon fontSize='small' color='error' />
              <Typography variant="body2" color="inherit" fontWeight={400}>
                {
                  productDetails?.category_kind == ProductCategoryKind.PRODUCT
                    ? 'Unlink Product from my catalogue'
                    : 'Unlink Service from my catalogue'
                }
              </Typography>
            </Box>
          }
          placement='bottom'
          arrow
          componentsProps={{
            tooltip: {
              sx: { backgroundColor: '#FFF', color: 'text.disabled', boxShadow: 1, fontSize: '12px' },
            },
            arrow: {
              sx: {
                color: '#FFF',
                '&::before': {
                  border: '1px solid #CCC',
                },
              },
            },
          }}
        >
          <span>
            <StyledLoadingButton
              style={{
                backgroundColor: 'rgba(246, 217, 222, 0.4)',
                color: '#FF4D4D',
                width: '48px',
                '&:hover': { backgroundColor: 'rgba(246, 217, 222, 0.4)' },
              }}
              variant='contained'
              onClick={unlinkProductPopper.handleOpen}
              translateKey={'unlink'}
              loading={loadingItemId === productDetails.catalog_item.id}
            />
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          title={
            <Typography variant="body2" color="inherit" fontWeight={400}>
              {
                productDetails?.category_kind == ProductCategoryKind.PRODUCT
                  ? 'This product cannot be unlinked because it is listed in an active form'
                  : 'This Service cannot be unlinked because it is listed in an active form'
              }
            </Typography>
          }
          placement='bottom'
          arrow
          componentsProps={{
            tooltip: {
              sx: { backgroundColor: '#FFF', color: 'text.disabled', boxShadow: 1, fontSize: '12px' },
            },
            arrow: {
              sx: {
                color: '#FFF',
                '&::before': {
                  border: '1px solid #CCC',
                },
              },
            },
          }}
        >
          <div style={{ display: 'inline-block' }}>
            <Button
              variant='contained'
              disabled
              style={{ backgroundColor: 'rgba(246, 217, 222, 0.2)', color: 'rgba(255, 77, 77, 0.3)', width: '48px' }}
            >
              Unlink
            </Button>
          </div>
        </Tooltip>
      )}

      <ConfirmPopup
        open={unlinkProductPopper.isOpen}
        handleConfirm={() => handleUnlinkCatalogueItem(productDetails?.catalog_item.id)}
        titleKey={'Unlink Listing'}
        subTitleKey={t('popups.subTitles.unlink_one_listing')}
        buttonTitleKey={t('buttons.unlink')}
        handleClose={unlinkProductPopper.handleClose}
        icon={<LinkOffIcon color='error' fontSize='medium' />}
      />
    </React.Fragment>
  );
};



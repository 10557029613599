import { Close } from '@mui/icons-material';
import { Button, Checkbox, Dialog, FormControlLabel, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { ReactNode, useEffect, useState } from 'react';
import { DeleteIcon } from 'src/ui/icons/delete';

export type ConfirmPopupProps = {
  handleClose: () => void;
  handleConfirm: Function;
  open: boolean;
  titleKey: string;
  buttonTitleKey: string;
  subTitleKey?: string | ReactNode;
  hideDeleteIcon?: boolean
  showSuccessAfterConfirm?: boolean
  successAfterConfirmTitle?: string
  icon?: ReactNode
  handleCloseAfterSuccess?: () => void
  showCheckbox?: boolean
  checkboxTitle?: string
  color?: string
};

export const ConfirmPopup: React.VFC<ConfirmPopupProps> = ({
  handleClose,
  handleConfirm,
  open,
  titleKey,
  buttonTitleKey,
  subTitleKey,
  hideDeleteIcon,
  showSuccessAfterConfirm,
  successAfterConfirmTitle,
  icon,
  handleCloseAfterSuccess,
  showCheckbox,
  checkboxTitle,
  color
}) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      sx={{ maxWidth: '420px', margin: 'auto' }}
      disableScrollLock
    >
      {!showSuccessAfterConfirm ? <ConfirmDialogContent
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        titleKey={titleKey}
        buttonTitleKey={buttonTitleKey}
        subTitleKey={subTitleKey}
        hideDeleteIcon={hideDeleteIcon}
        icon={icon}
        checkboxTitle={checkboxTitle}
        showCheckbox={showCheckbox}
        color={color}
      />

        : <ConfirmationSuccessDialogContent
          handleClose={handleClose}
          handleCloseAfterSuccess={handleCloseAfterSuccess}
          successAfterConfirmTitle={successAfterConfirmTitle} />
      }
    </Dialog>
  );
};

type ConfirmationSuccessDialogContentProps = {
  handleClose: () => void;
  successAfterConfirmTitle?: string
  handleCloseAfterSuccess?: () => void
};
const ConfirmationSuccessDialogContent: React.VFC<ConfirmationSuccessDialogContentProps> = ({
  handleClose,
  successAfterConfirmTitle,
  handleCloseAfterSuccess
}) => {
  const handleCloseConfirmationSuccessDialog = () => {
    handleClose()
    if (handleCloseAfterSuccess) handleCloseAfterSuccess()
  }
  useEffect(() => {
    setTimeout(handleCloseConfirmationSuccessDialog, 3000)
  }, []);

  return (
    <>
      <Grid container sx={{ p: 2 }}>
        <Grid item md={12} textAlign='center'>
          <DeleteIcon />
        </Grid>
        <Grid item md={12} textAlign='center' pb={1} width='278px'>
          <Typography variant='overline' color="error" fontWeight="700" >
            {successAfterConfirmTitle}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

type ConfirmDialogContentProps = {
  handleClose: () => void;
  handleConfirm: Function;
  titleKey: string;
  buttonTitleKey: string;
  subTitleKey?: string | ReactNode;
  hideDeleteIcon?: boolean;
  icon?: ReactNode
  showCheckbox?: boolean
  checkboxTitle?: string
  color?: string
};
const ConfirmDialogContent: React.VFC<ConfirmDialogContentProps> = ({
  handleClose,
  handleConfirm,
  titleKey,
  buttonTitleKey,
  subTitleKey,
  hideDeleteIcon,
  icon,
  showCheckbox,
  checkboxTitle,
  color
}) => {
  const { t } = useTranslation();
  const [checkboxValue, setcheckboxValue] = useState(false)
  return (
    <>
      <Grid item md={12} container justifyContent={"flex-end"} p={1}>
        <IconButton edge='start' color='inherit' onClick={handleClose}>
          <Close sx={{ color: 'text.secondary', height: 18, width: 18, }} />
        </IconButton>
      </Grid>
      <Grid container sx={{ mt: -6, p: 2 }}>
        {!hideDeleteIcon && <Grid item md={12} textAlign='center'>
          {icon ? icon : <DeleteIcon fontSize='medium' />}
        </Grid>}
        <Grid item md={12} textAlign='center' pb={1}>
          <Typography variant='overline' sx={{ color: color ? color : "#FF4D4D" }} fontWeight="700">
            {titleKey}
          </Typography>
        </Grid>
        {subTitleKey && <Grid item md={12} textAlign='center' pb={2}>
          <Typography variant='body2' color="text.disabled">
            {subTitleKey}
          </Typography>
        </Grid>}
        {showCheckbox && <Grid container item md={12} justifyContent='center' mb={2}>
          <FormControlLabel control={<Checkbox />} sx={{ color: 'text.disabled' }} label={checkboxTitle} value={checkboxValue} onChange={(value) => setcheckboxValue(value.target.value)} />
        </Grid>}
        <Grid item container md={12}>
          <Grid item md={6} pr={0.5}>
            <Button
              onClick={() => handleClose()}
              variant='contained'
              fullWidth
              color='warning'
            >
              {t('buttons.cancel')}
            </Button>
          </Grid>
          <Grid item md={6} pl={0.5}>
            <Button
              onClick={() => showCheckbox ? handleConfirm(checkboxValue) : handleConfirm()}
              variant='contained'
              fullWidth
              sx={{ bgcolor: color ? color : "#FF4D4D", '&:hover': { bgcolor: color ? color : "#FF4D4D" } }}
            >
              {buttonTitleKey}
            </Button>
          </Grid>
        </Grid>
      </Grid >
    </>
  )
}
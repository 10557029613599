import { SvgIcon, SvgIconProps } from '@mui/material';

export const ChatErrorIcon: React.FC<SvgIconProps> = props => (
    <SvgIcon
        viewBox="0 0 20 20"
        fontSize="small"
        {...props}
    >
        <path d="M7.01432 10.8789L9.18099 8.71224L11.3477 10.8789L12.5143 9.71224L10.3477 7.54557L12.5143 5.3789L11.3477 4.21224L9.18099 6.3789L7.01432 4.21224L5.84765 5.3789L8.01432 7.54557L5.84765 9.71224L7.01432 10.8789ZM0.847656 17.5456V2.54557C0.847656 2.08724 1.01085 1.69488 1.33724 1.36849C1.66363 1.0421 2.05599 0.878906 2.51432 0.878906H15.8477C16.306 0.878906 16.6983 1.0421 17.0247 1.36849C17.3511 1.69488 17.5143 2.08724 17.5143 2.54557V12.5456C17.5143 13.0039 17.3511 13.3963 17.0247 13.7227C16.6983 14.049 16.306 14.2122 15.8477 14.2122H4.18099L0.847656 17.5456ZM3.47266 12.5456H15.8477V2.54557H2.51432V13.4831L3.47266 12.5456Z" fill="#FF4D4D" />
    </SvgIcon>
);

import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import { ProductCategoryKind, ProductCategoryKindType } from "@/models";

type IProps = {
  selectedType: ProductCategoryKindType;
  onChange: (newValue: ProductCategoryKindType) => void;
  disabled?: boolean;
};

const ProductServiceButtonGroup = ({ selectedType, onChange, disabled }: IProps) => {
  return (
    <ButtonGroup>
      {[
        { label: "Product", value: ProductCategoryKind.PRODUCT },
        { label: "Service", value: ProductCategoryKind.SERVICE },
      ].map(({ label, value }) => (
        <Button
          key={value}
          type="button"
          variant={selectedType === value ? "contained" : "outlined"}
          onClick={() => {
            if (selectedType !== value) {
              onChange(value);
            }
          }}
          sx={{
            borderRadius: "5px",
            width: "120px",
            bgcolor: selectedType === value ? "#0F5D85" : "white",
            color: selectedType === value ? "white" : "#0F5D85",
            border: selectedType === value ? "none" : "1px solid #0F5D85",
            "&:hover": {
              bgcolor: selectedType === value ? "#005FCC" : "#E0EFFF",
            },
            transition: "all 0.2s ease-in-out",
          }}
          disabled={disabled && selectedType !== value}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ProductServiceButtonGroup;

export enum TableMapperType {
    request_for_quotes_buyer = "request_for_quotes_buyer",
    request_for_quotes_seller = "request_for_quotes_seller",
    quotes_buyer = "quotes_buyer",
    quotes_seller = "quotes_seller",
    quote_seller_products = "quote_seller_products",
    requisitions = "requisitions",
    catalog_draft = "catalog_draft",
    catalog = "catalog",
    projects_seller = "projects_seller",
    projects_buyer = "projects_buyer",
    seller_buyers = "seller_buyers",
    buyer_sellers = "buyer_sellers",
    buyer_comparison_table = "buyer_comparison_table",
    buyer_purchase_order = "buyer_purchase_order",
    seller_purchase_order = "seller_purchase_order",

    // As a buyer -- Seller Company Tabs
    seller_company_request_for_quotes = "seller_company_request_for_quotes",
    seller_company_comparison_tables = "seller_company_comparison_tables",
    seller_company_quotes = "seller_company_quotes",
    seller_company_purchase_order = "seller_company_purchase_order",

    // As a seller -- Buyer Company Tabs
    buyer_company_request_for_quotes = "buyer_company_request_for_quotes",
    buyer_company_quotes = "buyer_company_quotes",
    buyer_company_purchase_order = "buyer_company_purchase_order",

    // As a buyer -- Project Tabs
    project_tables_requisitions = "project_tables_requisitions",
    project_tables_request_for_quotes_buyer = "project_tables_request_for_quotes_buyer",
    project_tables_buyer_comparison_table = "project_tables_buyer_comparison_table",
    project_tables_quotes_buyer = "project_tables_quotes_buyer",
    project_tables_buyer_purchase_order = "project_tables_buyer_purchase_order",

    // As a seller -- Project Tabs
    project_tables_request_for_quotes_seller = "project_tables_request_for_quotes_seller",
    project_tables_quotes_seller = "project_tables_quotes_seller",
    project_tables_seller_purchase_order = "project_tables_seller_purchase_order",
}

export enum FilterKind {
    DATE = "DATE",
    MULTI_SELECT_STATUS = "MULTI_SELECT_STATUS",
    MULTI_SELECT = "MULTI_SELECT",
    RANGE = "RANGE"
}

export enum ReadStatus {
    PARTIALLY_READ = "PARTIALLY_READ",
    PENDING = "PENDING",
    READ = "READ"
}

export type TableFilters = {
    kind: FilterKind;
    type: string;
    title: string;
    value: TableFilterItem[];
}

export type TableFilterItem = {
    id: number;
    name: string;
    checked?: boolean;
}

export type TableSelectedFilters = {
    type: string;
    kind: string;
    value?: string | TableFilterItem[];
    date_value?: string;
    array_value?: | TableFilterItem[];
}
import { CancelIcon } from '@/icons';
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';
import { Box, Grid, Typography } from '@mui/material';

const CommonReasonsForRejection = [
  'Misleading tittles',
  'Poor quality images/logos',
  'Incorrect product categorization',
  'Lack of necessary product or brand information',
  'Non - compliant product or brand descriptions',
  'Restricted or Prohibited items',
  'Violation of intellectual property rights or copyright laws',
  'Duplicate listings or brands',
];

const DirectoryApprovalProcessContent = () => {
  return (
    <>
      <Box sx={{ backgroundColor: '#70B0FF1A', border: '1px solid #EAEEF6' }} borderRadius={'8px'} p={3} mt={1}>
        <NotListedLocationOutlinedIcon sx={{ color: 'primary.main' }} />
        <Typography fontWeight={400} fontSize={15} color='#000'>
          Sellers can add or suggest edits to ANY product or brand inside the directory. However, this is subject for
          approval from the eProcurement admin prior to publishing these additions or edits. Here is an overview of our
          approval (or rejection) process.
        </Typography>
      </Box>

      <Box
        sx={{ backgroundColor: '#fff' }}
        borderRadius={'8px'}
        p={5}
        mt={4}
        mb={2}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <img
          src='/assets/images/support/directory-approval-process.png'
          alt='Directory Approval Process'
          style={{ marginInline: 'auto', height: 'auto', width: '80%', objectFit: 'contain' }}
        />
      </Box>

      <Box sx={{ backgroundColor: '#EF44440F', border: '1px solid #EF4444' }} borderRadius={'8px'} p={3}>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <CancelIcon />
          <Typography fontSize={17} color='#3A3E50' fontWeight={700}>
            Common Reasons for Rejection
          </Typography>
        </Box>
        <Grid container mt={2} pl={1}>
          {CommonReasonsForRejection.map((reason, index) => (
            <Grid key={index} md={6}>
              <Typography fontWeight={400} fontSize={15} color='#000000'>
                <li>{reason}</li>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default DirectoryApprovalProcessContent;

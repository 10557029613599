import { AuthenticateStatus, procurementStatus } from "@/config"
import { useTranslation } from 'next-i18next';
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material"
import React from "react"
import LinkIcon from '@mui/icons-material/Link';
import { Link } from '@/components';
import { LinkType } from "@/models";
import { getUrlBasedOnFormType } from "src/logic/services/form-types-navigation";
import { sm } from "src/site-map";
import { useUserData } from "@/zustand";

interface RelatedFormsListProps {
    forms: any[]
}

const mainStatus = [procurementStatus.PARTIALLY_QUOTES_RECEIVED, procurementStatus.QUOTES_RECEIVED, procurementStatus.EDITING, procurementStatus.QUOTE_RECEIVED, procurementStatus.PO_RECEIVED, procurementStatus.RFQ_RECEIVED, procurementStatus.DRAFT, procurementStatus.PARTIALLY_APPROVED, procurementStatus.PARTIALLY_APPROVED_BY_ME, procurementStatus.PENDING, procurementStatus.QUOTE_GENERATED, procurementStatus.PARTIALLY_SENT_TO_SELLERS, procurementStatus.READY]
const dangerStatus = [procurementStatus.REVOKED, procurementStatus.DECLINED, procurementStatus.REJECTED, procurementStatus.REJECTED_BY_ME, procurementStatus.CANCELED, procurementStatus.RECALLED, procurementStatus.PARTIALLY_DECLINED]
const warningStatus = [procurementStatus.PENDING_APPROVAL, procurementStatus.PENDING_MY_APPROVAL, procurementStatus.SENT_FOR_APPROVAL, procurementStatus.REQUESTED, "PENDING_QUOTES"]
const successStatus = [procurementStatus.PO_GENERATED, procurementStatus.PUBLISHED, procurementStatus.APPROVED, procurementStatus.CONFIRMED, procurementStatus.APPROVED_BY_ME, procurementStatus.SENT_TO_SELLERS, procurementStatus.SENT_TO_SELLER, procurementStatus.SENT_TO_BUYER, procurementStatus.CONVERTED_TO_RFQ, procurementStatus.PARTIALLY_APPROVED, procurementStatus.PARTIALLY_SENT_TO_SELLERS, procurementStatus.PARTIALLY_CONFIRMED]
const statuses = {}
mainStatus.forEach(key => statuses[key] = 'main');
dangerStatus.forEach(key => statuses[key] = 'danger');
warningStatus.forEach(key => statuses[key] = 'warning');
successStatus.forEach(key => statuses[key] = 'success');

export const RelatedFormsList: React.VFC<RelatedFormsListProps> = ({ forms }) => {
    const { t } = useTranslation();
    const isGuest = useUserData().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;

    const getTheURL = (type: string, id: string, bundleId?: string) => {
        const url = getUrlBasedOnFormType(type as LinkType, id);
        if (type === LinkType.RFQ_OF_BUNDLE) {
            return { pathname: sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(bundleId).url, query: { rfqId: id } }
        }
        else if (type === LinkType.PO_OF_BUNDLE) {
            return { pathname: sm.portals.buyer.procurement.purchaseOrders.bundlePurchaseOrderId(bundleId).url, query: { 'purchase-order-id': id } }
        }
        else if (type === LinkType.RFQ_SELLER && isGuest) {
            return sm.portals.external.seller.procurement.requestForQuotes.requestForQuote(id).url;
        }
        else if (type === LinkType.QUOTE_SELLER && isGuest) {
            return sm.portals.external.seller.procurement.quotes.quoteId(id).url;
        }
        else if (type === LinkType.PO_SELLER && isGuest) {
            return sm.portals.external.seller.procurement.purchaseOrders.purchaseOrderId(id).url;
        }
        return url;
    };

    return (
        <>
            <Grid container p={2} alignItems={'center'} justifyContent={'flex-start'} sx={{ bgcolor: 'rgb(19, 98, 140, 0.1)', borderRadius: '8px', p: 1 }}>
                <LinkIcon color="primary" />
                <Typography color="primary" variant="body2" fontWeight={500} sx={{ ml: 'auto', mr: 'auto' }}>    Linked Forms   </Typography>
            </Grid>

            <Grid container py={2} sx={{ width: '300px' }}>
                {forms?.map(form => (
                    <Grid container justifyContent={'space-between'} gap={3} py={0.5}>
                        <Link underline='hover' href={getTheURL(form.form_type, form.id, form.bundle?.id)}>
                            <Typography variant='subtitle2' color='primary' sx={{ fontWeight: 700, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {form.name}
                            </Typography>
                        </Link>
                        <Typography variant="subtitle2"
                            color={statuses[form.status] == 'danger' ? 'error' : statuses[form.status] == 'warning' ? 'secondary' : statuses[form.status] == 'success' ? 'success.dark' : 'text.disabled'}>
                            {form.status && t('chip.' + form.status)}
                        </Typography>
                    </Grid>
                ))}
            </Grid>


        </>
    )
}
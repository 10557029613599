import { productManagerEnv, ProductManagerPopupStatus } from '@/config';
import { Seller } from '@/models';
import { NotifySuccess } from '@/services';
import { useProcurementStore } from '@/zustand';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { DescPopup, Loading } from 'src/ui/components/shared';
import { CreatePOWithProduct } from '../create-with-product';
import createPoInitialValues from '../create-with-product/initialValues';
import { SimilarProductPopup } from '../po-similar-product';
import { SelectPurchaseOrderPoppup } from '../select-purchase-order-popup';

interface props {
  sellerId?: string;
  selectedSeller?: Seller;
}
export const PurchaseOrderPopup: FC<props> = props => {
  const [popupState, setpopupState] = useState(0);
  const changePopupState = (val: number) => setpopupState(val);
  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const closePopup = () => {
    actions.setProductManagerPopupStatus(null);
    actions.setCandidateSellerRelatedToForm(null);
    actions.setCandidateProductToAdd(null);
  };
  return (
    <DescPopup
      open={
        data.productManagerPopupStatus === ProductManagerPopupStatus.ADD_SIMILAR_PRODUCT ||
        data.productManagerPopupStatus === ProductManagerPopupStatus.SELECT_PO ||
        data.productManagerPopupStatus === ProductManagerPopupStatus.CREATE_PO
      }
      handleClose={closePopup}
      hasBackButton={popupState > 0}
      backButtonAction={() => changePopupState(popupState - 1)}
    >
      <PopupContent
        popupState={popupState}
        changePopupState={changePopupState}
        sellerId={props.sellerId}
        closePopup={closePopup}
        selectedSeller={props.selectedSeller}
      />
    </DescPopup>
  );
};

const PopupContent: FC<{
  popupState: number;
  changePopupState: (val: number) => void;
  closePopup: () => void;
  sellerId?: string;
  selectedSeller?: Seller;
}> = props => {
  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const { getPurchaseOrderFromRelatedData } = useProcurementStore(state => state.purchaseOrderBuyerDetailsSliceActions);
  const { individualForms, loadingIndividualForms } = useProcurementStore(
    state => state.purchaseOrderBuyerDetailsSliceProperties
  );
  const { t } = useTranslation();

  const { purchaseOrderBuyerDetailsSliceActions } = useProcurementStore();
  const isCreatingPo = useLoadingState();
  const handleCreatePO = () => {
    isCreatingPo.startLoading();
    const product = data.candidateProductToAdd;
    const selectedSeller = props.selectedSeller;

    if (!product || !selectedSeller) return;

    const values = createPoInitialValues(product.id, product.quantity, selectedSeller);
    purchaseOrderBuyerDetailsSliceActions.create(
      {
        projectId: '',
        projectName: '',
        sellerId: values.seller_id,
      },
      newPurchaseOrder => {
        actions.setDetails(newPurchaseOrder, productManagerEnv.purchase_order);
        actions.addProduct(product);
        NotifySuccess(t('notifications.create_form', { formName: newPurchaseOrder.name }));
        actions.setProductManagerPopupStatus(null);
      }
    );
  };

  useEffect(() => {
    if (!props.sellerId)
      getPurchaseOrderFromRelatedData(
        data.candidateProductToAdd?.id,
        data.candidateSellerRelatedToPo?.seller.id,
        individualForms => {
          const selectedSeller = props.selectedSeller;
          if (individualForms.meta.total > 0) return;
          if (selectedSeller) handleCreatePO();
          else actions.setProductManagerPopupStatus(ProductManagerPopupStatus.CREATE_PO);
        }
      );
  }, []);

  return (
    <>
      {loadingIndividualForms || individualForms === undefined || isCreatingPo.isLoading ? (
        <Loading fullHeight />
      ) : (
        <>
          {data.productManagerPopupStatus === ProductManagerPopupStatus.SELECT_PO ? (
            <SelectPurchaseOrderPoppup
              sellerId={data.candidateSellerRelatedToPo?.seller.id}
              popupState={props.popupState}
              changePopupState={props.changePopupState}
              product={data.candidateProductToAdd}
              selectedSeller={props.selectedSeller}
            />
          ) : data.productManagerPopupStatus === ProductManagerPopupStatus.ADD_SIMILAR_PRODUCT ? (
            <SimilarProductPopup
              closePopup={props.closePopup}
              sellerId={props.sellerId}
              product={data.candidateProductToAdd}
            />
          ) : (
            <CreatePOWithProduct
              product={data.candidateProductToAdd}
              selectedSeller={props.selectedSeller}
              disableAddToExistingPoButton={individualForms.meta.total === 0}
            />
          )}
        </>
      )}
    </>
  );
};

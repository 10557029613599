import { productManagerEnv } from '@/config';
import { ProcurementModelDetails } from '@/models';
import { useProcurementStore } from '@/zustand';
import { Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import useScrollObserver from 'src/logic/hooks/use-scroll-observer';
import { useGetEditablePurchaseOrders } from 'src/logic/services/procurement/purchase-order-buyer/queries';
import { sm } from 'src/site-map';
import { Loading } from 'src/ui/components/shared';

export const PurchaseOrderPopperContent: FC<{
  handleCloseFormPopper: () => void;
}> = ({ handleCloseFormPopper }) => {
  const { push } = useRouter();
  const {
    data: purchaseOrdersData,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetEditablePurchaseOrders();
  const purchase_orders = React.useMemo(() => {
    if (!purchaseOrdersData) return [];
    return purchaseOrdersData.pages.flatMap(page => page.data).map(po => po.purchase_order);
  }, [purchaseOrdersData]);

  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const toggleForm = (form: ProcurementModelDetails) => {
    actions.toggleForm(
      form?.id,
      'purchaseOrderBuyerDetailsSliceActions',
      productManagerEnv.purchase_order,
      purchase_orders
    );
    handleCloseFormPopper();
    push(sm.catalogue.index.url);
  };

  const { observerRef, containerRef } = useScrollObserver({
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage?.();
      }
    },
  });

  return (
    <>
      <Grid container item xs={12} mb={1} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography fontWeight={700} variant='overline' sx={{ color: '#242833' }}>
            {t('texts.draft_purchase_orders')}
          </Typography>
        </Grid>
        <Grid item>
          <Button sx={{ fontWeight: 700 }} onClick={() => push(sm.portals.buyer.procurement.purchaseOrders.index.url)}>
            {t('buttons.view_all')}
          </Button>
        </Grid>
      </Grid>
      <Stack maxHeight='240px' width='100%' ref={containerRef}>
        {isLoading ? (
          <div style={{ minHeight: '100px' }}>
            <Loading fullHeight={false} />
          </div>
        ) : (
          <>
            {purchase_orders?.map(form => {
              return (
                <Grid item xs={12} key={form.id}>
                  {data.formDetails?.id == form.id ? (
                    <Stack
                      p={1}
                      sx={{
                        backgroundColor: 'info.contrastText',
                        borderRadius: '4px',
                      }}
                    >
                      <Typography variant='body2' fontWeight={700}>
                        {form.name}
                      </Typography>
                      <Typography variant='subtitle2' color='info.main' lineHeight={1.2} fontWeight={400}>
                        {form?.seller_details?.name}, {form.sender_project?.name}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack p={1} sx={{ cursor: 'pointer' }} onClick={() => toggleForm(form)}>
                      <Typography variant='body2'>{form.name}</Typography>
                      <Typography variant='subtitle2' color='text.third' lineHeight={1.2}>
                        {form?.seller_details?.name}, {form.sender_project?.name}
                      </Typography>
                    </Stack>
                  )}
                </Grid>
              );
            })}
            {hasNextPage && (
              <div
                style={{ marginBlock: '.5rem', opacity: isFetchingNextPage ? 1 : 0, transition: 'opacity 200ms' }}
                ref={observerRef}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '.5rem' }}>
                  <CircularProgress size={'1rem'} />
                  <Typography color='#8B8D98' fontWeight={400} fontSize={13}>
                    Loading Purchase Orders...
                  </Typography>
                </div>
              </div>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

import { useDebounce } from '@/hooks';
import { TextField, Typography } from '@mui/material';
import React from 'react';
import { TextFieldTermItem } from 'src/logic/models/procurement/general-terms';

type IProps = {
  item: TextFieldTermItem;
  onChange: (item: TextFieldTermItem, newValue: string) => void;
};

const TextFieldTermComponent = ({ item, onChange }: IProps) => {
  const [liveValue, setLiveValue] = React.useState<string>(item.value);
  useDebounce(() => onChange(item, liveValue), 400, [liveValue]);

  return (
    <div style={{ marginBlock: '1rem' }}>
      <Typography color='#3A3E50' fontSize={13} fontWeight={400} mb={1}>
        {item.name}
      </Typography>

      <div>
        <TextField
          id={`${item.id}`}
          variant='outlined'
          placeholder={item.name}
          value={liveValue}
          onChange={e => setLiveValue(e.target.value)}
          autoFocus
          fullWidth
        />
      </div>
    </div>
  );
};

export default TextFieldTermComponent;

import React, { memo } from 'react';

import { ArrowBack, ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, BreadcrumbsProps, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Link } from '..';

export type FixedStyledBreadcrumbsProps = {
  items: { title: string; url: string, textOnly?: string }[];
  showBackButton?: boolean;
  maxItems?: number;
} & BreadcrumbsProps;
export const FixedStyledBreadcrumbs: React.VFC<FixedStyledBreadcrumbsProps> = memo(
  ({ maxItems, items, showBackButton }) => {
    const { query, back } = useRouter();
    const { t } = useTranslation();

    return (
      <Grid container pt={2}>
        {showBackButton && (
          <Button
            // onClick={() => push({
            //   pathname: path ?? des,
            // })}
            onClick={back}
            startIcon={<ArrowBack sx={{ fontSize: '19px', mr: '-1px' }} />}
            sx={{ color: 'text.primary', mr: 1, alignItems: 'center', height: '20px' }}
          >
            <Typography variant='subtitle2'>{t('buttons.go_back')}</Typography>
          </Button>
        )}
        <Breadcrumbs
          maxItems={maxItems}
          separator={<ChevronRight sx={{ mx: -1, fontSize: '20px !important', pt: -1 }} />}
        >
          {items?.map((el, i) => {
            const isLastItem = i === items.length - 1;
            
            return (
              <React.Fragment key={el.title}>
                {(!isLastItem && !el.textOnly) ? (
                  <Link
                    key={el.title}
                    href={{
                      pathname: el.url,
                      query: { ...query },
                    }}
                    underline='hover'
                  >
                    <Typography color='text.third' variant='subtitle2' textTransform='capitalize'>
                      {el.title}
                    </Typography>
                  </Link>
                ) : (
                  <Typography color='primary.main' variant='subtitle2' textTransform='capitalize'>
                    {el.title}
                  </Typography>
                )}
              </React.Fragment>
            );
          })}
        </Breadcrumbs>
      </Grid>
    );
  }
);

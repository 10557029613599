import { useTranslation } from 'next-i18next';
import { useProcurementStore } from "@/zustand"
import { Button, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { DescPopup, MultilineTextField, StyledLoadingButton } from "src/ui/components/shared"
import { useRouter } from "next/router";
import { BundleRFQ, BundleRFQDetails } from '@/models';
import { MarkChatReadOutlined } from '@mui/icons-material';
import { sm } from 'src/site-map';
import { ProcurementButtons } from '@/config';

type SendForApprovalProps = { bundleRFQDetails: BundleRFQDetails | null }

export const SendForApproval: FC<SendForApprovalProps> = ({ bundleRFQDetails }) => {
    const { t } = useTranslation();
    const { push } = useRouter()
    const [comment, setComment] = useState(null);
    // const [showSendForApprovalPopup, setShowSendForApprovalPopup] = useState(false)
    const { sendForApproval, setSendForApprovalPopup, setShowAfterSendForApprovalPopup } = useProcurementStore(state => state.bundleRFQDetailsSliceActions)

    const { sendForApprovalPopup, showAfterSendForApprovalPopup, customLoadingButton } = useProcurementStore(state => state.bundleRFQDetailsSliceProperties)


    const closeSendForApprovalPopup = () => { setSendForApprovalPopup(false); setComment(null) };
    const closeAfterSendForApprovalPopup = () => { setShowAfterSendForApprovalPopup(false); setComment(null) };

    function handleChange(data: string) { return setComment(data) }

    const SendForApprovalAction = () => {
        let bundleRFQId = bundleRFQDetails?.bundleRFQModel?.id
        sendForApproval(bundleRFQId, comment)
    }

    return (
        <>
            {bundleRFQDetails?.canSendForApprovals && <Button variant="contained" sx={{ mr: 1 }} onClick={() => setSendForApprovalPopup(true)} >
                {t("buttons.send_for_approval")}
            </Button>}

            <DescPopup open={sendForApprovalPopup} handleClose={closeSendForApprovalPopup}>
                <Grid container gap={2} width={300}>
                    <Grid item md={12} textAlign='center'>
                        <MarkChatReadOutlined sx={{ color: 'primary.main', fontSize: 40 }} />
                        <Grid item md={12} textAlign='center'>
                            <Typography variant='overline' color='primary.main' fontWeight="700" my={1}>
                                {t('popups.titles.send_for_approval')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container gap={1}>
                        <Grid item container gap={1} md={12}>
                            <Typography fontWeight={400} sx={{ color: 'text.third' }}>{t('input_fields.add_comment_optional')}</Typography>
                            <MultilineTextField value={comment} placeholder={t('input_fields.type_your_comment')} handleValueChange={val => handleChange(val)} />
                            <StyledLoadingButton variant="contained" onClick={SendForApprovalAction} translateKey="send_for_approval" loading={customLoadingButton == ProcurementButtons.SEND_BUNDLE_RFQ_FOR_APPROVAL} />
                        </Grid>
                    </Grid>
                </Grid>
            </DescPopup>

            {/* <DescPopup open={showAfterSendForApprovalPopup} handleClose={closeAfterSendForApprovalPopup}>
                <Grid container md={12} gap={2} width={300} p={2}>
                    <Grid item md={12} textAlign='center'>
                        <MarkChatReadOutlined sx={{ color: 'success.dark', fontSize: 40 }} />
                        <Grid item md={12} textAlign='center'>
                            <Typography variant='overline' color='success.dark' fontWeight={700} my={1}>
                                {t('popups.titles.sent_for_approval')}
                            </Typography>
                        </Grid>
                        <Grid item container gap={1} md={12}>
                            <Typography variant='body2' color='text.disabled' fontWeight={400} my={1}>
                                {t('popups.subTitles.sent_for_approval')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Button variant="outlined" sx={{ color: 'text.disabled', fontWeight: 500 }} fullWidth onClick={closeAfterSendForApprovalPopup}>{t('buttons.view_bundle')}</Button>
                    </Grid>
                    <Grid item md={5}>
                        <Button variant="contained" fullWidth onClick={() => {
                            closeAfterSendForApprovalPopup()
                            push({ pathname: sm.portals.buyer.procurement.requestForQuotes.index.url })
                        }}>{t('buttons.my_RFQs')}</Button>
                    </Grid>
                </Grid>
            </DescPopup> */}
        </>
    )
} 
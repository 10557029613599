import { productManagerEnv } from '@/config';
import { ProcurementModelDetails } from '@/models';
import { useProcurementStore } from '@/zustand';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useGetEditableCatalogs } from 'src/logic/services/seller-catalog/queries';
import { sm } from 'src/site-map';
import { Loading } from 'src/ui/components/shared';

export const CatalogPopperContent: FC<{
  handleCloseFormPopper: () => void;
}> = ({ handleCloseFormPopper }) => {
  const { push } = useRouter();
  const { data: catalog, isFetching } = useGetEditableCatalogs();

  const { data, actions } = useProcurementStore(
    state => state.productFormManagerProperties
  );
  const toggleForm = (form: ProcurementModelDetails) => {
    actions.toggleForm(
      form?.id,
      'catalogListSliceActions',
      productManagerEnv.catalog,
      catalog
    );
    handleCloseFormPopper();
    push(sm.catalogue.index.url);
  };

  return (
    <>
      <Grid container item xs={12} mb={1} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography fontWeight={700} variant='overline' sx={{ color: '#242833' }}>
            {t('texts.catalog_draft_lists')}
          </Typography>
        </Grid>
        <Grid item>
          <Button sx={{ fontWeight: 700 }} onClick={() => push(sm.portals.seller.products.index.url)}>
            {t('buttons.view_all')}
          </Button>
        </Grid>
      </Grid>
      <Stack maxHeight='240px' width='100%'>
        {isFetching ? (
          <div style={{ minHeight: '100px' }}>
            <Loading fullHeight={false} />
          </div>
        ) : (
          <>
            {catalog?.map(catalogItem => {
              return (
                <Grid item xs={12} key={catalogItem.id}>
                  {data.formDetails?.id == catalogItem.id ? (
                    <Stack
                      p={1}
                      sx={{
                        backgroundColor: 'info.contrastText',
                        borderRadius: '4px',
                      }}
                    >
                      <Typography variant='body2' fontWeight={700}>
                        {catalogItem.name}
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        color='info.main'
                        mt={-0.5}
                        fontWeight={400}
                      >
                        {catalogItem.number_of_products ?? 0} products
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack
                      p={1}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => toggleForm(catalogItem)}
                    >
                      <Typography variant='body2'>
                        {catalogItem.name}
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        color='text.third'
                        mt={-0.5}
                        fontWeight={400}
                      >
                        {catalogItem.number_of_products ?? 0} products
                      </Typography>
                    </Stack>
                  )}
                </Grid>
              );
            })}
          </>
        )}
      </Stack>
    </>
  );
};

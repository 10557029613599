import { ProductManagerEnv } from '@/config';
import { Product, ProductCategoryKindLabel } from '@/models';
import { useProcurementStore } from '@/zustand';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Divider, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { sm } from 'src/site-map';
import { ConfirmPopup, Loading } from 'src/ui/components/shared';
// import { ProductEditPrice } from '../../../../requisition';
import QuantityCounter from './quantity-counter';
import DebouncedEditPrice from './debounced-edit-price';
import { DeleteIcon } from 'src/ui/icons/delete';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';

type ProductRowType = {
  product: Product;
  canEditForm: boolean;
};
export const ProductRow: React.VFC<ProductRowType> = ({ canEditForm, product }) => {
  const { push } = useRouter();
  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const { changeProductQuantity, changeProductPrice } = useProcurementStore();
  function changeQuantity(quantity: number) {
    changeProductQuantity(quantity, product);
  }
  const [showDelPopup, setshowDelPopup] = useState(false);
  const handleOpenDelPopup = () => setshowDelPopup(true);
  const handleCloseDelPopup = () => setshowDelPopup(false);
  const removeProduct = () => {
    actions.removeProduct(product);
    handleCloseDelPopup();
  };
  const purchaseOrderEnv = data.environment?.type === ProductManagerEnv.purchase_order;
  const catalogEnv = data.environment?.type === ProductManagerEnv.catalog;
  const requisitionEnv = data.environment?.type === ProductManagerEnv.requisition;
  const quoteEnv = data.environment?.type === ProductManagerEnv.quote;
  const edit_bundle_rfq = data.environment?.type === ProductManagerEnv.edit_bundle_rfq;
  const edit_quote_from_scratch = data.environment?.type === ProductManagerEnv.edit_quote_from_scratch;
  const edit_po_from_scratch = data.environment?.type === ProductManagerEnv.edit_po_from_scratch;

  const handleChangePrice = (value: string) => changeProductPrice(product, value);

  const navigateToAlternatives = () => {
    push(
      sm.catalogue.products.alternatives(
        // product?.category.parent.parent.id,
        // product?.category.parent.id,
        // product?.category.id,
        product?.id
      ).url
    );
  };

  const redirectToProductDetailsPage = () => {
    return push(
      sm.catalogue.products.productId(
        // product?.category.parent.parent.id,
        // product?.category.parent.id,
        // product?.category.id,
        product?.id
      ).url
    );
  };

  const shouldRenderQuantityCounter =
    canEditForm &&
    (purchaseOrderEnv ||
      requisitionEnv ||
      quoteEnv ||
      edit_bundle_rfq ||
      edit_quote_from_scratch ||
      edit_po_from_scratch);
  const shouldRenderListedPriceLabel = catalogEnv;
  const shouldRenderEditPrice =
    purchaseOrderEnv || catalogEnv || quoteEnv || edit_quote_from_scratch || edit_po_from_scratch;
  const shouldRenderAddAlternatives = requisitionEnv || edit_bundle_rfq;

  const loadingProductsIds = data.loadingProductsIds;
  const productIsLoading = React.useMemo(() => loadingProductsIds.find(id => id === product.id), [loadingProductsIds]);

  return (
    <>
      <Grid container item md={12} gap={1} position='relative'>
        {productIsLoading && (
          <div
            style={{
              position: 'absolute',
              inset: 0,
              zIndex: 10,
              backgroundColor: 'rgba(255, 255, 255, .5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
            }}
          >
            <Loading fullHeight={false} />
          </div>
        )}
        <Grid item container md={12} alignItems='start'>
          <Grid item md={12}>
            <Typography fontSize={10} textTransform={'uppercase'} color='#C5C6CB' fontWeight={700}>
              {ProductCategoryKindLabel[product.category_kind]}
            </Typography>
          </Grid>
          <Grid item md={10}>
            <Typography
              sx={{
                cursor: 'pointer',
                '&:hover': { textDecoration: 'underline' },
              }}
              mt={0.25}
              variant='body2'
              fontWeight='700'
              color='text.disabled'
              onClick={redirectToProductDetailsPage}
              lineHeight={1.3}
            >
              {product.name} {product?.is_private_product && <PrivacyButton disableText zIndex={1202} />}
            </Typography>
          </Grid>
          {canEditForm && (
            <Grid item md={2} container justifyContent='flex-end'>
              <DeleteIcon onClick={handleOpenDelPopup} color='#FF4D4D' sx={{ fontSize: '18px', cursor: 'pointer' }} />
              <ConfirmPopup
                titleKey={t('popups.titles.remove_product')}
                subTitleKey={t('popups.subTitles.remove_product', {
                  formName: data.formDetails?.name,
                })}
                buttonTitleKey={t('buttons.remove')}
                handleClose={handleCloseDelPopup}
                handleConfirm={removeProduct}
                open={showDelPopup}
              />
            </Grid>
          )}
        </Grid>
        <Grid item container md={12} justifyContent={'space-between'} alignItems='center' position='relative'>
          {shouldRenderQuantityCounter && (
            <Grid item md={3}>
              {/* <TextFieldCounter
                value={product.quantity}
                changeQuantity={changeQuantity}
              /> */}

              <QuantityCounter initialValue={product.quantity ?? 0} onChange={changeQuantity} />
            </Grid>
          )}
          {shouldRenderListedPriceLabel && (
            <Grid item md={3}>
              <Typography color='#8B8D98' fontWeight={400} fontSize={12}>
                Listed Price
              </Typography>
            </Grid>
          )}

          {shouldRenderEditPrice && (
            <Grid md={6} justifyContent={'end'} alignItems='center' display={'flex'} gap='.5rem'>
              {/* <ProductEditPrice
                handleChangePrice={handleChangePrice}
                value={product.price}
                showEndAdornment={false}
              /> */}
              <DebouncedEditPrice initialValue={product.price ?? ''} onChange={handleChangePrice} categoryKind={product.category_kind} />
              {/* <Typography
                variant='body2'
                fontWeight={600}
                color='text.disabled'
              >
                {t('texts.aed')}
              </Typography> */}
            </Grid>
          )}

          {shouldRenderAddAlternatives && (
            <Typography variant='subtitle2' color='text.third'>
              {product.alternatives ? product.alternatives.length : 0}
              {' Alternatives'}
              <span
                className='underline-on-hover'
                onClick={navigateToAlternatives}
                style={{
                  color: '#13628C',
                  fontWeight: 500,
                  cursor: 'pointer',
                  marginInlineStart: '.5rem',
                }}
              >
                Add
              </span>
            </Typography>
          )}
        </Grid>
        <Grid item md={12} pt={0.75}>
          <Divider sx={{ borderColor: 'info.contrastText', opacity: 0.5 }} />
        </Grid>
      </Grid>
    </>
  );
};

import { ProductManagerEnv } from '@/config';
import { useRouter } from 'next/router';
import React from 'react';
import { EnvironmentFilters } from 'src/logic/models/catalogue/AdvancedFilters';
import { sm } from 'src/site-map';
import { useProcurementStore } from '../../procurement';
import { useUserData } from '../../user';

const useEnvironmentFilters = (): EnvironmentFilters => {
  const { pathname } = useRouter();
  const { isSeller: _isSeller } = useUserData();
  const isSeller = _isSeller();

  const { data: envData } = useProcurementStore(state => state.productFormManagerProperties);
  const env = envData.environment?.type;

  const sellerId = envData?.formDetails?.seller_details?.id;
  const formId = envData?.formDetails?.id;

  return React.useMemo(() => {
    if (env === ProductManagerEnv.catalog) {
      return { is_adding_to_catalog: 1 };
    }
    if ((env === ProductManagerEnv.purchase_order || env === ProductManagerEnv.edit_po_from_scratch) && sellerId) {
      return { seller_id: sellerId };
    }
    if ((env === ProductManagerEnv.quote || env === ProductManagerEnv.edit_quote_from_scratch) && formId) {
      return {
        quote_id: formId,
        filtration_purpose: 'SELLER_ADD_TO_QUOTE',
      };
    }
    if (pathname === sm.catalogue.products.add.url || pathname === sm.catalogue.products.editProduct().url) {
      return {
        filtration_purpose: 'CREATE_PRODUCT',
      };
    }
    if (pathname === sm.catalogue.buyer.managePrivateListings.index.url) {
      return {
        filtration_purpose: 'BUYER_MANAGE_PRIVATE_LISTINGS',
      };
    }
    if (pathname.startsWith(sm.manageCatalogue.url) || pathname == sm.myCatalogue.index.url) {
      return {
        filtration_purpose: 'SELLER_MANAGE_MY_CATALOG',
      };
    }
    if (pathname.startsWith(sm.linkDirectory.url)) {
      return {
        filtration_purpose: 'SELLER_LINK_FROM_DIRECTORY',
      };
    }
    if (pathname == sm.myCatalogue.myBrands.url) {
      return {
        filtration_purpose: 'SELLER_MANAGE_CATALOG_BRAND',
      };
    }
    if (isSeller) {
      return {
        filtration_purpose: 'SELLER_GENERAL_DIRECTORY',
      };
    }

    return {};
  }, [env, sellerId, formId, pathname, isSeller]);
};

export default useEnvironmentFilters;

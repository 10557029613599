import { IconButton, List, styled, SwipeableDrawer, ListItemButton as _ListItemButton, Button, ClickAwayListener, Stack, Divider } from '@mui/material';
import { Badge, ContactSupport, Menu, BusinessCenter, Close } from '@mui/icons-material';
import React from 'react';
import { useRouter } from 'next/router';
import { isIOS } from '@/constants';
import { Link } from '../../shared';
import { sm } from 'src/site-map';
import { useTranslation } from 'next-i18next';
import { useDrawerStore } from '@/zustand';
import { Drawer_PAGES_CONFIG } from '../drawer-config';
import { SideDrawerItem } from '../drawer-desktop/drawer-item';
import { DrawerSubMenuContent } from '../drawer-desktop';
import { UserType } from '@/config';

const ListItemButton = styled(_ListItemButton)(({ theme }) => ({
    transition: '.3s',
    '&.Mui-selected': {
        borderLeft: `5px solid ${theme.palette.primary.light}`,
        '& > .MuiListItemText-root > .MuiListItemText-primary': {
            fontWeight: 500,
        },
    },
    '& > .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
    },
}));

type NavItem = {
    title: string;
    url: string;
    pageIcon: React.ReactNode;
    isPortal: boolean
};
const baseNav: NavItem[] = [
    { ...sm.becomeASeller, pageIcon: <Badge sx={{ color: 'primary.main' }} />, isPortal: false },
    { ...sm.careers.index, pageIcon: <BusinessCenter sx={{ color: 'primary.main' }} />, isPortal: false },
    { ...sm.contactUs, pageIcon: <ContactSupport sx={{ color: 'primary.main' }} />, isPortal: false },
];

export const MobileDrawer: React.FC = (props: any) => {
    const isMobileDrawerOpen = useDrawerStore(state => state.isMobileDrawerOpen)
    const openDrawer = useDrawerStore(state => state.openDrawer)
    const closeMobileDrawer = useDrawerStore(state => state.closeMobileDrawer)
    const isDrawerSubMenuOpen = useDrawerStore(state => state.isDrawerSubMenuOpen)
    const router = useRouter();
    const { t } = useTranslation();
    const goToRegister = () => { router.push(sm.register.index.url), closeMobileDrawer() }
    const goToLogin = () => { router.push(sm.login.url), closeMobileDrawer() }

    return (
        <>
            <IconButton
                sx={{ backgroundColor: 'white', color: 'primary.main' }}
                onClick={(e) => {
                    e.stopPropagation();
                    isMobileDrawerOpen ? closeMobileDrawer() : openDrawer()
                }}
            >
                {isMobileDrawerOpen ? <Close /> : <Menu />}
            </IconButton>
            <SwipeableDrawer
                open={isMobileDrawerOpen}
                anchor='left'
                elevation={1}
                hideBackdrop={true}
                disableBackdropTransition={!isIOS}
                disableDiscovery={isIOS}
                PaperProps={{ sx: { mt: '55px', width: '70%' }, }}>
                <ClickAwayListener onClickAway={() => closeMobileDrawer()}>
                    <List sx={{ height: '90%', width: '100%' }}>
                        <Stack sx={{ opacity: isDrawerSubMenuOpen ? 0 : 1, height: isDrawerSubMenuOpen ? 0 : '100%', justifyContent: 'space-between' }}>
                            <Stack>
                                {Drawer_PAGES_CONFIG[props.userType].map((pageProps: any) => (
                                    <SideDrawerItem key={pageProps.title} drawerItemDetails={pageProps} isDrawerOpen={isMobileDrawerOpen} />
                                ))}
                                {props.userType == UserType.UNDEFIENED && <>
                                    <Divider sx={{ borderColor: 'info.contrastText', mx: 2 }} />
                                    {baseNav.map((pageProps: any) => (
                                        <SideDrawerItem key={pageProps.title} drawerItemDetails={pageProps} isDrawerOpen={isMobileDrawerOpen} />
                                    ))}
                                </>}
                            </Stack>
                            {props.userType == UserType.UNDEFIENED && <Stack sx={{ alignItems: 'center' }}>
                                <Stack sx={{ width: '80%' }}>
                                    <Button fullWidth sx={{ mb: 1 }} variant='contained' color='secondary' onClick={goToRegister} >
                                        {t('buttons.register')}
                                    </Button>
                                    <Button variant='contained' fullWidth onClick={goToLogin}>
                                        {t('buttons.login')}
                                    </Button>
                                </Stack>
                            </Stack>}
                        </Stack>
                        {isDrawerSubMenuOpen && <Stack sx={{ height: '100%', px: 2 }}>
                            <DrawerSubMenuContent />
                        </Stack>}

                    </List>
                </ClickAwayListener>
            </SwipeableDrawer>
        </>
    );
};

import { Product, ProductCategoryKind } from '@/models';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import usePopup from 'src/logic/hooks/use-popup';
import { ConfirmPopup, DescPopup } from 'src/ui/components/shared';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import ProductQuickView from 'src/ui/components/smart/product/product-quick-view';
import { DeleteIcon } from 'src/ui/icons/delete';
import { DescriptionIndicator } from './components';
import { ProductCardStyles, TableHeaderCellProps, TableHeaderStyles } from './styles';
import useDeleteUtils from './use-delete-utils';

export type RequisitionServiceAlternativesTableProps = {
  alternativeProducts: Product[];
  quantity: number;
  additional_requirements: string;
  canDelete?: boolean;
};

const RequisitionServiceAlternativesTable = ({
  alternativeProducts,
  additional_requirements,
  quantity,
  canDelete = false,
}: RequisitionServiceAlternativesTableProps) => {
  const { t } = useTranslation();
  const [productToQuickView, setProductToQuickView] = React.useState<Product | null>(null);
  const detailsPopup = usePopup();
  const { cancelDeleteProduct, confirmDeletePopup, confirmDeleteProduct, requestProductToDelete } = useDeleteUtils();

  return (
    <>
      <DescPopup big open={detailsPopup.isOpen} handleClose={detailsPopup.handleClose} paperSx={{ borderRadius: 0 }}>
        {productToQuickView !== null && <ProductQuickView product={productToQuickView} />}
      </DescPopup>
      <Grid container md={12}>
        <Grid container md={12} sx={TableHeaderStyles}>
          <Grid item md={1.5}>
            <Typography {...TableHeaderCellProps}>SEGMENT</Typography>
          </Grid>
          <Grid item md={2.5}>
            <Typography {...TableHeaderCellProps}>NAME</Typography>
          </Grid>
          <Grid item md={8}>
            <Typography {...TableHeaderCellProps}>DETAILS</Typography>
          </Grid>
        </Grid>
        {alternativeProducts.map(product => (
          <Grid md={12} container key={product.id} sx={ProductCardStyles} alignItems={'center'}>
            <Grid item md={0.75} pr={0.25}>
              <Typography color='#3A3E50' fontWeight={400} fontSize={12}>
                SERVICE
              </Typography>
            </Grid>
            <Grid item md={0.75} pr={0.25}>
              <img
                width={50}
                height={50}
                style={{ objectFit: 'contain', display: 'block' }}
                src={product.image_url}
                alt=''
              />
            </Grid>
            <Grid item md={2.5} pr={1.5}>
              <div style={{ display: 'flex' }}>
                <Typography
                  fontSize={14}
                  fontWeight='700'
                  color='#3A3E50'
                  noWrap
                  className={'underline-on-hover cursor-pointer'}
                  onClick={() => {
                    setProductToQuickView(product);
                    detailsPopup.handleOpen();
                  }}
                >
                  {product.name}
                </Typography>
                {product.is_private_product && <PrivacyButton disableText />}
              </div>

              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <DescriptionIndicator description={product.description} category_kind={ProductCategoryKind.SERVICE} />
                <Typography fontSize={13} fontWeight={400} color='#8B8D98'>
                  {product.part_number}
                </Typography>
              </div>
            </Grid>

            <Grid item md={8}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Grid container md={12} alignItems={'center'} pr={3}>
                  <Typography color='#3A3E50' fontSize={12} fontWeight={400}>
                    {additional_requirements}
                  </Typography>
                </Grid>
                {canDelete && (
                  <DeleteIcon cursor={'pointer'} fontSize='small' onClick={() => requestProductToDelete(product)} />
                )}
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {canDelete && (
        <ConfirmPopup
          titleKey={t('popups.titles.remove_product')}
          subTitleKey={t('popups.subTitles.remove_product', { formName: 'your Draft Requisition' })}
          buttonTitleKey={t('buttons.remove')}
          handleClose={cancelDeleteProduct}
          handleConfirm={confirmDeleteProduct}
          open={confirmDeletePopup.isOpen}
        />
      )}
    </>
  );
};

export default RequisitionServiceAlternativesTable;

import { Button, SxProps } from '@mui/material';
import Link from 'next/link';
import { sm } from 'src/site-map';

type BackToCatalogueButtonProps = {
  customName?: string;
  buttonSx?: SxProps;
};

const BackToCatalogueButton = ({ customName, buttonSx = {} }: BackToCatalogueButtonProps) => {
  return (
    <Link href={sm.catalogue.index.url}>
      <Button
        sx={{
          border: '1px solid #3A3E50',
          color: '#3A3E50',
          borderRadius: '44px',
          fontWeight: 500,
          whiteSpace: 'nowrap',
          lineHeight: 1,
          px: 2,
          py: 1,
          ...buttonSx,
        }}
      >
        {customName ? customName : 'Directory'}
      </Button>
    </Link>
  );
};

export default BackToCatalogueButton;

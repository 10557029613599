import React from 'react';
import { Brand, Category } from 'src/logic/models/company-details/seller';

import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { StyledPopper } from 'src/ui/components/shared';
import { useOnClickOutside } from '@/hooks';

type IProps = {
  type: 'Categories' | 'Brands';
  items: Category[] | Brand[] | null;
};

export const chipStyles: React.CSSProperties = {
  backgroundColor: '#EAEEF6',
  color: '#13628C',
  fontWeight: 500,
  maxWidth: 'fit-content',
};

const ChipWithList = ({ items, type }: IProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const divRef = React.useRef<HTMLDivElement>(null);
  const chipRef = React.useRef<HTMLDivElement>(null);
  const tooltipOpen = Boolean(anchorEl);
  const handleCloseTooltip = () => {
    setAnchorEl(null);
  };
  useOnClickOutside(divRef, e => {
    if (!chipRef.current?.contains(e.target as Node)) {
      handleCloseTooltip();
    }
  });

  if (!items) return null;
  const remainingNumber = items.length - 1;
  return (
    <>
      <Chip
        ref={chipRef}
        label={`${items[0].name} + ${remainingNumber}`}
        variant='filled'
        sx={chipStyles}
        clickable
        onClick={e => {
          if (tooltipOpen) setAnchorEl(null);
          else setAnchorEl(e.currentTarget);
          e.stopPropagation();
        }}
      />
      <StyledPopper
        open={tooltipOpen}
        anchorEl={anchorEl}
        handleClose={handleCloseTooltip}
        place='bottom'
        zIndex={10}
      >
        <div
          ref={divRef}
          style={{
            padding: 15,
            minWidth: '10rem',
            maxWidth: '15rem',
            maxHeight: '15rem',
            overflowY: 'auto',
          }}
          className='styled-scrollbar'
          onClick={e => e.stopPropagation()}
        >
          <Typography variant='body2' fontWeight={500} color={'#3A3E50'}>
            {type}
          </Typography>
          <Divider
            sx={{
              borderColor: 'info.contrastText',
              opacity: 0.7,
              mt: 0.75,
              mb: 0.75,
            }}
          />
          {items.map(item => (
            <div key={item.id}>
              <Typography
                color='#13628C'
                variant='subtitle2'
                fontWeight={400}
                fontSize={13}
                mb={0.85}
                lineHeight={1.2}
              >
                {item.name}
              </Typography>
            </div>
          ))}
        </div>
      </StyledPopper>
    </>
  );
};

const HovarableListChip = ({ items, type }: IProps) => {
  if (!items || items.length === 0) {
    return <Chip label={`0 ${type}`} variant='filled' sx={chipStyles} />;
  }
  if (items.length === 1) {
    return <Chip label={items[0].name} variant='filled' sx={chipStyles} />;
  }
  return <ChipWithList items={items} type={type} />;
};

export default HovarableListChip;

import { AuthenticateStatus, ProductManagerEnv } from "@/config";
import { FilterItem, Filters_Res, filtrationPurpose, PaginationResult, Product, Requisition } from "@/models";
import { buildFilterRequestFormDataData, toggleSelectionOfFilterItem, useApplyFilters, useLoadFilters } from "@/services";
import produce from "immer";
import { StateCreator } from "zustand";
import { useUserData } from "../../user";

export interface FilterSlice {
    categoryData: any | null,
    filterSliceTypeId?: number,
    filterSliceSellerId?: number,
    filterSliceQuoteId?: number
    filterLoading: boolean,
    filterProducts: Product[],
    loadFilter: (typeId: number, sellerId?: number, quoteId?: number) => void;
    filterSliceFilters: Filters_Res[] | null,
    filterPaginationResult: PaginationResult | null
    copyOfReqFilterSlice: Requisition | null
    copyOfFormSlice: Requisition | null
    filterSortData: FilterItem[] | null
    filterApplyFilterLoading: boolean
    canPerformActionOnRequisition: boolean
    canPerformActionOnPurchaseOrder: boolean
    onFilterItemCheck: (filterItem: FilterItem, type: string) => void,
    onFilterItemUnCheck: (filterItem: FilterItem, type: string) => void,
    onFilterItemToggle: (filterItem: FilterItem, type: string, checked: boolean) => void,
    onSortItemSelectionFilterSlice: (sortId: number) => void,
    selectedSortItemIdFilterSlice?: number,
    applyFiltersFilterSlice: () => void
    selectedPageNumberFilterSlice: number,
    onPageNumberChangeFilterSlice: (pageNumber: number) => void,
    onResetFilterClicked: () => void,
    numberOfSelectedFilters: number,
    changeProductQuantity: (quantity: number, product: Product) => void
    changeProductRequirements: (additional_requirements: string, product: Product) => void
    changeProductQuantityWithPersistence: (quantity: number, product: Product) => void
    changeProductRequirementsWithPersistence: (additional_requirements: string, product: Product) => void
    changeProductPrice: (product: Product, price: number) => void
}

export const createFilterSlice: StateCreator<FilterSlice> = (set, get, api) => ({
    categoryData: null,
    filterLoading: false,
    filterApplyFilterLoading: true,
    filterProducts: [],
    copyOfFormSlice: null,
    filterSortData: null,
    numberOfSelectedFilters: 0,
    canPerformActionOnRequisition: false,
    canPerformActionOnPurchaseOrder: false,
    selectedPageNumberFilterSlice: 1,
    changeProductQuantity: (quantity: number, product: Product) => {
        product = produce(product, (draftProduct) => { draftProduct.quantity = quantity })
        set(produce(draftState => {
            draftState.filterProducts = draftState.filterProducts.map((filterProduct: Product) => {
                if (filterProduct.id == product.id) return product
                else return filterProduct
            })
        }))
        if (product.inCart) get().productFormManagerProperties.actions.changeQuantity(product, quantity)
    },
    changeProductRequirements: (additional_requirements: string, product: Product) => {
        product = produce(product, (draftProduct) => { draftProduct.additional_requirements = additional_requirements })
        set(produce(draftState => {
            draftState.filterProducts = draftState.filterProducts.map((filterProduct: Product) => {
                if (filterProduct.id == product.id) return product
                else return filterProduct
            })
        }))
        if (product.inCart) get().productFormManagerProperties.actions.changeRequirements(product, additional_requirements)
    },
    changeProductPrice: (product: Product, price: number) => {
        product = produce(product, (draftProduct) => { draftProduct.price = price })
        set(produce(draftState => {
            draftState.filterProducts = draftState.filterProducts.map((filterProduct: Product) => {
                if (filterProduct.id == product.id) return product
                else return filterProduct
            })
        }))
        if (product.inCart) {
            get().productFormManagerProperties.actions.changePrice(product, price)
        }
    },
    changeProductQuantityWithPersistence: (quantity: number, product: Product) => {
        product = produce(product, (draftProduct) => { draftProduct.quantity = quantity })
        set(produce(draftState => {
            draftState.filterProducts = draftState.filterProducts.map((filterProduct: Product) => {
                if (filterProduct.id == product.id) return product
                else return filterProduct
            })
        }))
        if (product.inCart) {
            get().updateRequisitionProductQuantityWithPersistence(product, quantity)
        }
    },
    changeProductRequirementsWithPersistence: (additional_requirements: string, product: Product) => {
        product = produce(product, (draftProduct) => { draftProduct.additional_requirements = additional_requirements })
        set(produce(draftState => {
            draftState.filterProducts = draftState.filterProducts.map((filterProduct: Product) => {
                if (filterProduct.id == product.id) return product
                else return filterProduct
            })
        }))
        if (product.inCart) {
            get().updateRequisitionProductRequirementsWithPersistence(product, additional_requirements)
        }
    },
    loadFilter: (typeId: number, sellerId?: number, quoteId?: number) => {
        set(produce(draftState => { draftState.filterLoading = true }))
        const isAddingToCatalog = get().productFormManagerProperties.data.environment?.type === ProductManagerEnv.catalog
        const purpose = get().productFormManagerProperties.data.environment ? filtrationPurpose[get().productFormManagerProperties.data.environment?.type] : null

        const { isSeller, isAuthenticated } = useUserData.getState()
        useLoadFilters(typeId, isAddingToCatalog, purpose, sellerId ?? undefined, quoteId ?? undefined).then(data => {
            const paginationResult = data.data.data
            set(produce(draftState => {
                draftState.filterSliceTypeId = typeId
                draftState.filterSliceSellerId = sellerId
                draftState.filterSliceQuoteId = quoteId
                draftState.filterProducts = paginationResult.data.map((product: Product) => {
                    return get().productFormManagerProperties.data.environment ? get().productFormManagerProperties.actions.transformProduct(product, get().productFormManagerProperties.data.formDetails)
                        : get().buildInitialProductState(product)
                })
                draftState.copyOfReqFilterSlice = null
                draftState.copyOfFormSlice = null
                draftState.canPerformActionOnRequisition = !isSeller() || isAuthenticated === AuthenticateStatus.NOT_AUTHENTICATED
                draftState.canPerformActionOnPurchaseOrder = !isSeller() || isAuthenticated === AuthenticateStatus.NOT_AUTHENTICATED
                draftState.filterSliceFilters = data.data.filter_data
                draftState.filterLoading = false
                draftState.categoryData = data.data.category
                draftState.filterApplyFilterLoading = false
                draftState.filterSortData = data.data.sort_data
                draftState.filterPaginationResult = { total: paginationResult.total, last_page: paginationResult.last_page, current_page: paginationResult.current_page }
            }))
        }).catch(e => { })

        api.subscribe(
            state => {
                const formDetails = state.productFormManagerProperties.data.formDetails
                if (formDetails)
                    if (JSON.stringify(formDetails) != JSON.stringify(get().copyOfFormSlice))
                        set(produce(draftState => {
                            draftState.filterProducts = draftState.filterProducts.map((product: Product) => {
                                return get().productFormManagerProperties.data.environment ? get().productFormManagerProperties.actions.transformProduct(product, formDetails)
                                    : get().buildInitialProductState(product)

                            })
                            draftState.copyOfFormSlice = formDetails
                        }))
            },
        )
    },
    onResetFilterClicked: () => {
        set(produce(draftState => {
            draftState.selectedPageNumberFilterSlice = 1
            draftState.selectedSortItemIdFilterSlice = draftState.filterSortData[0].id
            draftState.filterSortData = draftState.filterSortData.map((filterItem: FilterItem, index: number) => {
                return produce(filterItem, (filterItemDraft => { filterItemDraft.is_selected = index === 0 }))
            })
            draftState.filterSliceFilters = draftState.filterSliceFilters.map((filterRes: Filters_Res) => {
                return produce(filterRes, (filterResDraft => {
                    filterResDraft.filter_items = filterRes.filter_items.map((filterItem: FilterItem) => produce(filterItem, (filterItemDraft => { filterItemDraft.is_selected = false })))
                }))
            })
            draftState.numberOfSelectedFilters = 0
        }))
        get().applyFiltersFilterSlice()
    },
    onPageNumberChangeFilterSlice: (pageNumber: number) => {
        set(produce(draftState => {
            draftState.selectedPageNumberFilterSlice = pageNumber
        }))
        get().applyFiltersFilterSlice()
    },
    onSortItemSelectionFilterSlice: (sortId: number) => {
        set(produce(draftState => {
            draftState.selectedSortItemIdFilterSlice = sortId
            draftState.selectedPageNumberFilterSlice = 1
        }))
        get().applyFiltersFilterSlice()
    },
    onFilterItemCheck: (filterItem: FilterItem, type: string) => {
        get().onFilterItemToggle(filterItem, type, true)
    },
    onFilterItemUnCheck: (filterItem: FilterItem, type: string) => {
        get().onFilterItemToggle(filterItem, type, false)
    },
    applyFiltersFilterSlice: () => {
        const isAddingToCatalog = get().productFormManagerProperties.data.environment?.type === ProductManagerEnv.catalog
        const purpose = get().productFormManagerProperties.data.environment?.type ? filtrationPurpose[get().productFormManagerProperties.data.environment?.type] : null
        const filterFormData = buildFilterRequestFormDataData(get().filterSliceTypeId, get().filterSliceFilters, get().selectedPageNumberFilterSlice, isAddingToCatalog, purpose, get().selectedSortItemIdFilterSlice, get().filterSliceSellerId, get().filterSliceQuoteId)
        useApplyFilters(filterFormData)
            .then(data => {
                const paginationResult = data.data.content
                set(produce(draftState => {
                    draftState.filterProducts = data.data.content.data.map((product: Product) => {
                        //TODO: buildInitialProductState SHOULD CATER FOR CURRENT REQ
                        const formDetails = get().productFormManagerProperties.data.formDetails
                        if (get().productFormManagerProperties.data.environment) {
                            return get().productFormManagerProperties.actions.transformProduct(product, formDetails)
                        } else {
                            return get().buildInitialProductState(product)
                        }
                    })
                    draftState.filterApplyFilterLoading = false
                    draftState.filterPaginationResult = { total: paginationResult.total, last_page: paginationResult.last_page, current_page: paginationResult.current_page }
                }))
            })
            .catch(e => { })
    },
    onFilterItemToggle: (filterItem: FilterItem, type: string, checked: boolean) => {
        set(produce(draftState => {
            draftState.filterApplyFilterLoading = true
            draftState.filterSliceFilters = toggleSelectionOfFilterItem(filterItem, type, draftState.filterSliceFilters, checked)
            draftState.selectedPageNumberFilterSlice = 1
            draftState.numberOfSelectedFilters = draftState.filterSliceFilters.reduce((numberOfSelectedFilters, filterRes) => {
                numberOfSelectedFilters += filterRes.filter_items.reduce((selectedItems, filterItem) => {
                    selectedItems += filterItem.is_selected ? 1 : 0
                    return selectedItems
                }, 0)
                return numberOfSelectedFilters
            }, 0)
        }))
        get().applyFiltersFilterSlice()
    },
    filterSliceFilters: [],
    filterPaginationResult: null,
    copyOfReqFilterSlice: null,
})
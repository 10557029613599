import { Button, Grid } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import { LoadingButton } from '@mui/lab';

type StyledLoadingButtonProps = {
  variant: any;
  translateKey: string;
  loading: boolean;
  onClick?: any;
  color?: any;
  icon?: ReactNode;
  customWidth?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit';
  style?: React.CSSProperties;
  disableTranslate?: boolean;
};

export const StyledLoadingButton: React.VFC<StyledLoadingButtonProps> = ({ style = {}, ...props }) => {
  const { t } = useTranslation();
  return (
    <LoadingButton
      {...props}
      loading={props.loading}
      variant={props.variant}
      startIcon={props.icon}
      onClick={props.onClick}
      fullWidth={props.customWidth ? false : true}
      disabled={props.disabled ?? false}
      type={props.type ?? 'submit'}
      color={props.color ? props.color : 'primary'}
      style={{ cursor: 'pointer', ...style }}
    >
      {props.disableTranslate ? props.translateKey : t('buttons.' + props.translateKey)}
    </LoadingButton>
  );
};

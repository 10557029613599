import { productManagerEnv } from '@/config';
import { ProcurementModelDetails } from '@/models';
import { useProcurementStore } from '@/zustand';
import { Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import useScrollObserver from 'src/logic/hooks/use-scroll-observer';
import { useGetEditableQuotes } from 'src/logic/services/procurement/quote-seller/queries';
import { sm } from 'src/site-map';
import { Loading } from 'src/ui/components/shared';

export const QuotesPopperContent: FC<{ handleCloseFormPopper: () => void }> = ({ handleCloseFormPopper }) => {
  const { push } = useRouter();
  const { data: quotesData, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetEditableQuotes();
  const quotes = React.useMemo(() => {
    if (!quotesData) return [];
    return quotesData.pages.flatMap(page => page.data);
  }, [quotesData]);

  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const toggleForm = (form: ProcurementModelDetails) => {
    actions.toggleForm(form?.id, 'quoteDetailsSliceActions', productManagerEnv.quote, quotes);
    handleCloseFormPopper();
    push(sm.catalogue.index.url);
  };

  const { observerRef, containerRef } = useScrollObserver({
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage?.();
      }
    },
  });

  return (
    <>
      <Grid container item xs={12} mb={1} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography fontWeight={700} variant='overline' sx={{ color: '#242833' }}>
            {t('texts.draft_quotes')}
          </Typography>
        </Grid>
        <Grid item>
          <Button sx={{ fontWeight: 700 }} onClick={() => push(sm.portals.seller.procurement.quotes.index.url)}>{t('buttons.view_all')}</Button>
        </Grid>
      </Grid>
      <Stack maxHeight='240px' width='100%' ref={containerRef}>
        {isLoading ? (
          <div style={{ minHeight: '100px' }}>
            <Loading fullHeight={false} />
          </div>
        ) : (
          <>
            {quotes?.map(quote => {
              return (
                <Grid item xs={12} key={quote.id}>
                  {data.formDetails?.id == quote.id ? (
                    <Stack
                      p={1}
                      sx={{
                        backgroundColor: 'info.contrastText',
                        borderRadius: '4px',
                      }}
                    >
                      <Typography variant='body2' fontWeight={700}>
                        {quote.name}
                      </Typography>
                      <Typography variant='subtitle2' color='info.main' lineHeight={1.2} fontWeight={400}>
                        {quote.buyer_details ? `${quote.buyer_details.name}, ` : ''}
                        {quote.sender_project?.name}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack p={1} sx={{ cursor: 'pointer' }} onClick={() => toggleForm(quote)}>
                      <Typography variant='body2'>{quote.name}</Typography>
                      <Typography variant='subtitle2' color='text.third' lineHeight={1.2}>
                        {quote.buyer_details ? `${quote.buyer_details.name}, ` : ''}
                        {quote.sender_project?.name}
                      </Typography>
                    </Stack>
                  )}
                </Grid>
              );
            })}
            {hasNextPage && (
              <div
                style={{ marginBlock: '.5rem', opacity: isFetchingNextPage ? 1 : 0, transition: 'opacity 200ms' }}
                ref={observerRef}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '.5rem' }}>
                  <CircularProgress size={'1rem'} />
                  <Typography color='#8B8D98' fontWeight={400} fontSize={13}>
                    Loading Quotes...
                  </Typography>
                </div>
              </div>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

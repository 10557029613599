import { AuthenticateStatus, ProcurementButtons, ProcurementPopupStatus, procurementStatus } from "@/config";
import { Comment, GeneralObject, LinkType, ProductTableRow, RFQ, RFQDetails, RFQProduct } from "@/models";
import { buildRFQDetails, NotifySuccess, NotifySuccessWithCancelIcon, useChangeProject, useDeclineRequestForQuote, useEditExpiryDate, useFetchDeclineRFQReasons, useGenerateQuote, useRequestExtension, useRequestForQuoteDetails, useUpdateQuoteFromRfq } from "@/services";
import { t } from "i18next";
import produce from "immer";
import { useUserData } from "src/logic/zustand/user";
import { StateCreator } from "zustand";
import QueryHandler, { QueryHandlerModel } from "src/logic/services/query-handlers/QueryHandler";
import { generateExternalQuote, getExternalRequestForQuoteDetails, useDeclineExternalRequestForQuote, useUpdateExternalQuoteFromRfq } from "src/logic/services/procurement/external-request-for-quote";
import { VAT } from "@/constants";

type ResponseHandlers<T = any> = {
    onSuccess?: (data: T) => void;
    onError?: () => void;
  };
export interface RequestForQuoteSlice {
    requestForQuoteSliceProperties: {
        requestForQuote: RFQDetails | null,
        fetchRfqDetailsQueryStatus: QueryHandlerModel,
        // loadingRFQDetails: boolean
        productsTableRows: ProductTableRow[]
        openedPopupStatus: ProcurementPopupStatus | null
        numberOfSelectedProducts: number
        isQuoteOwner: boolean
        newComments: Comment[]
        isEditMode: boolean
        isAllProductsSelected: boolean
        reasonsForPopup: GeneralObject[]
        summaryDetails: { SummarySubtotal: number, SummaryVat: number, SummaryTotal: number }
        customLoadingButton: ProcurementButtons | null
    }
    requestForQuoteSliceActions: {
        fetchRFQDetails: (rfqId: number, isEditMode: boolean) => void
        fetchExternalRfqDetails: (rfqId: string, isEditMode: boolean) => void
        // setIsLoading: (isLoading: boolean) => void
        setRfqDetails: (rfq: RFQ, isEditMode: boolean) => void
        setProductsTableRows: (rfq: RFQDetails | null) => void
        toggleSelectProductFromRFQ: (product: ProductTableRow) => void
        changeAvailableQuantity: (product: ProductTableRow, quantity: number) => void
        changeUnitPrice: (product: ProductTableRow, price: string) => void
        setPopupStatus: (status: ProcurementPopupStatus | null) => void
        addRemark: (product: ProductTableRow, remark: string) => void
        decline: (reasonId: string, comment?: string) => void
        getReasonsForPopup: () => void
        generateQuote: (rfqProducts: ProductTableRow[], notifyNoProductsAddedError: () => void, successHandler: (quoteId: number) => void, notifyAvailableQuantityError: (quantity: ProductTableRow[] | null, quoteIdToEdit?: number) => void) => void
        generateExternalQuote: (rfqProducts: ProductTableRow[], notifyNoProductsAddedError: () => void, successHandler: (quoteId: number) => void, notifyAvailableQuantityError: (quantity: ProductTableRow[] | null, quoteIdToEdit?: number) => void) => void
        // generateExternalQuote: (rfqProducts: ProductTableRow[]) => void;
        closeNewCommentsPopup: () => void
        setSummaryDetails: (products: ProductTableRow[]) => void
        toggleSelectAllProducts: (value: boolean) => void
        setIsAllProductsSeleced: (products: ProductTableRow[], numberOfSelectedProducts: number) => void
        setCustomLoadingButton: (isLoading: ProcurementButtons | null) => void
        requestExtensionForRFQExpiredBySeller: (responseHandlers?: ResponseHandlers<RFQ>) => void
        editExpiryDate: (validityDate: string, closePopup: () => void) => void
        changeProject: (project: GeneralObject, isEditMode?: boolean) => void
    }
}

export const createRequestForQuoteSlice: StateCreator<RequestForQuoteSlice> = (set, get, api) => ({
    requestForQuoteSliceProperties: {
        requestForQuote: null,
        fetchRfqDetailsQueryStatus: QueryHandler.getInitialStatus(),
        // loadingRFQDetails: false,
        productsTableRows: [],
        numberOfSelectedProducts: 0,
        openedPopupStatus: null,
        isQuoteOwner: false,
        newComments: [],
        isEditMode: false,
        isAllProductsSelected: false,
        summaryDetails: { SummarySubtotal: 0, SummaryVat: 0, SummaryTotal: 0 },
        reasonsForPopup: [],
        customLoadingButton: null
    },
    requestForQuoteSliceActions: {
        fetchRFQDetails: (rfqId: number, isEditMode: boolean) => {
            // get().requestForQuoteSliceActions.setIsLoading(true)
            set(produce(draftState => {
                draftState.requestForQuoteSliceProperties.fetchRfqDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            useRequestForQuoteDetails(rfqId).then(data => {
                get().requestForQuoteSliceActions.setRfqDetails(data.data, isEditMode)
                set(produce(draftState => {
                    draftState.requestForQuoteSliceProperties.fetchRfqDetailsQueryStatus = QueryHandler.getSuccessStatus();
                }))
                // get().requestForQuoteSliceActions.setIsLoading(false)
            }).catch((response) => {
                set(produce(draftState => {
                    draftState.requestForQuoteSliceProperties.fetchRfqDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        fetchExternalRfqDetails: (rfqId: string, isEditMode: boolean) => {
            set(produce(draftState => {
                draftState.requestForQuoteSliceProperties.fetchRfqDetailsQueryStatus = QueryHandler.getStartStatus();
            }))
            getExternalRequestForQuoteDetails(rfqId).then(data => {
                get().requestForQuoteSliceActions.setRfqDetails(data.data, isEditMode)
                set(produce<RequestForQuoteSlice>(draftState => {
                    draftState.requestForQuoteSliceProperties.fetchRfqDetailsQueryStatus = QueryHandler.getSuccessStatus();
                }))
            }).catch((response) => {
                set(produce(draftState => {
                    draftState.requestForQuoteSliceProperties.fetchRfqDetailsQueryStatus = QueryHandler.getErrorStatus(response);
                }))
            })
        },
        // setIsLoading: (isLoading: boolean) => set(produce(draftState => {
        //     draftState.requestForQuoteSliceProperties.loadingRFQDetails = isLoading
        // })),
        setRfqDetails: (rfq: RFQ, isEditMode: boolean) => {
            const userInfo = useUserData.getState().userInfo
            const requestForQuote = buildRFQDetails(rfq, userInfo, isEditMode)
            set(produce(draftState => {
                draftState.requestForQuoteSliceProperties.requestForQuote = requestForQuote
                draftState.requestForQuoteSliceProperties.isEditMode = isEditMode
                draftState.requestForQuoteSliceProperties.newComments = rfq.new_comments?.length == 0 ? null : rfq.new_comments
                draftState.requestForQuoteSliceProperties.customLoadingButton = null
            }))
            get().requestForQuoteSliceActions.setProductsTableRows(requestForQuote)
            get().setTimeline(rfq.timeline)
        },
        setSummaryDetails: (products: ProductTableRow[]) => {
            let subtotal = products.reduce((accumulator, product) => accumulator + (Number(product.unitPrice) * product.availableQuantity), 0);
            let vat = VAT * subtotal
            set(produce(draftState => {
                draftState.requestForQuoteSliceProperties.summaryDetails.SummarySubtotal = subtotal
                draftState.requestForQuoteSliceProperties.summaryDetails.SummaryVat = vat
                draftState.requestForQuoteSliceProperties.summaryDetails.SummaryTotal = subtotal + vat
            }))
        },
        setPopupStatus: (status: ProcurementPopupStatus | null) => set(produce(draftState => {
            draftState.requestForQuoteSliceProperties.openedPopupStatus = status
            if (!status) {
                draftState.requestForQuoteSliceProperties.openedPopupStatus = null
            }
        })),
        getReasonsForPopup: () => {
            if (get().requestForQuoteSliceProperties.openedPopupStatus == ProcurementPopupStatus.DECLINE)
                useFetchDeclineRFQReasons().then(data => { set(produce(draftState => { draftState.requestForQuoteSliceProperties.reasonsForPopup = data.data })) })
        },
        decline: (reasonId: string, comment?: string) => {
            get().requestForQuoteSliceActions.setCustomLoadingButton(ProcurementButtons.DECLINE_RFQ_BY_SELLER)
            const rfqProps = get().requestForQuoteSliceProperties
            if (rfqProps.openedPopupStatus == ProcurementPopupStatus.DECLINE) {
                const isGuest = useUserData.getState().authenticationStatus === AuthenticateStatus.NOT_AUTHENTICATED;
                const declineService = isGuest ? useDeclineExternalRequestForQuote : useDeclineRequestForQuote
                declineService(rfqProps.requestForQuote?.rfqModel?.id, reasonId, comment).then((data) => {
                    get().requestForQuoteSliceActions.setPopupStatus(null)
                    get().requestForQuoteSliceActions.setRfqDetails(data.data, false)
                    NotifySuccessWithCancelIcon(t('notifications.form_declined', { formName: get().requestForQuoteSliceProperties.requestForQuote?.rfqModel?.name}))
                }).catch(() => get().requestForQuoteSliceActions.setCustomLoadingButton(null))
            }
        },
        toggleSelectProductFromRFQ: (product: ProductTableRow) => {
            let productRows = get().requestForQuoteSliceProperties.productsTableRows
            let changedProductsRows = productRows.map((productRow: ProductTableRow) => {
                if (productRow.id == product.id) return produce(productRow, draftproductRow => {
                    draftproductRow.availableQuantity = productRow.requestedQuantity ?? 1
                    draftproductRow.unitPrice = productRow.unitPrice ?? ''
                    draftproductRow.remark = productRow.remark ?? ''
                    draftproductRow.productDetails.selected = !productRow.productDetails.selected
                })
                else return productRow
            })
            let numberOfSelectedProducts = changedProductsRows.filter(productsTableRow => productsTableRow.productDetails.selected).length
            set(produce(draftState => {
                draftState.requestForQuoteSliceProperties.productsTableRows = changedProductsRows
                draftState.requestForQuoteSliceProperties.numberOfSelectedProducts = numberOfSelectedProducts
            }))
            get().requestForQuoteSliceActions.setIsAllProductsSeleced(changedProductsRows, numberOfSelectedProducts)
        },
        changeAvailableQuantity: (product: ProductTableRow, quantity: number) => {
            let productRows = get().requestForQuoteSliceProperties.productsTableRows
            let changedProductRows = productRows.map((productRow: ProductTableRow) => {
                if (productRow.id == product.id && productRow.requestedQuantity >= quantity && quantity > 0) return produce(productRow, draftproductRow => {
                    draftproductRow.availableQuantity = quantity
                })
                else return productRow
            })
            set(produce(draftState => {
                draftState.requestForQuoteSliceProperties.productsTableRows = changedProductRows
            }))
            get().requestForQuoteSliceActions.setSummaryDetails(changedProductRows)
        },
        addRemark: (product: ProductTableRow, remark: string) => {
            let productRows = get().requestForQuoteSliceProperties.productsTableRows
            set(produce(draftState => {
                draftState.requestForQuoteSliceProperties.productsTableRows = productRows.map((productRow: ProductTableRow) => {
                    if (productRow.id == product.id && productRow.productDetails.selected) return produce(productRow, draftproductRow => {
                        draftproductRow.remark = remark
                    })
                    else return productRow
                })
            }))
        },
        changeUnitPrice: (product: ProductTableRow, price: string) => {
            let productRows = get().requestForQuoteSliceProperties.productsTableRows
            let changedProductRows = productRows.map((productRow: ProductTableRow) => {
                if (productRow.id == product.id) return produce(productRow, draftproductRow => {
                    draftproductRow.unitPrice = price
                })
                else return productRow
            })
            set(produce(draftState => {
                draftState.requestForQuoteSliceProperties.productsTableRows = changedProductRows
            }))
            get().requestForQuoteSliceActions.setSummaryDetails(changedProductRows)
        },
        setProductsTableRows: (rfq: RFQDetails | null) => {
            let ProductsArray: ProductTableRow[] = []
            rfq?.rfqModel?.products?.map((product: RFQProduct) => ProductsArray.push({
                id: product.id,
                productDetails: {
                    ...product.product,
                    partNumber: product.product.part_number,
                    selected: product.selected,
                },
                brandImage: product.product.brand.image_url,
                warranty_term: product.warranty_term.name,
                requestedQuantity: product.requested_quantity,
                additional_requirements: product.additional_requirements,
                availableQuantity: product.selected ? product.available_quantity : 0,
                unitPrice: product.unit_price == 0 ? '' : product.unit_price,
                remark: product.remarks ?? ''
            }))
            if (rfq?.canGenerateQuote) {
                set(produce(draftState => {
                    draftState.requestForQuoteSliceProperties.productsTableRows = ProductsArray
                    draftState.requestForQuoteSliceProperties.numberOfSelectedProducts = ProductsArray.filter(productsTableRow => productsTableRow.productDetails.selected).length
                }))
            }
            get().requestForQuoteSliceActions.setSummaryDetails(ProductsArray)
            get().requestForQuoteSliceActions.setIsAllProductsSeleced(ProductsArray, get().requestForQuoteSliceProperties.numberOfSelectedProducts)
        },
        generateQuote: (rfqProducts: ProductTableRow[], notifyNoProductsAddedError: () => void, successHandler: (quoteId: number) => void, notifyAvailableQuantityError: (quantity: ProductTableRow[] | null) => void, quoteIdToEdit?: number) => {
            let selectedRFQProducts = [] as ProductTableRow[]
            selectedRFQProducts = rfqProducts.filter(rfqProduct => rfqProduct.productDetails.selected)
            let selectedRFQProductsWithNoAvailableQuantity = selectedRFQProducts?.filter(selectedRFQProduct => selectedRFQProduct.availableQuantity == 0)
            if (selectedRFQProducts.length == 0) notifyNoProductsAddedError()
            else if (selectedRFQProductsWithNoAvailableQuantity.length != 0) notifyAvailableQuantityError(selectedRFQProductsWithNoAvailableQuantity)
            else {
                get().requestForQuoteSliceActions.setCustomLoadingButton(ProcurementButtons.GENERATE_QUOTE)
                if (quoteIdToEdit) {
                    useUpdateQuoteFromRfq(quoteIdToEdit, selectedRFQProducts).then((data) => {
                        get().requestForQuoteSliceActions.setRfqDetails(data.data, false)
                        successHandler(data.data.quote.id)
                        NotifySuccess(t("popups.titles.quote_generated"))
                    }).catch(() => get().requestForQuoteSliceActions.setCustomLoadingButton(null))
                } else {
                    useGenerateQuote(get().requestForQuoteSliceProperties.requestForQuote?.rfqModel?.id, selectedRFQProducts).then((data) => {
                        get().requestForQuoteSliceActions.setRfqDetails(data.data, false)
                        successHandler(data.data.quote.id)
                        NotifySuccess(t("popups.titles.quote_generated"))
                    }).catch(() => get().requestForQuoteSliceActions.setCustomLoadingButton(null))
                }
            }
        },
        generateExternalQuote: (rfqProducts: ProductTableRow[], notifyNoProductsAddedError: () => void, successHandler: (quoteId: number) => void, notifyAvailableQuantityError: (quantity: ProductTableRow[] | null) => void, quoteIdToEdit?: number) => {
            let selectedRFQProducts = [] as ProductTableRow[]
            selectedRFQProducts = rfqProducts.filter(rfqProduct => rfqProduct.productDetails.selected)
            let selectedRFQProductsWithNoAvailableQuantity = selectedRFQProducts?.filter(selectedRFQProduct => selectedRFQProduct.availableQuantity == 0)
            if (selectedRFQProducts.length == 0) notifyNoProductsAddedError()
            else if (selectedRFQProductsWithNoAvailableQuantity.length != 0) notifyAvailableQuantityError(selectedRFQProductsWithNoAvailableQuantity)
            else {
                get().requestForQuoteSliceActions.setCustomLoadingButton(ProcurementButtons.GENERATE_QUOTE)
                if (quoteIdToEdit) {
                    useUpdateExternalQuoteFromRfq(quoteIdToEdit, selectedRFQProducts).then((data) => {
                        get().requestForQuoteSliceActions.setRfqDetails(data.data, false)
                        successHandler(data.data.quote.id)
                        NotifySuccess(t("popups.titles.quote_generated"))
                    }).catch(() => get().requestForQuoteSliceActions.setCustomLoadingButton(null))
                } else {
                    generateExternalQuote(get().requestForQuoteSliceProperties.requestForQuote?.rfqModel?.id, selectedRFQProducts).then((data) => {
                        get().requestForQuoteSliceActions.setRfqDetails(data.data, false)
                        successHandler(data.data.quote.id)
                        NotifySuccess(t("popups.titles.quote_generated"))
                    }).catch(() => get().requestForQuoteSliceActions.setCustomLoadingButton(null))
                }
            }
        },
        // generateExternalQuote: (rfqProducts: ProductTableRow[]) => {
        //     const selectedRFQProducts = rfqProducts.filter(rfqProduct => rfqProduct.productDetails.selected)
        //     get().requestForQuoteSliceActions.setCustomLoadingButton(ProcurementButtons.GENERATE_QUOTE)
        //     generateExternalQuote(get().requestForQuoteSliceProperties.requestForQuote?.rfqModel?.id, selectedRFQProducts).then((data) => {
        //         get().requestForQuoteSliceActions.setRfqDetails(data.data, false)
        //         NotifySuccess(t("popups.titles.quote_generated"))
        //     }).finally(() => {
        //         get().requestForQuoteSliceActions.setCustomLoadingButton(null)
        //     })
        // },
        closeNewCommentsPopup: () => set(produce(draftState => {
            const rfqModel = draftState.requestForQuoteSliceProperties.requestForQuote?.rfqModel;
            if (rfqModel) {
                rfqModel.new_comments = null;
            }
            draftState.requestForQuoteSliceProperties.newComments = null
        })),
        toggleSelectAllProducts: (value: boolean) => {
            let productRows = get().requestForQuoteSliceProperties.productsTableRows
            let changedProductsRows = productRows.map((productRow: ProductTableRow) => {
                return produce(productRow, draftproductRow => {
                    draftproductRow.availableQuantity = productRow.requestedQuantity ?? 1
                    draftproductRow.unitPrice = productRow.unitPrice ?? ''
                    draftproductRow.remark = productRow.remark ?? ''
                    draftproductRow.productDetails.selected = value
                })
            })
            let numberOfSelectedProducts = changedProductsRows.filter(productsTableRow => productsTableRow.productDetails.selected).length
            set(produce(draftState => {
                draftState.requestForQuoteSliceProperties.productsTableRows = changedProductsRows
                draftState.requestForQuoteSliceProperties.numberOfSelectedProducts = numberOfSelectedProducts
            }))
            get().requestForQuoteSliceActions.setSummaryDetails(changedProductsRows)
            get().requestForQuoteSliceActions.setIsAllProductsSeleced(changedProductsRows, numberOfSelectedProducts)
        },
        setIsAllProductsSeleced: (products: ProductTableRow[], numberOfSelectedProducts: number) => {
            set(produce(draftState => { draftState.requestForQuoteSliceProperties.isAllProductsSelected = products.length == numberOfSelectedProducts }))
        },
        setCustomLoadingButton: (customButton: ProcurementButtons | null) => {
            set(produce(draftState => {
                draftState.requestForQuoteSliceProperties.customLoadingButton = customButton
                if (!customButton) { draftState.requestForQuoteSliceProperties.customLoadingButton = null }
            }))
        },
        requestExtensionForRFQExpiredBySeller: (responseHandlers?: ResponseHandlers<RFQ>) => {
            get().requestForQuoteSliceActions.setCustomLoadingButton(ProcurementButtons.REQUEST_EXTENSION)
            useRequestExtension(get().requestForQuoteSliceProperties.requestForQuote?.rfqModel?.id, 'RequestForQuote').then((data) => {
                set(produce(draftState => { draftState.requestForQuoteSliceProperties.customLoadingButton = null }))
                // let rfqModel = get().requestForQuoteSliceProperties.requestForQuote?.rfqModel
                // let updatedRFQ = { ...rfqModel, has_pending_extension_request: true, extension_request_status: procurementStatus.PENDING }
                // get().requestForQuoteSliceActions.setRfqDetails(updatedRFQ, false)
                get().requestForQuoteSliceActions.setRfqDetails(data.data, false)
                NotifySuccess(t('notifications.extension_requested'))
                responseHandlers?.onSuccess?.(data.data);
            }).catch(() => get().requestForQuoteSliceActions.setCustomLoadingButton(null))
        },
        editExpiryDate: (validityDate: string, closePopup: () => void) => {
            const poId = get().requestForQuoteSliceProperties.purchaseOrder?.model?.id
            get().requestForQuoteSliceActions.setCustomLoadingButton(ProcurementButtons.SAVE_EXPIRY_DATE)
            useEditExpiryDate(poId, LinkType.RFQ_SELLER, validityDate).then((data) => {
                get().requestForQuoteSliceActions.setRfqDetails(data.data, false)
                closePopup()
                NotifySuccess(t('notifications.expiry_date_edited'));
            }).catch(() => get().requestForQuoteSliceActions.setCustomLoadingButton(null))
        },
        changeProject: (project: GeneralObject, isEditMode: boolean = false) => {
            const modelAfterUpdateProject = useChangeProject(project, get().requestForQuoteSliceProperties?.requestForQuote?.rfqModel, true, false)
            get().requestForQuoteSliceActions.setRfqDetails(modelAfterUpdateProject, isEditMode)
        },
    }
})
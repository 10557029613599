import { Grid, Typography, Button } from "@mui/material";
import { ResponsiveContainer } from "@/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ArrowForward } from "@mui/icons-material";
import { sm } from 'src/site-map';
import { useTranslation } from 'next-i18next';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { apiURLs } from '@/config';
import { getRegisterTranslationKey } from '@/hooks'

interface verificationThankYouProps { email: string; }


export const ThankYou: React.FC<verificationThankYouProps> = email => {
    const isMobile = useMediaQuery(t => t.breakpoints.down('md'));
    const { t } = useTranslation()
    const [resendCode, setResendCode] = useState(false);
    const router = useRouter();

    const resendVerificationCode = () => {
        axios
            .post(apiURLs.resendVerification, email)
            .then(response => { setResendCode(true) })
            .catch(exception => { router.push('something-went-wrong') });
    };

    return (
        <Grid container component={ResponsiveContainer}>
            <Grid item xs={12} sx={{ pt: [4, 16] }} >
                <Typography variant="h2" sx={{ fontWeight: 'bolder' }}> {t(getRegisterTranslationKey('headers.thank_you'))} </Typography>
            </Grid>
            <Grid item xs={12} sx={{ pt: 2 }}>
                <Typography variant="body2" color="text.third"> {t(getRegisterTranslationKey('paragraphs.thank_you'))} </Typography>
            </Grid>
            <Grid container item sx={{ py: 2 }}>
                <Button
                    variant='contained'
                    href={sm.home.url}
                    sx={{
                        my: 2,
                        mr: 2,
                        float: 'left',
                        width: isMobile ? '100%' : '176px'
                    }}
                    endIcon={<ArrowForward />}
                > {t('buttons.back_to_site')} </Button>
                {!resendCode ?
                    <Button
                        variant='outlined'
                        onClick={resendVerificationCode}
                        sx={{ my: 2, mr: 2, float: 'left', width: isMobile ? '100%' : '247px' }}
                    >
                        {t('buttons.resend_verification_code_expired')}
                    </Button> :
                    <Button
                        variant='outlined'
                        startIcon={<CheckIcon />}
                        sx={{ my: 2, mr: 2, float: 'left', width: isMobile ? '100%' : '325px', bgcolor: 'primary.light' }}
                    >
                        {t('buttons.verification_code_sent')}
                    </Button>
                }
                <Button
                    variant='outlined'
                    component="a"
                    href={sm.contactUs.url}
                    sx={{
                        my: 2,
                        mr: 2,
                        textAlign: 'bold',
                        width: isMobile ? '100%' : 'initial',
                    }}
                > {t('buttons.go_to_contact_us')} </Button>
            </Grid>
        </Grid>
    )
}
import { StyledTabs } from '@/components';
import { ProductCategoryKind, TabModel } from '@/models';
import { NotifyError, NotifySuccess } from '@/services';
import { useProcurementStore } from '@/zustand';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import usePopup from 'src/logic/hooks/use-popup';
import { sm } from 'src/site-map';
import { RequisitionProductWithSellersCard, StatusChip } from 'src/ui/components/dumb';
import PrimaryBadge from 'src/ui/components/dumb/shared/primary-badge';
import { ConfirmPopup, StyledBreadcrumbs, StyledLoadingButton, TextFieldCounter, WarningPopup } from 'src/ui/components/shared';
import GeneralTerms from 'src/ui/components/smart/general-terms/GeneralTerms';
import SelectCustomWarranty from 'src/ui/components/smart/general-terms/custom-warranty/SelectCustomWarranty';
import BuyerRequirementsIndicator from '../../../products-table/BuyerRequirementsIndicator';
import useEditUtils from './useEditUtils';
import useGeneralTerms from './useGeneralTerms';
import useTabs from './useTabs';
import useSetHeaderData from 'src/logic/hooks/use-header';

type TabContentProps = {
  tabs: TabModel[] | null;
  selectedTab: number;
  onChange: (id: number) => void;
};
const TabContent: React.FC<TabContentProps> = ({ tabs, selectedTab, onChange }) => {
  const { t } = useTranslation();

  return (
    <StyledTabs
      tabs={tabs}
      value={selectedTab}
      thirdText={' ' + t('texts.selected')}
      secondText={' ' + t('texts.sellers')}
      updateTab={(_, newValue) => onChange(newValue)}
      onChange={() => { }}
    />
  );
};

export const EditRfqBuyerPage = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const generalTermsSlice = useProcurementStore(state => state);
  const { bundleRfqModel, products, selectedMainProductId, warranty_term } = useProcurementStore(
    state => state.editBundleRFQDetailsSliceProperties
  );
  const {
    updateGeneralTerms,
    setSelectedMainProductId,
    changeQuantity,
    changeRequirements,
    handleSelectWarrantyTermForMainProduct,
    toggleSelectSellerForMainProduct,
    toggleSelectSellerForAlternativeProduct,
    saveChanges,
    clearState,
    clearSellerSelections,
    linkSellersInEditMode,
    getNumberOfMainProductsWithNoSellerSelected
  } = useProcurementStore(state => state.editBundleRFQDetailsSliceActions);

  const { initialValues, validationSchema, termsData } = useGeneralTerms();
  const { formName, handleAddProducts, handleCancelEditing, addAlternativesForSelectedProduct } = useEditUtils();
  const { tabs, selectedMainProduct, allSellersCountAreZero, numberOfSelectedSellersForMainProduct } = useTabs();

  const cancelEditWarningPopup = usePopup();

  useSetHeaderData('Select Sellers', null)

  //generate rfq warning popup
  const [isWarningPopupOpened, setIsWarningPopupOpened] = useState(false);
  const handleOpenWarningPopup = () => setIsWarningPopupOpened(true);
  const handleCloseWarningPopup = () => setIsWarningPopupOpened(false);
  const numberOfProducts: any = getNumberOfMainProductsWithNoSellerSelected();
  const productProps = numberOfProducts == 1 ? "product" : "products";

  const saveLoading = useLoadingState();

  const handleSubmit = () => {
    if (allSellersCountAreZero) {
      NotifyError('Please Select Sellers');
      return;
    }
    else if (getNumberOfMainProductsWithNoSellerSelected()) handleOpenWarningPopup();
    else handelSave()
  }

  const handelSave = () => {
    handleCloseWarningPopup();
    saveLoading.startLoading();
    saveChanges({
      onSuccess: data => {
        NotifySuccess('Changes Saved');
        saveLoading.finishLoading();

        const { id, is_bundle } = data;
        if (is_bundle) {
          router.push(sm.portals.buyer.procurement.bundleRFQs.bundleRFQ(id).url);
        } else {
          router.push(sm.portals.buyer.procurement.requestForQuotes.RequestForQuote(id).url);
        }

        clearState();
      },
      onError: () => saveLoading.finishLoading()
    })
  }

  return (
    <>
      <ConfirmPopup
        icon={<ErrorOutlineIcon color='error' fontSize='large' />}
        titleKey={t('popups.titles.cancel_editing_mode')}
        subTitleKey={t('popups.subTitles.cancel_editing_mode_rfq')}
        buttonTitleKey={"Yes, I'm sure"}
        handleClose={cancelEditWarningPopup.handleClose}
        handleConfirm={handleCancelEditing}
        open={cancelEditWarningPopup.isOpen}
      />

      <Grid container pb={5}>
        <Grid item md={12} my={1} pl={1}>
          <StyledBreadcrumbs
            showBackButton
            path={sm.portals.buyer.procurement.bundleRFQs.bundleRFQ('*').url}
            alias={sm.portals.buyer.procurement.bundleRFQs.bundleRFQ('*').url}
            dynammicAlias={[formName]}
          />
        </Grid>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {formikProps => (
            <>
              <WarningPopup
                open={isWarningPopupOpened}
                title={t("popups.titles.generate_rfq_warning", { productsNumber: numberOfProducts, name: `main ${productProps}` })}
                subTitle={t("popups.subTitles.generate_rfq_warning")}
                handleClose={handleCloseWarningPopup}
                confirmButtonTitle={t("buttons.proceed_generate_rfq")}
                handleConfirm={handelSave}
                cancelButtonTitle={t("buttons.back_select_sellers")}
              />
              <Box
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  p: 1,
                  borderRadius: '8px',
                  mb: 1,
                  boxShadow: '0px 8px 32px 0px #EBEBEB',
                }}
              >
                <Grid container md={12} p={1}>
                  <Grid item container md={5} alignItems='center' gap={1}>
                    <Typography variant='h5' fontWeight={500} pl={1}>
                      {formName} ({t('texts.select_sellers')}) <StatusChip status='editing' />
                    </Typography>
                  </Grid>
                  <Grid item container gap={1} md={7} alignItems='center' justifyContent={'end'}>
                    <Grid container md={12} alignItems='center' justifyContent={'end'}>
                      <StyledLoadingButton
                        customWidth
                        variant='contained'
                        color={'success'}
                        onClick={handleSubmit}
                        translateKey='save_changes'
                        disabled={!products || products.length === 0}
                        loading={saveLoading.isLoading}
                      />

                      <Button
                        color='error'
                        sx={{
                          pt: 1,
                          ml: 1,
                          backgroundColor: 'inherit',
                          borderColor: 'error.main',
                          '&:hover': { backgroundColor: 'inherit' },
                        }}
                        startIcon={<ClearIcon sx={{ mr: -1 }} />}
                        onClick={cancelEditWarningPopup.handleOpen}
                      >
                        {t('buttons.cancel')}
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item md={12}>
                    <Divider sx={{ opacity: 0.7, my: 1 }} />{' '}
                  </Grid>
                  <Grid item container md={12} px={1}>
                    <Typography color='text.third' variant='body2'>
                      {t('texts.convert_to_RFQ')}
                    </Typography>{' '}
                  </Grid>
                </Grid>
              </Box>

              <Grid container mt={1} mb={4}>
                <GeneralTerms
                  mode='editable'
                  generalTermsSlice={generalTermsSlice}
                  forceValidateTerms
                  handleSelectGeneralTerms={updateGeneralTerms}
                />
              </Grid>

              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                  <Typography fontWeight={700} color='text.primary' variant='overline'>
                    Products / Services <PrimaryBadge>{products?.length}</PrimaryBadge>
                  </Typography>
                </Grid>
                <Grid item>
                  <Button variant='contained' color='primary' type='button' onClick={handleAddProducts}>
                    Add Products / Services
                  </Button>
                </Grid>
              </Grid>

              {products && (
                <Grid item md={12} mt={2.5}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      display: 'grid',
                    }}
                  >
                    <TabContent
                      tabs={tabs}
                      selectedTab={selectedMainProductId}
                      onChange={id => setSelectedMainProductId(id)}
                    />
                  </Box>
                </Grid>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBlock: '1em',
                  width: '100%',
                }}
              >
                <Grid container item md={12}>
                  <Typography fontWeight={400} mt='4px' color='#3A3E50' fontSize={'14px'}>
                    Quantity Requested
                  </Typography>
                  <Box width={120} height={20} ml={1} mr={3}>
                    <TextFieldCounter
                      value={selectedMainProduct?.quantity}
                      changeQuantity={newQuantity => changeQuantity(selectedMainProductId, newQuantity)}
                    />
                  </Box>

                  <div style={{ marginTop: 3.5 }}>
                    <SelectCustomWarranty
                      label={<span style={{ color: '#3A3E50', fontWeight: 400, fontSize: "14px" }}>Custom Warranty <span style={{ color: '#8B8D98' }}>(optional)</span></span>}
                      generalTermsSlice={generalTermsSlice}
                      handleChange={warranty_term =>
                        handleSelectWarrantyTermForMainProduct(selectedMainProductId, warranty_term)
                      }
                      value={selectedMainProduct?.warranty_term}
                      items={termsData?.warranty_terms ?? []}
                    />

                    {selectedMainProduct?.category_kind === ProductCategoryKind.SERVICE && (
                      <Box display={'flex'} alignItems={'center'} ml={3} gap={0.5}>
                        <BuyerRequirementsIndicator
                          additional_requirements={selectedMainProduct.additional_requirements}
                          editable
                          onSave={(newRequirements, popupUtils) => {
                            changeRequirements(selectedMainProductId, newRequirements);
                            popupUtils?.handleClose();
                          }}
                        />
                        <InfoOutlinedIcon sx={{ color: '#89B1C6', fontSize: '18px' }} />
                      </Box>
                    )}
                  </div>
                </Grid>
                <Button
                  type='button'
                  onClick={() => clearSellerSelections(selectedMainProductId)}
                  startIcon={<HighlightOffIcon />}
                  disabled={numberOfSelectedSellersForMainProduct === 0}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Clear Selection
                </Button>
              </div>

              {selectedMainProduct && (
                <>
                  <Grid item md={12} my={2}>
                    <RequisitionProductWithSellersCard
                      productDetails={selectedMainProduct}
                      selectSellersForProduct={(p, sellerData) => {
                        toggleSelectSellerForMainProduct(selectedMainProductId, sellerData);
                      }}
                      editMode={true}
                      handleLinkSellersInEditMode={(productId: number, newSellers: any) =>
                        linkSellersInEditMode(productId, newSellers)
                      }
                    />
                  </Grid>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div>
                      <Typography variant='overline' color='text.disabled'>
                        {t('buttons.alternatives')}{' '}
                        <PrimaryBadge>{selectedMainProduct.alternatives?.length}</PrimaryBadge>
                      </Typography>
                    </div>
                    <div>
                      <Button
                        type='button'
                        variant='contained'
                        color='secondary'
                        onClick={addAlternativesForSelectedProduct}
                      >
                        {t('buttons.add_alternatives')}
                      </Button>
                    </div>
                  </div>
                  {selectedMainProduct.alternatives?.length === 0 && (
                    <Typography fontWeight={400} color='#8B8D98' variant='body1'>
                      No Alternatives added for this product
                    </Typography>
                  )}
                  {selectedMainProduct.alternatives?.map((alternativeProduct, index) => (
                    <Grid item md={12} key={`${alternativeProduct.id}-${index}-${selectedMainProduct?.id}`} my={1}>
                      <RequisitionProductWithSellersCard
                        productDetails={alternativeProduct}
                        isAlternative
                        selectSellersForProduct={(p, sellerData) => {
                          toggleSelectSellerForAlternativeProduct(
                            selectedMainProductId,
                            alternativeProduct.id,
                            sellerData
                          );
                        }}
                        editMode={true}
                        handleLinkSellersInEditMode={(productId: number, newSellers: any) =>
                          linkSellersInEditMode(productId, newSellers)
                        }
                      />
                    </Grid>
                  ))}
                </>
              )}
            </>
          )}
        </Formik>
      </Grid>
    </>
  );
};

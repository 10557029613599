import { SvgIcon, SvgIconProps } from '@mui/material';

export const CustomCatalogueIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        viewBox="0 0 29 29"
        {...props}
    >
        <mask id="mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="29" height="29">
            <rect x="0.5" y="0.972656" width="28" height="28" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0)">
            <path
                d="M9.1625 12.0284L13.5083 4.94089C13.625 4.74644 13.7708 4.60547 13.9458 4.51797C14.1208 4.43047 14.3056 4.38672 14.5 4.38672C14.6944 4.38672 14.8792 4.43047 15.0542 4.51797C15.2292 4.60547 15.375 4.74644 15.4917 4.94089L19.8375 12.0284C19.9542 12.2228 20.0125 12.427 20.0125 12.6409C20.0125 12.8548 19.9639 13.0492 19.8667 13.2242C19.7694 13.3992 19.6333 13.5402 19.4583 13.6471C19.2833 13.7541 19.0792 13.8076 18.8458 13.8076H10.1542C9.92083 13.8076 9.71667 13.7541 9.54167 13.6471C9.36667 13.5402 9.23056 13.3992 9.13333 13.2242C9.03611 13.0492 8.9875 12.8548 8.9875 12.6409C8.9875 12.427 9.04583 12.2228 9.1625 12.0284ZM20.9167 26.6409C19.4583 26.6409 18.2187 26.1305 17.1979 25.1096C16.1771 24.0888 15.6667 22.8492 15.6667 21.3909C15.6667 19.9326 16.1771 18.693 17.1979 17.6721C18.2187 16.6513 19.4583 16.1409 20.9167 16.1409C22.375 16.1409 23.6146 16.6513 24.6354 17.6721C25.6562 18.693 26.1667 19.9326 26.1667 21.3909C26.1667 22.8492 25.6562 24.0888 24.6354 25.1096C23.6146 26.1305 22.375 26.6409 20.9167 26.6409ZM4 24.8909V17.8909C4 17.5603 4.11181 17.2832 4.33542 17.0596C4.55903 16.836 4.83611 16.7242 5.16667 16.7242H12.1667C12.4972 16.7242 12.7743 16.836 12.9979 17.0596C13.2215 17.2832 13.3333 17.5603 13.3333 17.8909V24.8909C13.3333 25.2214 13.2215 25.4985 12.9979 25.7221C12.7743 25.9457 12.4972 26.0576 12.1667 26.0576H5.16667C4.83611 26.0576 4.55903 25.9457 4.33542 25.7221C4.11181 25.4985 4 25.2214 4 24.8909Z"
                fill="#0F5D85"
            />
        </g>
    </SvgIcon>
);
